import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function InputPassword(props) {
    const { name, label, value, variant, onChange, error = null, ...other } = props;
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () =>{
        setShowPassword(showPassword ? false : true);
    };

    return (
        <TextField
            variant={variant || "outlined"}
            label={label || "Password"}
            type={showPassword ? 'text' : 'password'}
            name={name}
            value={value}
            onChange={onChange}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                            className="color-secondary-0 hover-primary"
                            size="small"
                            onClick={handleShowPassword} >
                            {showPassword ? <VisibilityIcon  /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
            }}
            {...other}
            {...(error && { error: true, helperText: error })} />
    )
}
