import React, { useState } from 'react';
import { Divider, MenuItem, Typography } from '@mui/material';
import Dialog from '../../layout/Dialog';
import EmployeeAcc from '../account/EmployeeAcc';
import EmployerAcc from '../account/EmployerAcc';
import MemberAcc from '../account/MemberAcc';
import StudentAcc from '../account/StudentAcc';
import BusinessAcc from '../account/BusinessAcc';

export default function MyAccount(props) {
  const {onClick} = props;
  const [showEmplyFrm, setShowEmplyFrm] = useState(false);
  const [showEmplrFrm, setShowEmplrFrm] = useState(false);
  const [showMemberFrm, setShowMemberFrm] = useState(false);
  const [showStuFrm, setShowStuFrm] = useState(false);
  const [showBusiFrm, setShowBusiFrm] = useState(false);

  const handleEmplyFrm = () => {
    onClick && onClick();
    setShowEmplyFrm(true);
  }
  const handleEmplrFrm = () => {
    onClick && onClick();
    setShowEmplrFrm(true);
  }
  const handleMemberFrm = () => {
    onClick && onClick();
    setShowMemberFrm(true);
  }
  const handleStuFrm = () => {
    onClick && onClick();
    setShowStuFrm(true);
  }
  const handleBuisFrm = () => {
    onClick && onClick();
    setShowBusiFrm(true);
  }

  return (
    <>
      <MenuItem className='btn-hov-sec' onClick={() => handleEmplyFrm()}>
        <Typography textAlign='center' className='font-18'>Job Seekers Account</Typography>
      </MenuItem>
      <Divider className='m-0' />
      <MenuItem className='btn-hov-sec' onClick={() => handleEmplrFrm()}>
        <Typography textAlign='center' className='font-18'>Employer Account</Typography>
      </MenuItem>
      <Divider className='m-0' />
      <MenuItem className='btn-hov-sec' onClick={() => handleMemberFrm()}>
        <Typography textAlign='center' className='font-18'>Member Account</Typography>
      </MenuItem>
      <Divider className='m-0' />
      <MenuItem className='btn-hov-sec' onClick={() => handleStuFrm()}>
        <Typography textAlign='center' className='font-18'>Student Account</Typography>
      </MenuItem>
      <Divider className='m-0' />
      <MenuItem className='btn-hov-sec' onClick={() => handleBuisFrm()}>
        <Typography textAlign='center' className='font-18'>Business Account</Typography>
      </MenuItem>
      {/* Account Forms*/}
      {/* Employee Form */}
      {showEmplyFrm && <Dialog
        show={showEmplyFrm}
        maxWidth='md'>
        <EmployeeAcc {...{ setShowForm: setShowEmplyFrm }} />
      </Dialog>}
      {/* Employer Form */}
      {showEmplrFrm && <Dialog
        show={showEmplrFrm}
        maxWidth='md'>
        <EmployerAcc {...{ setShowForm: setShowEmplrFrm }} />
      </Dialog>}
      {/* Member Registration Form */}
      {showMemberFrm && <Dialog
        show={showMemberFrm}
        maxWidth='md'>
        <MemberAcc {...{ setShowForm: setShowMemberFrm }} />
      </Dialog>}
      {/* Student Registration Form */}
      {showStuFrm && <Dialog
        show={showStuFrm}
        maxWidth='md'>
        <StudentAcc {...{ setShowForm: setShowStuFrm }} />
      </Dialog>}
      {/* Business Registration Form */}
      {showBusiFrm && <Dialog
        show={showBusiFrm}
        maxWidth='md'>
        <BusinessAcc {...{ setShowForm: setShowBusiFrm }} />
      </Dialog>}
    </>
  )
}
