import React from 'react';
import {
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import Slide from '../../layout/Slide';
import MultCarousel from '../../layout/MultCarousel'

export default function HomeSlide({ data }) {
  return (
    <>
      <MultCarousel {...{ showDots: false }}>
        {data.map((item, i) =>
          <Grid key={i} container className='pos-relative'>
            <img
              src={item.photo}
              className='img-fluid img-full-srcn'
              alt={item.title} />
            <Grid
              item
              xs={12}
              container
              className='pos-absolute'
              sx={{ bottom: '2rem', pl: '1rem', pr: '1rem'}}>
              <Container maxWidth='xl' className='color-white'>
                <Grid container>
                  <Grid item xs={12} sx={{ pr: { md: '50px' } }}>
                    <Slide {...{ direction: 'down', autoShow: true }}>
                      <Typography sx={{ fontSize: { md: '26px', xs: '20px' } }}>
                        {item.title}
                      </Typography>
                    </Slide>
                    <Slide {...{ direction: 'right', autoShow: true }}>
                      <Typography variant='subtitle1' className='mt-15' sx={{ display: { md: 'block', xs: 'none' } }}>
                        {item.shortDes}
                      </Typography>
                    </Slide>
                    <Slide {...{ direction: 'up', autoShow: true }}>
                      <a href={item.link} target='_blank' rel="noreferrer">
                        <Button
                          variant='contained'
                          className='btn-primary mt-15'>
                          Read More...
                        </Button>
                      </a>
                    </Slide>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        )}
      </MultCarousel>
    </>
  )
}
