import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react'
import Dashboard from '../../../layout/Dashboard'
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { IsEmptyObj, PageTitle } from '../../../hooks/Methods';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessPrfl from './BusinessPrfl';
import BusinessPrflEdit from './BusinessPrflEdit';
import BusinessChangePass from './BusinessChangePass';
import { connect } from 'react-redux';
import * as actions from '../../../actions/ieb';
import { Alert } from '../../../layout';
import { LoaderContext } from '../../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';

const Menu = (props) => {
  const { _fetch, setAlert } = props;
  const [selIndex, setSelectIndex] = useState(1);
  const navigate = useNavigate();
  const { setShowLoader } = useContext(LoaderContext);

  const handleClick = (selIndex) => {
    setSelectIndex(selIndex)
    switch (selIndex) {
      case 1:
        navigate('/dashboard/business')
        break;
      case 2:
        navigate('/dashboard/business/editProfile')
        break;
      case 3:
        navigate('/dashboard/business/changePass')
        break;
      case 4:
        navigate('/contactUs')
        break;
      default:
        // Logout
        const onLogout = () => {
          setShowLoader(true);
          const onComplete = (res) => {
            setShowLoader(false);
            (res !== undefined && res.status === 204) && navigate('/');
          }
          _fetch(ENDPOINTS.Auth + CNTRL_ACTN.Logout, onComplete, actions.ACTION_TYPES.LOGIN);
        }
        setAlert({
          isOpen: true,
          type: 'confirmation',
          title: 'Are you sure!',
          subTitle: 'You want to Logout from your account?',
          onConfirm: onLogout,
        });
        break;
    }
  }
  return (
    <>
      <ListItemButton
        selected={selIndex === 1}
        onClick={() => handleClick(1)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 2}
        onClick={() => handleClick(2)}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 3}
        onClick={() => handleClick(3)}>
        <ListItemIcon>
          <SyncLockIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 4}
        onClick={() => handleClick(4)}>
        <ListItemIcon>
          <DeleteSweepRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Delete Account (Contact)" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 0}
        onClick={() => handleClick(0)}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </>
  )
}

const BusinessDash = (props) => {
  const { title, _fetch, _authUser } = props;
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });
  PageTitle(title);

  useEffect(() => {
    //Get Authorize User
    if (IsEmptyObj(_authUser)) {
      const onComplete = (res, err) => {
        (res !== undefined && res.status === 401) && navigate("/unauthorized");
      }
      _fetch(ENDPOINTS.Auth + CNTRL_ACTN.GetAuthUser, onComplete, actions.ACTION_TYPES.LOGIN);
    }
    return () => {
      setAlert({
        isOpen: false,
        type: '',
        title: '',
        subTitle: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dashboard
      menu={<Menu {...{ _fetch, setAlert }} />}
      title={'Hi! ' + (_authUser.result !== undefined && _authUser.result.fullName)}
      subtitle='Business - Dashboard'>
      <Routes>
        <Route path='/' element={<BusinessPrfl />} />
        <Route path='editProfile' element={<BusinessPrflEdit title='Edit Profile' />} />
        <Route path='changePass' element={<BusinessChangePass title='Change Password' />} />
      </Routes>
      <Alert alert={alert} setAlert={setAlert} />
    </Dashboard>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _fetch: actions.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(BusinessDash);