import React, { useState } from 'react'
import { Fab, Zoom } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ScrollToTop() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);
  return (
    <Zoom in={visible} timeout={800}>
      <Fab
        onClick={() => scrollToTop()}
        size='medium'
        sx={{
          position: 'fixed',
          right: '20px',
          bottom: '20px',
          zIndex: '10000'
        }}
        className="btn-primary">
        <KeyboardArrowUpIcon />
      </Fab>
    </Zoom>
  )
}
