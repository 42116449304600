import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';

export default function DatePicker(props) {
  const {
    name,
    label,
    value,
    onChange,
    views = ['day', 'month', 'year'],
    error = null,
    ...other } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} >
        <MuiDatePicker
          views={views}
          label={label}
          name={name}
          onChange={onChange}
          {...other}
          renderInput={(params) =>
            <TextField
              className='input-primary'
              {...params}
              inputProps={{
                ...params.inputProps,
                value: value !== '' ? value : ''
              }}
              error={false}
              {...(error && { error: true, helperText: error })} />
          }
        />
      </Stack>
    </LocalizationProvider >
  )
}
