import React from 'react';
import {
   FormControl,
   FormHelperText,
   InputLabel,
   ListSubheader,
   MenuItem,
   Select as MuiSelect
} from '@mui/material';


export default function Select(props) {
   const { name, label, value, variant, onChange, options, error = null, showNA = false, ...other } = props;
   return (
      <FormControl
         variant={variant || "outlined"} {...other}>
         <InputLabel>{label}</InputLabel>
         <MuiSelect
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            MenuProps={{ classes: { paper: "max-h-350" } }}
            {...(error && { error: true })}>
            {showNA && <MenuItem value=""><em>N/A</em></MenuItem>}
            {
               options.map((item, index) => (
                  item.Subheader !== undefined ? (
                     <ListSubheader disabled sx={{
                       position: 'relative',
                       color: '#000',
                       fontStyle: 'italic',
                       fontWeight: 'bold',
                       textDecoration: 'underline'
                     }} key={index} value="">
                        <em>{item.Subheader}</em>
                     </ListSubheader>
                  ) : (
                     <MenuItem key={index} value={item.id}>
                        {item.name}
                     </MenuItem>
                  )
               ))
            }
         </MuiSelect>
         {error && <FormHelperText sx={{color: '#d32f2f'}}>{error}</FormHelperText>}
      </FormControl>
   )
}
