import { Grid, Paper } from '@mui/material'
import React from 'react'
import { PageTitle } from '../../../hooks/Methods';
import Login from '../../account/login/Login';

export default function AdminLogin() {
  PageTitle("Admin Login")
  return (
    <>
      <Grid className='d-flex justify-center align-center h-100vh p-10'>
        <Paper className='pt-20'>
          <Login isAdmin={true} />
        </Paper>
      </Grid>
    </>
  )
}
