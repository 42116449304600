import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const Ads = ({ _home }) => {
  const [randomAds, setRandomAds] = useState();
  useEffect(() => {
    // 3 is the Ads Id
    // Select ads
    let ads = (_home.result && _home.result.imgs) && _home.result.imgs.filter(j => j.imgType === 3);
    (ads && ads.length > 0) && setRandomAds(
      ads[Math.floor(Math.random() * ads.length)]
    )
    return () => {
      setRandomAds();
    }
  }, [_home.result])

  return (
    randomAds ? <a href={randomAds.link} target="_blank" rel="noreferrer">
      <img src={randomAds.photo} alt="ads"
        className='img-fluid obj-contain border-radious-5' />
    </a> : <></>
  )
}

const mapStateToProps = state => ({
  _home: state.ieb.homeData
})

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(Ads);