import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { DropLi } from '../../../../hooks';
import { JsonToExcel, PageTitle } from '../../../../hooks/Methods';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import { Alert, Table } from '../../../../layout';

const Employee = (props) => {
  const {
    _fetchAllByPram,
    _list,
    _delete } = props;
  const {
    departments,
    educations,
    interests,
    aplOrigins } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  })

  PageTitle("Employee");

  const handleDlt = (props) => {
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to delete this record?',
      onConfirm: () => {
        if (props.id !== undefined && props.id !== 0) {
          const onComplete = (res) => {
            setAlert({
              isOpen: true,
              type: res.status === 200 ? 'success' : 'error',
              title: res.status === 200 ? 'Deleted Successfully' : 'OPPS',
              subTitle: res.msg
            })
          }
          _delete(ENDPOINTS.Employee + CNTRL_ACTN.Delete,
            props.id,
            onComplete,
            actions.ACTION_TYPES.REMOVE_LIST);
        }
      },
    })
  }


  const handleExcel = (data) => {
    const result = data.map(({ photoFile, attachCVFile, ...rest }) => ({
      ...rest,
      photo: rest.photo.props.href,
      attachCV: rest.attachCV.props.href,
    }));
    JsonToExcel(result.map(j => {
      delete Object.assign(j, { 'Id': j.id })['id'];
      delete Object.assign(j, { 'Full Name': j.fullName })['fullName'];
      delete Object.assign(j, { 'Email': j.email })['email'];
      delete Object.assign(j, { 'Application Origin': j.aplOrigin })['aplOrigin'];
      delete Object.assign(j, { 'Education': j.education })['education'];
      delete Object.assign(j, { 'Department': j.department })['department'];
      delete Object.assign(j, { 'Experience': j.expDuration })['expDuration'];
      delete Object.assign(j, { 'Field of Experience': j.fieldOfExp })['fieldOfExp'];
      delete Object.assign(j, { 'Field of Interest': j.fieldOfInt })['fieldOfInt'];
      delete Object.assign(j, { 'Last Degree Passing Year': j.lastDegPassYr })['lastDegPassYr'];
      delete Object.assign(j, { 'Mobile': j.mobile })['mobile'];
      delete Object.assign(j, { 'Home District': j.homeDist })['homeDist'];
      delete Object.assign(j, { 'Computer Expertise': j.computerExp })['computerExp'];
      delete Object.assign(j, { 'Previous Company': j.prevComp })['prevComp'];
      delete Object.assign(j, { 'Special Quality': j.specialQuality })['specialQuality'];
      delete Object.assign(j, { 'Photo': j.photo })['photo'];
      delete Object.assign(j, { 'CV': j.attachCV })['attachCV'];
      return j;
    }), 'Employee');
  }

  useEffect(() => {
    _fetchAllByPram(ENDPOINTS.Employee + CNTRL_ACTN.GetAll, false);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Circular List || Table */}
      <Container maxWidth='xl'>
        <Table
          showDownloadBtn={true}
          showEdit={false}
          showAddBtn={false}
          handleExcel={handleExcel}
          handleDlt={handleDlt}
          tblHead={[
            { id: 'actions', label: 'Action' },
            { id: 'id', label: 'Id' },
            { id: 'fullName', label: 'Full Name' },
            { id: 'mobile', label: 'Mobile' },
            { id: 'aplOrigin', label: 'Application Origin' },
            { id: 'email', label: 'Email' },
            { id: 'education', label: 'Education' },
            { id: 'expDuration', label: 'ExpDuration' },
            { id: 'prevComp', label: 'Previous Company' },
            { id: 'computerExp', label: 'Computer Expertise' },
            { id: 'department', label: 'Department' },
            { id: 'lastDegPassYr', label: 'Last Degree Passing Year' },
            { id: 'fieldOfInt', label: 'Field of Interest' },
            { id: 'specialQuality', label: 'Special Quality' },
            { id: 'homeDist', label: 'Home District' },
            { id: 'photo', label: 'Photo' },
            { id: 'attachCV', label: 'CV' },
          ]}
          tblData={(_list.length > 0 && _list[0].aplOrigin !== undefined) ? _list.map(obj => {
            return {
              ...obj,
              'aplOrigin': aplOrigins.find(j => j.id === obj.aplOrigin).name,
              'department': obj.department && departments.find(j => j.id === obj.department).name,
              'education': obj.education && educations.find(j => j.id === obj.education).name,
              'fieldOfInt': obj.fieldOfInt && interests.find(j => j.id === obj.fieldOfInt).name,
              'expDuration': obj.expDuration && obj.expDuration + ' year',
              'lastDegPassYr': obj.lastDegPassYr && obj.lastDegPassYr + ' year',
              'photo': <a href={obj.photo} target="_blank" rel="noreferrer" className='hov-primary'>View Photo</a>,
              'attachCV': <a href={obj.attachCV} target="_blank" rel="noreferrer" className='hov-primary'>View CV</a>
            }
          }) : []} />
      </Container>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  )
}

const mapStateToProps = state => ({
  _list: state.ieb.list,
})

const mapActionToProps = {
  _fetchAllByPram: actions.fetchAllById,
  _delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(Employee);
