import { Grid, Typography, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { PageTitle } from '../../hooks/Methods';

export default function ErrorPage(props) {
  const { status = '404',
    title,
    msgTitle,
    msg = 'Page Not Found',
    btnTitle = 'Back To Home',
    btnLink = '/',
    onClick,
    icon } = props;

  PageTitle(title || '404 - Page Not Found');

  return (
    <Grid
      container
      direction='column'
      justifyContent="center"
      alignItems="center"
      sx={{ height: 'calc(100vh  - 200px)' }}>
      <Typography
        variant='p'
        sx={{ fontSize: '90px' }}
        className='color-secondary font-italic font-bold'>
        {icon !== undefined ? icon : status}
      </Typography>
      <Typography
        variant='p'
        className='font-36 font-bold color-secondary'>
        {msgTitle}
      </Typography>
      <Typography
        variant='p'
        className='font-20 color-gray'>
        {msg}
      </Typography>
      <Link to={btnLink}>
        <Button onClick={() => onClick && onClick()} className='btn-primary mt-30' size='large' variant='contained'>
          {btnTitle}
        </Button>
      </Link>
    </Grid>
  )
}
