import React, { useState } from 'react';
import {
  Box,
  Button,
  Grow,
  Paper,
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { GlobalVar } from '../hooks/Methods';

export default function CstmMenu(props) {
  const { btnTitle, btnCls, children, sxProps, ...other } = props;
  const [showMenu, setShowMenu] = useState(false);
  const { mdWidth } = GlobalVar();
  return (
    <Box
      className='pos-relative btn-hov-sec btn-hov-black btn-hov-bold border-radious-5 z-index-100'
      onMouseLeave={() => setShowMenu(false)}
      onBlur={() => setShowMenu(false)}>
      <Button
        className={btnCls}
        sx={sxProps !== undefined ? sxProps : {}}
        onMouseOver={() => window.innerWidth > mdWidth && setShowMenu(true)}
        onClick={() => window.innerWidth < mdWidth && setShowMenu(!showMenu)}
        aria-controls='long-menu'
        aria-haspopup="true"
        endIcon={<KeyboardArrowDownRoundedIcon />}
        {...other}>
        {btnTitle}
      </Button>
      <Grow
        in={showMenu}
        style={{ transformOrigin: '0 0 0', borderTop: '2px solid #000' }}>
        <Paper
          className='pos-absolute pb-5 pt-5 border-trans-2'>
          {children}
        </Paper>
      </Grow>
    </Box >
  )
}
