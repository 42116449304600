import React, { } from 'react';
import {
  Container,
  Toolbar,
  Grid,
  Box,
  Button,
  Avatar,
} from '@mui/material';
import Login from '../account/login/Login';
import MyAccount from './MyAccount';
import CstmMenu from '../../layout/CstmMenu';
import { connect } from 'react-redux';
import { IsEmptyObj } from '../../hooks/Methods';
import { Link } from 'react-router-dom';

const TopNav = (props) => {
  const { _authUser } = props;
  return (
    <Grid
      className="bg-secondary"
      sx={{ borderBottom: '3px solid #ffb606' }}>
      <Container
        maxWidth='xl'>
        <Toolbar
          disableGutters
          sx={{
            minHeight: '0 !important',
            padding: '5px 0px 5px 0px',
            justifyContent: 'right'
          }}>
          {(!IsEmptyObj(_authUser) && _authUser.result && !IsEmptyObj(_authUser.result)) ?
            <Link to={`dashboard/${_authUser.type}`}>
              <Button
                size='small'
                className='btn-primary hov-trans border-radious-30 pr-10'>
                <Avatar
                  alt={_authUser.result.fullName}
                  sx={{ width: '24px', height: '24px', mr: '5px' }}
                  src={_authUser.result.photo !== "null" && _authUser.result.photo !== undefined
                    ? _authUser.result.photo : (_authUser.result.logo !== undefined && _authUser.result.logo)} />
                {_authUser.result.fullName}
              </Button>
            </Link> :
            <>
              <Box className='mr-10'>
                <CstmMenu {...{
                  btnTitle: 'Sign Up',
                  btnCls: 'btn-primary hov-trans',
                  variant: 'contained',
                  size: 'small'
                }}>
                  <MyAccount />
                </CstmMenu>
              </Box>
              <Login
                className='btn-primary hov-trans'
                variant="contained"
                size='small' />
            </>
          }
        </Toolbar>
      </Container>
    </Grid>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(TopNav);
