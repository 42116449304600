import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';
import * as actions from '../../../actions/ieb';
import { Input, InputPass } from '../../../controls';
import { Model, UseForm } from '../../../hooks';
import { PageTitle, RemoveEmptyPropFromObj } from '../../../hooks/Methods';
import { Alert, Form } from '../../../layout';
import { PassValidation } from '../../../utils';
import { LoaderContext } from '../../../utils/Context';

const AddOrEdit = (props) => {
  const {
    isUpdate = false,
    _create,
    _update,
    _admin,
    setActiveStep } = props;
  const { AdminMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext)
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputPass,
    passValidation,
    toFormData,
  } = UseForm(AdminMdl);

  PageTitle(isUpdate ? 'Edit Admin' : 'Create Admin')

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : "Full name is required."
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    if (!isUpdate) {
      temp.password = values.password !== "" ? !passValidation.passValid ? " " : "" : "Password is required."
      temp.confirmPass = values.confirmPass !== "" ? !passValidation.match ? "Password and Confirm password not match." : "" : "Confirm Password is required."
    }
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          isUpdate
            ? setAlert({
              isOpen: true,
              type: 'success',
              title: 'Awesome!',
              subTitle: `You have successfully updated admin account.`,
            })
            : setAlert({
              isOpen: true,
              type: 'success',
              title: 'Congratulations!',
              subTitle: `You have successfully create admin account.`,
              onClick: () => setActiveStep(1)
            })
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg
          })
        }
      }
      isUpdate
        ? _update(ENDPOINTS.Admin + CNTRL_ACTN.Update, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.ADMIN)
        : _create(ENDPOINTS.Admin + CNTRL_ACTN.Create, toFormData(values), onComplete, actions.ACTION_TYPES.ADMIN)
    }
  }

  useEffect(() => {
    isUpdate && setValues({ ..._admin.result });
    return () => {
      setValues();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Alert alert={alert} setAlert={setAlert} />
      <Grid className='d-flex justify-center align-center h-100vh p-10'>
        <Paper className='mx-w-700'>
          <Form noValidate
            onSubmit={submitForm}>
            <Grid container spacing={3} className='pl-20 pr-20 pt-30 pb-30' >
              <Grid item xs={12} className='text-center mb-30 pos-relative'>
                <Typography variant='p' className='bottom-border color-dark font-30'>
                  {isUpdate ? 'Update admin account' : 'Create admin Account'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Full Name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleInputChange}
                  error={errors.fullName}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  className='input-primary w-100pr'
                />
              </Grid>
              {!isUpdate && <>
                <Grid item xs={12} md={6}>
                  <InputPass
                    label="Password"
                    name="password"
                    value={values.password}
                    onChange={handleInputPass}
                    error={errors.password}
                    className='input-primary w-100pr'
                  />
                  <PassValidation {...{ password: values.password, passValidation }} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputPass
                    label="Confirm Password"
                    name="confirmPass"
                    value={values.confirmPass}
                    onChange={e => handleInputPass(e, true)}
                    error={(
                      errors.confirmPass !== "" &&
                      values.password !== "" &&
                      !passValidation.match &&
                      values.confirmPass !== "")
                      ? "Password and Confirm password not match"
                      : errors.confirmPass}
                    className='input-primary w-100pr'
                  />
                </Grid>
              </>
              }
              <Grid item xs={12} className='text-right'>
                <Button
                  type='submit'
                  variant='contained'
                  className={(!isUpdate && 'w-100pr') + ' btn-primary'}>
                  {isUpdate ? 'Save Changes' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _admin: state.ieb.admin
})

const mapActionToProps = {
  _create: actions.create,
  _update: actions.update,
}

export default connect(mapStateToProps, mapActionToProps)(AddOrEdit);
