import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { PageTitle } from '../../../../hooks/Methods';
import * as actions from '../../../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Typography
} from '@mui/material';
import { Alert } from '../../../../layout';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Input } from '../../../../controls';
import SearchIcon from '@mui/icons-material/Search';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import AddEditPage from '../Page/AddEditPage';
import ViewPage from '../Page/ViewPage';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useParams } from 'react-router-dom';

let pageSize = 12;
const Pages = (props) => {
  const { _fetchAllById, _list, _delete } = props;
  const [searchVal, setSearchVal] = useState("");
  const [updData, setUpdData] = useState();
  const [viewData, setViewData] = useState();
  const [pages, setPages] = useState([]);
  const [pgTitle, setPgTitle] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  })
  const { pageType } = useParams();

  PageTitle(pgTitle);

  const updPost = (val) => {
    setUpdData(val);
    setActiveStep(1);
  }
  const dltPost = (id) => {
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to delete this post?',
      onConfirm: () => {
        const onComplete = (res) => {
          setAlert({
            isOpen: true,
            type: res.status === 200 ? 'success' : 'error',
            title: res.status === 200 ? 'Deleted Successfully' : 'OPPS',
            subTitle: res.msg
          })
        }
        _delete(ENDPOINTS.Admin + CNTRL_ACTN.DeletePage, id,
          onComplete, actions.ACTION_TYPES.REMOVE_LIST);
      }
    })
  }

  const findPages = (e) => {
    setPages(_list.filter(j => j.title.includes(e.target.value)));
    setSearchVal(e.target.value);
  }
  const viewPage = (data) => {
    setViewData(data);
    setActiveStep(2);
  }
  useEffect(() => {
    setPages(_list);
    return () => {
      setPages([]);
    }
  }, [_list])

  useEffect(() => {
    pageType && _fetchAllById(ENDPOINTS.Admin + CNTRL_ACTN.GetPagesByType, pageType);

    switch (parseInt(pageType)) {
      case 2:
        setPgTitle('News');
        break;
      case 3:
        setPgTitle('Events');
        break;
      case 4:
        setPgTitle('Notice');
        break;
      case 5:
        setPgTitle('Research');
        break;
      default:
        break;
    }

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType])

  const content = (step) => {
    switch (step) {
      case 1:
        return (
          <AddEditPage {...{
            pageType,
            setActiveStep,
            updData,
            setUpdData,
          }} />
        )
      case 2:
        return (
          <>
            <IconButton
              onClick={() => setActiveStep(0)}
              className='btn-secondary-trans ml-15'>
              <ArrowBackIosRoundedIcon className='font-18 m-2' />
            </IconButton>
            <ViewPage {...{ viewData }} />
          </>
        )
      default:
        return (
          <>
            {/* View Post Page */}
            <Paper className='ml-20 mr-20 mb-20  p-10'>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <Input
                    label="Search By Title"
                    name="search"
                    placeholder='Key words'
                    size='small'
                    className='input-primary w-100pr'
                    value={searchVal}
                    onChange={findPages}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton size='small' className='hov-primary-text'>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    onClick={() => setActiveStep(1)}
                    className='w-100pr btn-primary'>
                    <PostAddRoundedIcon className='mr-5' />Add Pages
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            {/* Pages */}
            <Grid container className='pl-20 pr-20' spacing={2}>
              {pages.length > 0 && pages.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize).map((item, i) =>
                <Grid key={i} item xs={12} md={3}>
                  <Card>
                    <CardMedia
                      component='img'
                      alt={item.title}
                      className='h-250'
                      image={item.coverPhoto ? item.coverPhoto : process.env.PUBLIC_URL + "/img/other/no-image.png"}
                    />
                    <CardContent className='pb-15'>
                      <Grid className='d-flex'>
                        <CalendarMonthIcon className='color-gray mr-10 font-20' />
                        <Typography
                          variant='h6'
                          className='font-14 font-bold color-gray'>
                          {item.createDate}
                        </Typography>
                      </Grid>
                      <Typography
                        onClick={() => viewPage(item)}
                        variant='h6'
                        className='font-16 font-bold color-secondary mt-10 cursor-pointer hov-primary'>
                        {item.title}
                      </Typography>
                      <Grid className='text-center mt-10'>
                        <IconButton
                          onClick={() => updPost(item)}
                          size='small'
                          className='btn-secondary-trans m-2'>
                          <EditIcon className='font-18 m-2' />
                        </IconButton>
                        <IconButton
                          onClick={() => dltPost(item.id)}
                          size='small'
                          className='btn-danger-trans m-2'>
                          <ClearRoundedIcon className='font-18 m-2' />
                        </IconButton>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} className='mt-30 d-flex justify-center'>
                <Paper className='p-15 border-radious-30'>
                  <Pagination
                    color='primary'
                    count={Math.ceil(pages.length / pageSize)}
                    defaultPage={1}
                    onChange={(e, newPage) => setPage(newPage)}
                    showFirstButton={true}
                    showLastButton={true} />
                </Paper>
              </Grid>
            </Grid>
          </>
        )
    }
  }

  return (
    <>
      {content(activeStep)}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  )
}

const mapStateToProps = state => ({
  _list: state.ieb.list,
  _admin: state.ieb.admin
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
  _fetchAllById: actions.fetchAllById,
  _delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(Pages);