import React, { useState, useEffect, useContext } from 'react'
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import { Input, Select, FileInput, DatePicker, InputRich } from '../../../controls';
import InputImg from '../../../controls/InputImg';
import { DropLi, UseForm, Model } from '../../../hooks';
import { Form, Alert } from '../../../layout';
import { IsEmptyObj, MaxInput, RemoveEmptyPropFromObj, RemoveNullPropFromObj } from '../../../hooks/Methods';
import { connect } from 'react-redux';
import * as actions from '../../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';
import { LoaderContext } from '../../../utils/Context';

const EmployeeCV = (props) => {
  const { _authUser, _update } = props;
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });
  const { EmployeeMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext);

  const {
    departments,
    educations,
    interests,
    experiences,
    aplOrigins } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleImg,
    handleInputDate,
    toFormData,
  } = UseForm(EmployeeMdl);

  const validateForm = () => {
    let temp = {};
    temp.photo = values.photo !== null ? "" : "Photo is required."
    temp.fullName = values.fullName !== "" ? "" : "Full name is required."
    temp.mobile = values.mobile !== "" ? "" : "Mobile no. is required."
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    temp.education = values.education !== "" ? "" : "Education is required."
    temp.expDuration = values.expDuration !== "" ? "" : "Experience is required."
    temp.fieldOfInt = values.fieldOfInt !== "" ? "" : "Field of interest is required."
    temp.fieldOfExp = values.fieldOfExp !== "" ? "" : "Field of experience is required."
    temp.aplOrigin = values.aplOrigin !== "" ? "" : "Application origin is required."
    temp.department = values.department !== "" ? "" : "Department is required."
    temp.prevComp = values.prevComp !== "" ? "" : "Previous company is required."
    temp.lastDegPassYr = (values.lastDegPassYr !== "" && values.lastDegPassYr !== null) ? "" : "Last degree passing year is required."

    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: 'success',
            title: 'Awesome!',
            subTitle: 'You hove successfully upload your CV.',
          });
        } else if (res.status === 409 && res.msg) {
          setErrors({
            errors,
            'email': res.msg
          })
        } else {
          setAlert({
            isOpen: true,
            type: 'error',
            title: res.status,
            subTitle: res.msg && res.msg,
          })
        }
      }
      _update(ENDPOINTS.Employee + CNTRL_ACTN.Update, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.LOGIN)
    }
  }

  useEffect(() => {
    if (!IsEmptyObj(_authUser) && _authUser.result !== null && _authUser.result !== undefined) {
      setValues({ ...values, ...RemoveNullPropFromObj(_authUser.result) })
    }
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser])

  return (
    <Container maxWidth='lg' className='mt-20'>
      <Paper>
        <Alert alert={alert} setAlert={setAlert} />
        <Form noValidate
          onSubmit={submitForm}>
          <Grid container className='pl-20 pr-20 pt-10 pb-30' spacing={3}>
            <Grid item xs={12} className='text-center mb-10 pos-relative'>
              <Typography variant='p' className='bottom-border color-dark font-24'>
                Upload your CV
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className='d-flex justify-center'>
              <InputImg
                name='photoFile'
                src={values.photo}
                rmv={() => { setValues({ ...values, 'photoFile': null, 'photo': '' }) }}
                value={values.photoFile}
                onChange={handleImg}
                error={errors.photoFile} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Full Name"
                    name="fullName"
                    value={values.fullName}
                    onChange={handleInputChange}
                    error={errors.fullName}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Mobile No."
                    name="mobile"
                    value={values.mobile}
                    onChange={handleInputChange}
                    error={errors.mobile}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Email Address"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    label='Applicant Origin'
                    name='aplOrigin'
                    className='input-primary w-100pr'
                    value={values.aplOrigin}
                    onChange={handleInputChange}
                    error={errors.aplOrigin}
                    options={aplOrigins} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    label='Education'
                    name='education'
                    className='input-primary w-100pr'
                    value={values.education}
                    onChange={handleInputChange}
                    error={errors.education}
                    options={educations} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    label='Department'
                    name='department'
                    className='input-primary w-100pr'
                    value={values.department}
                    onChange={handleInputChange}
                    error={errors.department}
                    options={departments} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Last Degree Passing Year"
                    name="lastDegPassYr"
                    views={['year']}
                    minDate={new Date('1950')}
                    maxDate={new Date()}
                    value={values.lastDegPassYr}
                    onChange={date => handleInputDate('lastDegPassYr', date !== null ? date.getFullYear() : '')}
                    error={errors.lastDegPassYr} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label="Experience"
                    name="expDuration"
                    type="number"
                    value={values.expDuration}
                    onChange={handleInputChange}
                    error={errors.expDuration}
                    className='input-primary w-100pr'
                    onInput={e => MaxInput(e, 2)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Year</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    label='Field of Interest '
                    name='fieldOfInt'
                    className='input-primary w-100pr'
                    value={values.fieldOfInt}
                    onChange={handleInputChange}
                    error={errors.fieldOfInt}
                    options={interests} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    label='Field of Experience '
                    name='fieldOfExp'
                    className='input-primary w-100pr'
                    value={values.fieldOfExp}
                    onChange={handleInputChange}
                    error={errors.fieldOfExp}
                    options={experiences} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Previous Company"
                name="prevComp"
                value={values.prevComp}
                onChange={handleInputChange}
                error={errors.prevComp}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Computer Expertise"
                name="computerExp"
                value={values.computerExp}
                onChange={handleInputChange}
                error={errors.computerExp}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label="Special Quality"
                name="specialQuality"
                value={values.specialQuality}
                onChange={handleInputChange}
                error={errors.specialQuality}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label="Home District"
                name="homeDist"
                value={values.homeDist}
                onChange={handleInputChange}
                error={errors.homeDist}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FileInput
                label='Attach your CV'
                name='attachCVFile'
                src={values.attachCV}
                rmv={() => { setValues({ ...values, 'attachCVFile': null, 'attachCV': '' }) }}
                value={values.attachCVFile}
                onChange={handleImg}
                error={errors.attachCVFile}
              />
            </Grid>
            <Grid item xs={12}>
              <InputRich
                label='Details: '
                type="simple"
                value={values.details}
                onBlur={(val) => setValues({ ...values, details: val })} />
            </Grid>
            <Grid item xs={12} className='text-right'>
              <Button
                type='submit'
                variant='contained'
                className='btn-primary font-20'
                size='large'>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Container>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _fetch: actions.fetchAll,
  _update: actions.update
}

export default connect(mapStateToProps, mapActionToProps)(EmployeeCV);