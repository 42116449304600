import React, { useState } from 'react';
import { Input, Button, Grid, Card, Grow } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function FileInput(props) {
  const { name, label, onChange, error = null, rmv, src = null } = props;

  const [sizeErr, setSizeErr] = useState();
  const [fileName, setFileName] = useState();

  const handleFile = e => {
    let file = e.target.files[0];
    if (e.target.files && e.target.files[0] && (file.size / 1048576) < 6) {
      onChange({ name, value: file });
      file !== undefined && setFileName(file.name)
      setSizeErr();
    } else setSizeErr('Maximum Allowed 6MB')
  }
  const rmvImg = () => {
    setFileName()
    rmv !== undefined && rmv();
  }

  return (
    <Card elevation={2} className='p-5'>
      <p className='mt-0 mb-5'>
        <span className='font-bold color-gray'>File Name:&nbsp;&nbsp;</span>
        {src !== null && src !== ""
          ? <a href={src} target="_blank" rel="noreferrer">{src}</a>
          : fileName}
      </p>
      <Grid className='d-flex'>
        <label htmlFor={name} className='w-100pr'>
          <Input
            className='d-none'
            accept="image/*"
            id={name}
            onChange={handleFile}
            multiple type="file" />
          <Button
            className='btn-primary w-100pr'
            startIcon={<DriveFolderUploadIcon />}
            component="span">
            {label}
          </Button>
        </label>
        {(fileName || (src !== null && src !== "")) && <Grow in timeout={800}>
          <Button
            onClick={rmvImg}
            className='btn ml-5'>
            <CloseIcon />
          </Button>
        </Grow>}
      </Grid>
      {(error || sizeErr) && <FormHelperText sx={{ color: '#d32f2f' }}>{error}{sizeErr}</FormHelperText>}
    </Card>

  )
}
