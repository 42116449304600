import { Container, Divider, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux';
import { DropLi } from '../../../hooks';

const ProfileComp = (props) => {
  const { user } = props;
  return (
    <Paper className='p-10'>
      <Grid item xs={12} className='bg-secondary p-20 border-radious-5'>
        <Grid className='text-center mt-10'>
        <img
            alt='user-img'
            className='h-150 w-150 object-cover border-circle border-primary-2 p-5'
            src={user.logo !== null ? user.logo : process.env.PUBLIC_URL + '/img/other/no-img.jpg'} />
        </Grid>
        <Grid className='text-center'>
          <Typography variant='h6' className='mt-15'>
            {user.companyName}
          </Typography>
          <Divider className='bg-gray' />
          <Typography variant='subtitle' className='font-20 mt-20 color-light'>
            {user.fullName}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

const EmployerPrfl = (props) => {
  const { _authUser } = props;
  const {
    fellowMemberNo,
    employerOrigins,
  } = DropLi();
  return (
    <Container>
      {(_authUser.result !== undefined && _authUser.result.fullName !== undefined) && <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ display: { md: 'none', xs: 'block' } }}>
          <ProfileComp {...{user: _authUser.result}} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className='p-20'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Name:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.fullName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Company Name:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.companyName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Fellowship No/Membership No:
                </Typography>
                <Typography variant='subtitle1'>
                {_authUser.result.fellowMemberNo + " " + fellowMemberNo.find(j => j.id === _authUser.result.fellowMemberNoType).name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Email Address:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Mobile No.:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.mobile}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Employer Origin:
                </Typography>
                <Typography variant='subtitle1'>
                  {employerOrigins.find(j => j.id === _authUser.result.employerOrigin).name}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <ProfileComp {...{user: _authUser.result}} />
        </Grid>
      </Grid>}
    </Container>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(EmployerPrfl);