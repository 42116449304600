import { Container, Paper } from '@mui/material';
import React from 'react'
import { PageTitle } from '../../../hooks/Methods';
import MemberAcc from '../../account/MemberAcc'

export default function MemberPrflEdit(props) {
  const { title } = props;
  PageTitle(title);
  return (
    <Container className='mt-30'>
      <Paper>
        <MemberAcc {...{ isUpdate: true }} />
      </Paper>
    </Container>
  )
}
