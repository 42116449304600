import React, { useState, useEffect, useContext } from 'react'
import {
  Button,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Input, Select, FileInput } from '../../../controls';
import { DropLi, UseForm, Model } from '../../../hooks';
import { Form, Alert } from '../../../layout';
import { MaxInput, RemoveEmptyPropFromObj } from '../../../hooks/Methods';
import { connect } from 'react-redux';
import * as actions from '../../../actions/ieb';
import { LoaderContext } from '../../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';

const AddEditCircular = (props) => {
  const {
    setShowForm,
    circular,
    setCircular,
    _create,
    _update,
    _fetchById,
  } = props;
  const { CircularMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  const {
    departments,
    educations,
    experiences,
    emplStatus,
    employerOrigins } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleImg,
    toFormData
  } = UseForm(CircularMdl);

  useEffect(() => {
    if (circular !== undefined && circular.id !== undefined) {
      const onComplete = (res) => {
        (res.status === 200 && res.result !== undefined) && setValues({ ...res.result });
      }
      _fetchById(ENDPOINTS.Employer + CNTRL_ACTN.GetCircularById, circular.id, onComplete, actions.ACTION_TYPES.CREATE);
    }
    return () => {
      setCircular();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circular])

  const validateForm = () => {
    let temp = {};
    temp.jobTitle = values.jobTitle !== "" ? "" : "Job title is required."
    temp.email = values.email !== "" ? "" : "Email is required."
    temp.vacancy = values.vacancy !== "" ? "" : "Vacancy is required."
    temp.jobContext = values.jobContext !== "" ? "" : "Job context is required."
    temp.jobResp = values.jobResp !== "" ? "" : "Job responsibilities is required."
    temp.origin = values.origin !== "" ? "" : "Origin is required."
    temp.emplStatus = values.emplStatus !== "" ? "" : "Employment status is required."
    temp.education = values.education !== "" ? "" : "Educational qualification is required."
    temp.department = values.department !== "" ? "" : "Department is required."
    temp.expDuration = values.expDuration !== "" ? "" : "Experience is required."
    temp.fieldOfExp = values.fieldOfExp !== "" ? "" : "Field of experience is required."
    temp.jobLocation = values.jobLocation !== "" ? "" : "Job location is required."
    temp.salary = values.salary !== "" ? "" : "Salary is required."

    setErrors({ ...temp });
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      // Create Action
      if (values.id === 0) {
        const onComplete = (res) => {
          setShowLoader(false);
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: 'success',
              title: 'Awesome!',
              subTitle: 'You hove successfully added a circual.',
              onClick: handleClose
            });
          } else if (res.status === 409) {
            setErrors({
              ...errors,
              email: res.msg
            })
          }
        }
        _create(ENDPOINTS.Employer + CNTRL_ACTN.AddCircular, toFormData(values), onComplete, actions.ACTION_TYPES.ADD_LIST);
      }// Update Action
      else if (values.id !== undefined && values.id !== 0) {
        const onComplete = (res) => {
          setShowLoader(false);
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: 'success',
              title: 'Awesome!',
              subTitle: 'You hove successfully updated.',
              onClick: handleClose
            });
          } else if (res.status === 409 && res.msg) {
            setErrors({
              errors,
              'email': res.msg
            })
          } else {
            setAlert({
              isOpen: true,
              type: 'error',
              title: res.status,
              subTitle: res.msg && res.msg,
            })
          }
        }
        _update(ENDPOINTS.Employer + CNTRL_ACTN.PutCircular, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.UPDATE_LIST)
      }
    }
  }

  const handleClose = () => {
    setShowForm(false);
  }

  return (
    <>
      <Alert
        alert={alert}
        setAlert={setAlert} />
      <Form noValidate
        onSubmit={submitForm}>
        <Grid container className='pl-20 pr-20 pt-10 pb-30' spacing={2}>
          <Grid item xs={12} className='text-center mb-20 mt-30 pos-relative'>
            <Typography variant='p' className='bottom-border color-dark font-24'>
              {circular ? 'Edit Circular' : 'Add Circular'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Job Title"
              name="jobTitle"
              value={values.jobTitle}
              onChange={handleInputChange}
              error={errors.jobTitle}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Vacancy"
              name="vacancy"
              type='number'
              value={values.vacancy}
              onChange={handleInputChange}
              error={errors.vacancy}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label='Employment Status'
              name='emplStatus'
              className='input-primary w-100pr'
              value={values.emplStatus}
              onChange={handleInputChange}
              error={errors.emplStatus}
              options={emplStatus} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label='Educational Qualification'
              name='education'
              className='input-primary w-100pr'
              value={values.education}
              onChange={handleInputChange}
              error={errors.education}
              options={educations} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label='Department'
              name='department'
              className='input-primary w-100pr'
              value={values.department}
              onChange={handleInputChange}
              error={errors.department}
              options={departments} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label='Field of Experience'
              name='fieldOfExp'
              className='input-primary w-100pr'
              value={values.fieldOfExp}
              onChange={handleInputChange}
              error={errors.fieldOfExp}
              options={experiences} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              label='Origin'
              name='origin'
              className='input-primary w-100pr'
              value={values.origin}
              onChange={handleInputChange}
              error={errors.origin}
              options={employerOrigins} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Experience"
              name="expDuration"
              type="number"
              value={values.expDuration}
              onChange={handleInputChange}
              error={errors.expDuration}
              className='input-primary w-100pr'
              onInput={e => MaxInput(e, 2)}
              InputProps={{
                endAdornment: <InputAdornment position="end">Year</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label="Salary"
              name="salary"
              value={values.salary}
              onChange={handleInputChange}
              error={errors.salary}
              className='input-primary w-100pr'
              InputProps={{
                startAdornment: values.salary && <InputAdornment position="start">৳</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Additional Requirements"
              name="additionalReq"
              value={values.additionalReq}
              onChange={handleInputChange}
              error={errors.additionalReq}
              multiline
              rows={4}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Special Criteria"
              name="specialCriteria"
              value={values.specialCriteria}
              onChange={handleInputChange}
              error={errors.specialCriteria}
              multiline
              rows={4}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Job Context"
              name="jobContext"
              value={values.jobContext}
              onChange={handleInputChange}
              error={errors.jobContext}
              multiline
              rows={5}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Job Responsibilites"
              name="jobResp"
              value={values.jobResp}
              onChange={handleInputChange}
              error={errors.jobResp}
              multiline
              rows={5}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Compensation & Other Benefits"
              name="otherBenifits"
              value={values.otherBenifits}
              onChange={handleInputChange}
              error={errors.otherBenifits}
              multiline
              rows={4}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Job Location"
              name="jobLocation"
              value={values.jobLocation}
              onChange={handleInputChange}
              error={errors.jobLocation}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FileInput
              label='Attach Job Circular (If Any)'
              name='attachCircularFile'
              src={values.attachCircular}
              rmv={() => { setValues({ ...values, 'attachCircular': '', 'attachCircularFile': '' }) }}
              value={values.attachCircularFile}
              onChange={handleImg}
              error={errors.attachCircularFile}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              type='submit'
              variant='contained'
              className='btn-primary font-20 w-100pr'
              size='large'>
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => handleClose()}
              variant='contained'
              className='btn font-20 w-100pr'
              size='large'>
              Close
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _create: actions.create,
  _update: actions.update,
  _fetchById: actions.fetchAllById,
}

export default connect(mapStateToProps, mapActionToProps)(AddEditCircular);
