import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import * as actions from '../../../../actions/ieb';
import { Input, InputRich } from '../../../../controls';
import InputImg from '../../../../controls/InputImg';
import { Model, UseForm } from '../../../../hooks';
import { PageTitle, RemoveEmptyPropFromObj } from '../../../../hooks/Methods';
import { Alert, Form } from '../../../../layout';
import { LoaderContext } from '../../../../utils/Context';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';


const AddOrEditPage = (props) => {
  const {
    updData,
    setUpdData,
    _create,
    _update,
    setActiveStep,
    pageType = 0, } = props;
  const { PageMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  PageTitle(updData ? 'Update Post' : 'Create Post');

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    toFormData,
    handleImg
  } = UseForm(PageMdl);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : "Full name is required."
    if (parseInt(pageType) !== 4) temp.photoFile = values.photoFile !== "" ? "" : "Cover photo is required."
    temp.description = values.description !== "" ? "" : "Description is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: 'success',
            title: 'Awesome!',
            subTitle: `You have successfully ${updData ? 'updated' : 'created'}.`,
            onClick: () => setActiveStep(0)
          })
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg
          })
        }
      }
      // Update Action
      if (updData !== undefined) {
        _update(ENDPOINTS.Admin + CNTRL_ACTN.UpdatePage,
          toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.UPDATE_LIST);

        // Create Action
      } else {
        values.pageType = pageType;
        _create(ENDPOINTS.Admin + CNTRL_ACTN.CreatePage,
          toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.ADD_LIST);
      }
    }
  }

  useEffect(() => {
    updData && setValues({ ...updData });
    return () => {
      setUpdData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Alert alert={alert} setAlert={setAlert} />
      <Container maxWidth='xl'>
        <Paper className='p-15 mt-10'>
          <Form noValidate
            onSubmit={submitForm}>
            <Grid container spacing={3}>
              <Grid item xs={12} container justifyContent='space-between' className='mb-30'>
                <Grid>
                  {setActiveStep && <IconButton
                    onClick={() => setActiveStep(0)}
                    className='btn-secondary-trans m-2'>
                    <ArrowBackIosRoundedIcon className='font-18 m-2' />
                  </IconButton>}
                </Grid>
                <Grid className='text-center pos-relative'>
                  <Typography
                    variant='p'
                    className='bottom-border color-dark'
                    sx={{ fontSize: { xs: '25px', md: '30px' } }}>
                    {updData ? 'Update Post' : 'Create Post'}
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    type='submit'
                    className='btn-primary'>
                    {updData ? 'Save Changes' : 'Publish'}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Title"
                  name="title"
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12}>
                <InputRich
                  name='description'
                  value={values.description}
                  error={errors.description}
                  onBlur={val => {
                    setValues({ ...values, description: val });
                    values.description !== "" && setErrors({ ...errors, description: false });
                  }} />
              </Grid>
              <Grid item xs={12} className='d-flex'>
                <InputImg
                  name='photoFile'
                  title='Attach Cover Photo'
                  defImg={process.env.PUBLIC_URL + '/img/other/no-image.png'}
                  src={values.coverPhoto}
                  rmv={() => { setValues({ ...values, 'photoFile': '', 'coverPhoto': '' }) }}
                  value={values.photoFile}
                  onChange={handleImg}
                  error={errors.photoFile} />
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Container>
    </>
  )
}
const mapStateToProps = state => ({
  _data: state.ieb.data,
  _admin: state.ieb.admin
})
const mapActionToProps = {
  _create: actions.create,
  _update: actions.update
}
export default connect(mapStateToProps, mapActionToProps)(AddOrEditPage);
