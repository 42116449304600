import { useState } from 'react'

export default function UseForm(getFreshModelObject) {
  const [values, setValues] = useState(getFreshModelObject());
  const [errors, setErrors] = useState({});
  const [passValidation, setPassValidation] = useState({
    match: null,
    charNumberValid: false,
    specialCharValid: false,
    uppercaseValid: false,
    numberValid: false,
    passValid: false
  });

  // Handle Input Value
  const handleInputChange = (e) => {
    const { name, value } =  e.target;
    setValues({
      ...values,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: false
    })
  }

  const handleImg = obj => {
    const { name, value } =  obj;
    setValues({
      ...values,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: false
    })
  }

  // Handle Input Password
  const handleInputPass = (e, confirmPass = false) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value
    });

    if (!confirmPass) {
      setPassValidation({
        charNumberValid: value.length >= 6 ? true : false,
        specialCharValid: (/[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g).test(value) ? true : false,
        uppercaseValid: (/[A-Z]/).test(value) ? true : false,
        numberValid: (/[0-9]/).test(value) ? true : false,
        passValid: value.length >= 6
          && (/[ !@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/).test(value)
          && (/[A-Z]/).test(value)
          && (/[0-9]/).test(value) ? true : false
      });
    } else {
      setPassValidation({
        ...passValidation,
        match: values.password === value ? true : false
      })
    }
    setErrors({
      ...errors,
      [name]: false
    })
  }

  // Handle Input Date Value
  const handleInputDate = (name, date) => {
    setValues({
      ...values,
      [name]: date
    })
    setErrors({
      ...errors,
      [name]: false
    })
  }

  // Handle Input Check Value
  const handleInputCheck = e => {
    const { name, checked } = e.target
    setValues({
      ...values,
      [name]: checked
    })
    setErrors({
      ...errors,
      [name]: false
    })
  }

  const resetFormControls = () => {
    setValues(getFreshModelObject());
    setErrors({})
  }

  const toFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    passValidation,
    setPassValidation,
    handleInputChange,
    handleInputPass,
    handleInputDate,
    handleInputCheck,
    resetFormControls,
    toFormData,
    handleImg,
  }
}
