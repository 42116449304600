import { Container, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { PageTitle } from '../../../../hooks/Methods';

export default function ViewPage({ viewData, cls = '' }) {

  PageTitle(viewData.title);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return () => {
    }
  }, [])

  return (
    <Container maxWidth='xl'>
      {viewData &&
        <Paper className={'m-15 p-20 ' + cls}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h5' className='color-secondary'>{viewData.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className='font-bold color-gray'>Post Date: {viewData.createDate}</Typography>
            </Grid>
            <Grid item xs={12}>
              <div dangerouslySetInnerHTML={{ __html: viewData.description }} className='table-border' />
            </Grid>
          </Grid>
        </Paper>
      }
    </Container>
  )
}
