import {
  Container,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Typography,
  Divider,
  Button,
  Pagination
} from '@mui/material'
import React, { useContext, useState, useEffect } from 'react';
import { Input } from '../../controls';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import JobSort from '../sorting/Sorting';
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog } from '../../layout';
import { DropLi } from '../../hooks';
import { BackToTop, FilterList, PageTitle } from '../../hooks/Methods';
import { connect } from 'react-redux';
import * as actions from '../../actions/ieb';
import { LoaderContext } from '../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import Ads from '../Ads/Ads';
import ViewSeekers from './ViewSeekers';
import { Route, Routes, useNavigate } from 'react-router-dom';

let pageSize = 10;
const Seekers = (props) => {
  const { _fetchAll, _list } = props;
  const { setShowLoader } = useContext(LoaderContext);
  const { departments, educations, interests, experiences, aplOrigins } = DropLi();

  const [showMblSort, setShowMblSort] = useState(false);
  const [list, setList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [orgn, setOrgn] = useState([]);
  const [edu, setEdu] = useState([]);
  const [fieldOfExp, setFieldOfExp] = useState([]);
  const [dept, setDept] = useState([]);
  const [expVal, setExpVal] = useState([1, 40]);
  const [inte, setInte] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  PageTitle('Job Seekers');
  BackToTop();

  const handleView = (val) => navigate(`viewProfile/${val.id}`);

  useEffect(() => {
    setShowLoader(true);
    const onComplete = (res) => {
      setShowLoader(false);
      setList(res.result);
    }
    _fetchAll(ENDPOINTS.Employee + CNTRL_ACTN.GetAll, onComplete);

    return () => {
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (_list.length > 0 && _list[0].fieldOfInt !== undefined)
      && FilterList(
        _list,
        setList,
        searchVal,
        expVal,
        dept,
        fieldOfExp,
        edu,
        orgn,
        inte);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dept, fieldOfExp, expVal, searchVal, edu, orgn, inte])


  const DefaultView = (
    <Container maxWidth='xl' className='mt-120 mb-50'>
      <Grid container spacing={2}>
        {/* Sorting */}
        <Grid item md={3} xs={12} sx={{ display: { md: 'block', xs: 'none' } }}>
          <JobSort
            {...{
              expVal,
              setExpVal,
              edu,
              setEdu,
              dept,
              setDept,
              orgn,
              setOrgn,
              fieldOfExp,
              setFieldOfExp,
              inte,
              setInte
            }}
          />
        </Grid>
        <Dialog
          show={showMblSort}
          setShow={setShowMblSort}
          fullScreen={true}>
          <JobSort
            {...{
              expVal,
              setExpVal,
              edu,
              setEdu,
              dept,
              setDept,
              orgn,
              setOrgn,
              fieldOfExp,
              setFieldOfExp,
              inte,
              setInte
            }}
          />
        </Dialog>
        <Grid item md={9} xs={12}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='subtitle1'
                className='font-18 color-secondary border-left'>Job Corner {'>'} Job Seekers</Typography>
            </Grid>
            {/* Searchbar */}
            <Grid item xs={12}>
              <Paper className='p-15 d-flex align-center'>
                <IconButton
                  onClick={() => setShowMblSort(true)}
                  className='mr-10'
                  sx={{ display: { md: 'none', xs: 'block' } }}>
                  <TuneIcon className='font-28 color-secondary' />
                </IconButton>
                <Input
                  label="Search"
                  name="search"
                  placeholder='Key words'
                  className='input-primary w-100pr'
                  onChange={e => setSearchVal(e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton size='small' className='hov-primary-text'>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Paper>
            </Grid>
            {/* Body */}
            {list.length > 0 && list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize).map((empl, i) =>
              <Grid key={i} item xs={12}>
                <Paper className='p-10 hover'
                  onClick={() => handleView(empl)}>
                  <Grid container direction='row' spacing={2}>
                    <Grid item md={3} xs={12} className='text-center'>
                      <img
                        src={empl.photo !== null ? empl.photo : process.env.PUBLIC_URL + "/img/other/no-img.jpg"}
                        alt='ABCD'
                        className='img-fluid border-radious-5 h-250 object-cover' />
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            className='color-secondary font-bold hover-underline'>
                            {empl.fullName}
                          </Typography>
                          <Typography
                            variant='subtitle1'
                            className='color-dark font-bold'>
                            {educations.find(j => j.id === empl.education).name + " in " + departments.find(j => j.id === empl.department).name} Engineering
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item md={5} xs={12} className='color-gray' sx={{ borderRight: { md: '1px solid rgb(0 0 0 / 12%)' } }}>
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Contact No:</span> <a href={'tel:' + empl.mobile}>{empl.mobile}</a>
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Email:</span> <a href={'mailto:' + empl.email}>{empl.email}</a>
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Last Degree Passing Year:</span> {empl.lastDegPassYr}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Experience:</span> {empl.expDuration} Years
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Application Origin:</span> {aplOrigins.find(j => j.id === empl.aplOrigin).name}
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item md={7} xs={12} className='color-gray'>
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Previous Company:</span> {empl.prevComp}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Computer Expertise:</span> {empl.computerExp}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Field of Experience:</span> {experiences.find(j => j.id === empl.fieldOfExp).name}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Field of Interest:</span> {interests.find(j => j.id === empl.fieldOfInt).name}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Special Quality:</span> {empl.specialQuality}
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} className='color-gray'>
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Home District:</span> {empl.homeDist}
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} className='text-right mt-10'>
                          {empl.attachCV !== null && <Button
                            className='btn-primary border-radious-25 pr-10 pl-10'
                            size='small'
                            endIcon={<DownloadIcon />}>
                            <a href={empl.attachCV} target="_blank" rel="noreferrer">View CV</a>
                          </Button>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} className='text-center'>
              <Ads />
            </Grid>
            <Grid item xs={12} className='mt-30 d-flex justify-center'>
              <Paper className='p-15 border-radious-30'>
                <Pagination
                  color='primary'
                  count={Math.ceil(list.length / pageSize)}
                  defaultPage={1}
                  onChange={(event, newPage) => setPage(newPage)}
                  showFirstButton={true}
                  showLastButton={true} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )

  return (
    <Routes>
      {_list.map((seekers, i) =>
        <Route
          key={i}
          path={`viewProfile/${seekers.id}`}
          element={<ViewSeekers {...{ seekers }} />} />)}
      <Route index element={DefaultView} />
    </Routes>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser,
  _list: state.ieb.list
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(Seekers);