import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { DropLi } from '../../../../hooks';
import { JsonToExcel, PageTitle } from '../../../../hooks/Methods';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import { Alert, Table } from '../../../../layout';

const Student = (props) => {
  const {
    _fetchAll,
    _list,
    _delete } = props;
  const {
    eduBgs,
    departments,
    experiences,
    bloods,
    parts
  } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  })

  PageTitle("Student");

  const handleDlt = (props) => {
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to delete this record?',
      onConfirm: () => {
        if (props.id !== undefined && props.id !== 0) {
          const onComplete = (res) => {
            setAlert({
              isOpen: true,
              type: res.status === 200 ? 'success' : 'error',
              title: res.status === 200 ? 'Deleted Successfully' : 'OPPS',
              subTitle: res.msg
            })
          }
          _delete(ENDPOINTS.Student + CNTRL_ACTN.Delete,
            props.id,
            onComplete,
            actions.ACTION_TYPES.REMOVE_LIST);
        }
      },
    })
  }


  const handleExcel = (data) => {
    const result = data.map(({ photoFile, ...rest }) => ({
      ...rest,
      photo: rest.photo.props.href,
    }));
    JsonToExcel(result.map(j => {
      delete Object.assign(j, { 'Id': j.id })['id'];
      delete Object.assign(j, { 'Full Name': j.fullName })['fullName'];
      delete Object.assign(j, { 'Studentship No.': j.stuNo })['stuNo'];
      delete Object.assign(j, { 'Department': j.department })['department'];
      delete Object.assign(j, { 'Blood Group': j.bloodGroup })['bloodGroup'];
      delete Object.assign(j, { 'Mobile': j.mobile })['mobile'];
      delete Object.assign(j, { 'Email': j.email })['email'];
      delete Object.assign(j, { 'Educational Bacground': j.eduBg })['eduBg'];
      delete Object.assign(j, { 'Part': j.part })['part'];
      delete Object.assign(j, { 'Experience Duration': j.expDuration })['expDuration'];
      delete Object.assign(j, { 'Field of Experience': j.fieldOfExp })['fieldOfExp'];
      delete Object.assign(j, { 'Present Organization': j.presentOrg })['presentOrg'];
      delete Object.assign(j, { 'Present Designation': j.presentDes })['presentDes'];
      delete Object.assign(j, { 'Home District': j.homeDist })['homeDist'];
      delete Object.assign(j, { 'Photo': j.photo })['photo'];
      return j;
    }), 'Student');
  }

  useEffect(() => {
    _fetchAll(ENDPOINTS.Student + CNTRL_ACTN.GetAll);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Circular List || Table */}
      <Container maxWidth='xl'>
        <Table
          showDownloadBtn={true}
          showEdit={false}
          showAddBtn={false}
          handleExcel={handleExcel}
          handleDlt={handleDlt}
          tblHead={[
            { id: 'actions', label: 'Action' },
            { id: 'id', label: 'Id' },
            { id: 'fullName', label: 'Full Name' },
            { id: 'stuNo', label: 'Studentship No.' },
            { id: 'department', label: 'Department' },
            { id: 'bloodGroup', label: 'Blood Group' },
            { id: 'mobile', label: 'Mobile' },
            { id: 'email', label: 'Email' },
            { id: 'eduBg', label: 'Educational Bacground' },
            { id: 'part', label: 'Part' },
            { id: 'expDuration', label: 'Experience Duration' },
            { id: 'fieldOfExp', label: 'Field of Experience ' },
            { id: 'presentOrg', label: 'Present Organization' },
            { id: 'presentDes', label: 'Present Designation' },
            { id: 'homeDist', label: 'Home District' },
            { id: 'photo', label: 'Photo' },
          ]}
          tblData={(_list.length > 0 && _list[0].eduBg !== undefined) ? _list.map(obj => {
            return {
              ...obj,
              'department': obj.department === 0 ? "" : departments.find(j => j.id === obj.department).name,
              'bloodGroup': obj.bloodGroup === 0 ? "" : bloods.find(j => j.id === obj.bloodGroup).name,
              'eduBg': obj.eduBg === 0 ? "" : eduBgs.find(j => j.id === obj.eduBg).name,
              'part': obj.part === 0 ? "" : parts.find(j => j.id === obj.part).name,
              'fieldOfExp': obj.fieldOfExp === 0 ? "" : experiences.find(j => j.id === obj.fieldOfExp).name,
              'expDuration': obj.expDuration && obj.expDuration + ' year',
              'photo': <a href={obj.photo} target="_blank" rel="noreferrer" className='hov-primary'>View Logo</a>,
            }
          }) : []} />
      </Container>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  )
}

const mapStateToProps = state => ({
  _list: state.ieb.list,
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
  _delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(Student);
