import React, { useState } from 'react';
import LoginForm from './LoginForm';
import SendFrgtCode from './forgot/SendFrgtCode';
import VerifyFrgtCode from './forgot/VerifyFrgtCode';
import ChangePass from './forgot/ChangePass';
import { Button, Grid } from '@mui/material';
import { Model } from '../../../hooks';
import LoginIcon from '@mui/icons-material/Login';
import Dialog from '../../../layout/Dialog';

export default function Login(props) {
  const { showIcon = true, onShowFun, isAdmin = false, ...other } = props;
  const { ForgotMdl } = Model()
  const [forgotVal, setForgotVal] = useState(ForgotMdl());
  const [activeStep, setActiveStep] = useState();
  const [showForm, setShowForm] = useState(false);

  const handleShow = () => {
    setShowForm(true);
    onShowFun && onShowFun();
  }

  const Content = (step) => {
    switch (step) {
      case 1:
        return (
          <SendFrgtCode {...{ setActiveStep, forgotVal, setForgotVal, isAdmin }} />
        )
      case 2:
        return (
          <VerifyFrgtCode {...{ setActiveStep, forgotVal, setForgotVal, isAdmin }} />
        )
      case 3:
        return (
          <ChangePass {...{ setActiveStep, forgotVal, setForgotVal, isAdmin }} />
        )
      default:
        return (
          <LoginForm {...{ handleShow, setActiveStep, forgotVal, setForgotVal, isAdmin }} />
        )
    }
  }

  return (
    <>
      {
        !isAdmin ?
          <>
            <Button
              onClick={handleShow}
              {...other}>Log In{showIcon && <LoginIcon className='font-16 ml-5' />}</Button>
            {showForm && <Dialog
              show={showForm}
              onClose={false}
              setShow={setShowForm}
              maxWidth='xs'>
              <Grid className='overflow-hidden'>
                {Content(activeStep)}
              </Grid>
            </Dialog>}
          </> :
          <Grid className='overflow-hidden'>
            {Content(activeStep)}
          </Grid>
      }
    </>
  )
}
