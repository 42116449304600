import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
import Slide from '@mui/material/Slide';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export default function Notify(props) {
  const { notify, open, setOpen } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {notify &&
        <Snackbar open={open}
          autoHideDuration={4000}
          onClose={handleClose}>
          <Slide in={open}>
            <Alert onClose={handleClose} severity={notify.type || 'success'} sx={{ width: '100%' }}>
              {notify.title && <AlertTitle>{notify.title}</AlertTitle>}
              {notify.msg}
            </Alert>
          </Slide>
        </Snackbar>}
    </>
  );
}