import {
  Container,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Typography,
  Divider,
  Pagination
} from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { Input } from '../../controls';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import JobSort from '../sorting/Sorting';
import { Dialog } from '../../layout';
import { BackToTop, FilterList, PageTitle } from '../../hooks/Methods';
import { LoaderContext } from '../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import { connect } from 'react-redux';
import * as actions from '../../actions/ieb';
import { DropLi } from '../../hooks';
import Ads from '../Ads/Ads';
import ViewAlumniMember from './ViewAlumniMember';
import { Route, Routes, useNavigate } from 'react-router-dom';

let pageSize = 10;
const Alumni = (props) => {
  const { _fetchAll, _list } = props;
  const { setShowLoader } = useContext(LoaderContext);
  const { departments, educationals, fellowMemberNo, bloods, experiences } = DropLi();

  const [list, setList] = useState([]);
  const [showMblSort, setShowMblSort] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [fieldOfExp, setFieldOfExp] = useState([]);
  const [dept, setDept] = useState([]);
  const [expVal, setExpVal] = useState([1, 40]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  PageTitle('Alumni Mumber');
  BackToTop();

  useEffect(() => {
    setShowLoader(true);
    const onComplete = (res) => {
      setShowLoader(false);
      setList(res.result);
    }
    _fetchAll(ENDPOINTS.Member + CNTRL_ACTN.GetAll, onComplete);
    return () => {
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (_list.length > 0 && _list[0].educational !== undefined) && FilterList(_list, setList, searchVal, expVal, dept, fieldOfExp);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dept, fieldOfExp, expVal, searchVal])

  // navigate to view profile.
  const handleView = (val) => navigate(`viewProfile/${val.id}`)

  const DefaultView = (
    <Container maxWidth='xl' className='mt-120 mb-50'>
      <Grid container spacing={2}>
        {/* Sorting */}
        <Grid item md={3} xs={12} sx={{ display: { md: 'block', xs: 'none' } }}>
          <JobSort {...{
            expVal,
            setExpVal,
            fieldOfExp,
            setFieldOfExp,
            dept,
            setDept,
          }} />
        </Grid>
        <Dialog
          show={showMblSort}
          setShow={setShowMblSort}
          fullScreen={true}>
          <JobSort {...{
            expVal,
            setExpVal,
            fieldOfExp,
            setFieldOfExp,
            dept,
            setDept,
          }} />
        </Dialog>
        <Grid item md={9} xs={12}>
          <Grid container direction='row' spacing={2}>
            {/* Page Title */}
            <Grid item xs={12}>
              <Typography
                variant='subtitle1'
                className='font-18 color-secondary border-left'>Members {'>'} Alumni</Typography>
            </Grid>
            {/* Searchbar */}
            <Grid item xs={12}>
              <Paper className='p-15 d-flex align-center'>
                <IconButton
                  onClick={() => setShowMblSort(true)}
                  className='mr-10'
                  sx={{ display: { md: 'none', xs: 'block' } }}>
                  <TuneIcon className='font-28 color-secondary' />
                </IconButton>
                <Input
                  label="Search"
                  name="search"
                  placeholder='Key words'
                  className='input-primary w-100pr'
                  onChange={e => setSearchVal(e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton size='small' className='hov-primary-text'>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Paper>
            </Grid>
            {/* Body */}
            {list.length > 0 && list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
              .map((member, i) =>
                <Grid key={i} item xs={12}>
                  {/* member  */}
                  <Paper
                    className='p-10 hover'
                    onClick={() => handleView(member)}>
                    <Grid container direction='row' spacing={2}>
                      <Grid item md={3} xs={12} className='text-center d-flex justify-center align-center'>
                        <img
                          src={member.photo}
                          alt={member.fullName}
                          className='img-fluid border-radious-5 h-220 object-cover' />
                      </Grid>
                      <Grid item md={9} xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant='h6'
                              className='color-secondary font-bold hover-underline'>
                              {member.fullName}
                            </Typography>
                            <Typography
                              variant='subtitle1'
                              className='color-dark font-bold'>
                              {educationals.find(j => j.id === member.educational).name + " in " + departments.find(j => j.id === member.department).name} Engineering
                            </Typography>
                            <Divider />
                          </Grid>
                          <Grid item md={6} xs={12} className='color-gray' sx={{ borderRight: { md: '1px solid rgb(0 0 0 / 12%)' } }}>
                            <Typography
                              variant='subtitle1'>
                              <span className='font-bold'>Contact No:</span> <a href={'tel:' + member.mobile}>{member.mobile}</a>
                            </Typography>
                            <Divider />
                            <Typography
                              variant='subtitle1'>
                              <span className='font-bold'>Email:</span> <a href={'mailto:' + member.email}>{member.email}</a>
                            </Typography>
                            <Divider />
                            <Typography
                              variant='subtitle1'>
                              <span className='font-bold'>Graduation Year:</span> {member.gradYear}
                            </Typography>
                            <Divider />
                            <Typography
                              variant='subtitle1'>
                              <span className='font-bold'>Experience:</span> {member.expDuration} Years
                            </Typography>
                            <Divider />
                          </Grid>
                          <Grid item md={6} xs={12} className='color-gray'>
                            <Typography
                              variant='subtitle1'
                              sx={{ pl: { md: '10px', xs: '0px' } }}>
                              <span className='font-bold'>{fellowMemberNo.find(j => j.id === member.fellowMemberNoType).name + "/" + member.fellowMemberNo}</span>
                            </Typography>
                            <Divider />
                            <Typography
                              variant='subtitle1'
                              sx={{ pl: { md: '10px', xs: '0px' } }}>
                              <span className='font-bold'>Blood Group:</span> {bloods.find(j => j.id === member.bloodGroup).name}
                            </Typography>
                            <Divider />
                            <Typography
                              variant='subtitle1'
                              sx={{ pl: { md: '10px', xs: '0px' } }}>
                              <span className='font-bold'>Field of Experience:</span> {experiences.find(j => j.id === member.fieldOfExp).name}
                            </Typography>
                            <Divider />
                            <Typography
                              variant='subtitle1'
                              sx={{ pl: { md: '10px', xs: '0px' } }}>
                              <span className='font-bold'>Home District:</span> {member.homeDist}
                            </Typography>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} className='color-gray'>
                            <Typography
                              variant='subtitle1'>
                              <span className='font-bold'>Present Designation:</span> {member.presLastDes}
                            </Typography>
                            <Divider />
                            <Typography
                              variant='subtitle1'>
                              <span className='font-bold'>Present Organization:</span> {member.presLastOrg}
                            </Typography>
                            <Divider />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            <Grid item xs={12} className='text-center'>
              <Ads />
            </Grid>
            <Grid item xs={12} className='mt-30 d-flex justify-center'>
              <Paper className='p-15 border-radious-30'>
                <Pagination color='primary'
                  count={Math.ceil(list.length / pageSize)}
                  defaultPage={1}
                  onChange={(event, newPage) => setPage(newPage)}
                  showFirstButton={true}
                  showLastButton={true} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )

  return (
    <Routes>
      {_list.map((member, i) =>
        <Route
          key={i}
          path={`viewProfile/${member.id}`}
          element={<ViewAlumniMember {...{ member }} />} />)}
      <Route index element={DefaultView} />
    </Routes>
  );

}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser,
  _list: state.ieb.list
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(Alumni);