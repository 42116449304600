import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import { Input, Select } from '../../../../controls';
import InputImg from '../../../../controls/InputImg';
import { DropLi, UseForm } from '../../../../hooks';
import Model from '../../../../hooks/Model';
import { Alert, Form } from '../../../../layout';
import * as actions from '../../../../actions/ieb';
import { LoaderContext } from '../../../../utils/Context';
import { RemoveEmptyPropFromObj } from '../../../../hooks/Methods';

const AddEditSlider = (props) => {
  const {
    setShowForm,
    updData,
    setUpdData,
    _create,
    _update } = props;
  const { SliderMdl } = Model();
  const { imgsType } = DropLi();
  const { setShowLoader } = useContext(LoaderContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetFormControls,
    handleImg,
    toFormData,
  } = UseForm(SliderMdl);

  const validateForm = () => {
    let temp = {};
    temp.photoFile = values.photoFile !== "" ? "" : "Photo is required."
    temp.imgType = values.imgType !== "" ? "" : "Image type is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: 'success',
            title: 'Awesome!',
            subTitle: `You hove successfully ${updData ? "updated" : 'created'}.`,
            onClick: closeForm
          });
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg
          })
        }
      }
      updData
        ? _update(ENDPOINTS.Admin + CNTRL_ACTN.UpdateSlider,
          toFormData(RemoveEmptyPropFromObj(values)),
          onComplete, actions.ACTION_TYPES.UPDATE_LIST)
        : _create(ENDPOINTS.Admin + CNTRL_ACTN.CreateSlider,
          toFormData(RemoveEmptyPropFromObj(values)), onComplete,
          actions.ACTION_TYPES.ADD_LIST);
    }
  }

  const closeForm = () => {
    setShowForm(false);
    resetFormControls();
  }

  useEffect(() => {
    updData && setValues({
      ...updData,
      imgType: imgsType.find(j => j.name === updData.imgType).id,
      link: updData.link === null ? "" : updData.link.props.href,
      photo: updData.photo.props.href
    })
    return () => {
      setUpdData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData])


  return (
    <>
      <Alert
        alert={alert}
        setAlert={setAlert} />
      <Form noValidate
        onSubmit={submitForm}>
        <Grid container className='pl-20 pr-20 pt-30 pb-30' spacing={2}>
          <Grid item xs={12} className='text-center mb-30 pos-relative'>
            <Typography variant='p' className='bottom-border color-dark font-30'>
              {updData ? 'Update Slider' : 'Create Slider'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className='d-flex justify-center'>
            <InputImg
              name='photoFile'
              defImg={process.env.PUBLIC_URL + "/img/other/no-image.png"}
              title='Attach Photo'
              src={values.photo}
              rmv={() => { setValues({ ...values, 'photoFile': '', 'photo': '' }) }}
              value={values.photoFile}
              onChange={handleImg}
              error={errors.photoFile} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  label='Image Type'
                  name='imgType'
                  className='input-primary w-100pr'
                  value={values.imgType}
                  onChange={handleInputChange}
                  error={errors.imgType}
                  options={imgsType} />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Title (Opt.)"
                  name="title"
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="URL/Link (Opt.)"
                  name="link"
                  value={values.link}
                  onChange={handleInputChange}
                  error={errors.link}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Short Description"
                  name="shortDes"
                  value={values.shortDes}
                  onChange={handleInputChange}
                  error={errors.shortDes}
                  multiline
                  rows={4}
                  className='input-primary w-100pr'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              type='submit'
              variant='contained'
              className='btn-primary w-100pr font-20'
              size='large'>
              {updData ? "Save Changes" : "Submit"}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={closeForm}
              variant='contained'
              className='btn w-100pr font-20'
              size='large'>
              close
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _create: actions.create,
  _check: actions.check,
  _update: actions.update
}

export default connect(mapStateToProps, mapActionToProps)(AddEditSlider);
