import React from 'react';
import {
  Grid,
  Fab,
} from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';


const responsive = (large = 1, desktop = 1, tablet = 1, mobile = 1) => ({
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: large,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: desktop
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: tablet
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: mobile
  }
});

export default function MultCarousel(props) {
  const { showDots = true, large, desktop, tablet, mobile, children, ...other } = props;

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      index,
      active,
    } = rest;

    return (
      <span onClick={() => onClick()}>
        <FiberManualRecordIcon
          className={(active && "border-primary-2 ") + " color-primary font-16 border-circle mr-5 mb-20 cursor-pointer"} />
        {React.Children.toArray()[index]}
      </span>
    );
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide, totalItems } } = rest;
    return (
      <Grid className="pos-absolute" container >
        <Fab
          size="small"
          className={currentSlide === 0 ? 'd-none' : 'btn-primary left-0 pos-absolute ml-10 z-index-20'}
          onClick={() => previous()}>
          <NavigateBeforeRoundedIcon className="font-size-32" />
        </Fab>
        <Fab size="small" className={totalItems > (currentSlide + 1) ? 'btn-primary right-0 pos-absolute mr-10 z-index-20' : 'd-none'} onClick={() => next()} >
          <NavigateNextRoundedIcon className="font-size-32" />
        </Fab>
      </Grid>
    );
  };

  return (
    <Carousel 
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      responsive={responsive(large, desktop, tablet, mobile)}
      showDots={showDots}
      customDot={<CustomDot />}
      arrows={false}
      customButtonGroup={<ButtonGroup />}
      {...other}>
      {children}
    </Carousel>
  )
}
