import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';

export const WinDims = () => {
  const hasWindow = typeof window !== 'undefined';
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow]);
  return windowDimensions;
}

export const PageTitle = (title, defTitle = 'IEB-Alumni') => {
  const location = useLocation();
  location.pathname === '/'
    ? (document.title = defTitle)
    : (document.title = title);
}

export const FilterList = (
  list,
  setList,
  searchVal,
  expVal = [1, 40],
  dept = [],
  fieldOfExp = [],
  edu = [],
  orgn = [],
  inte = []) => {
  let result = list;
  // Search By Text
  if (searchVal !== "") result = result.filter(j => Object.keys(j).some(x => String(j[x])
    .toLowerCase()
    .includes(searchVal.toLowerCase())))
  // Fitler By Experiences
  if (expVal.length > 0) result = result.filter(j => j.expDuration >= expVal[0] && j.expDuration <= expVal[1]);
  // Filter By Department
  if (dept.length > 0) result = result.filter(j => dept.includes(j.department.toString()));
  // Filter By Filed_of_Experience
  if (fieldOfExp.length > 0) result = result.filter(j => fieldOfExp.includes(j.fieldOfExp.toString()));
  // Filter By Education
  if (edu.length > 0) result = result.filter(j => edu.includes(j.education.toString()));
  // Filter By Origin
  if (orgn.length > 0) result = result.filter(j => orgn.includes(j.aplOrigin !== undefined ? j.aplOrigin.toString() : j.origin.toString()));
  // Filter By Interest
  if (inte.length > 0) result = result.filter(j => inte.includes(j.fieldOfInt.toString()));
  setList(result)
}

export const BackToTop = () => {
  // BackToTop
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

export const FormatNum = (value) => (parseInt(value.toString().replace(/[^\d]+/gi, '')) || '').toLocaleString('en-US');

export const MaxInput = (e, maxVal) => {
  e.target.value
    && (e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxVal))
}

export const JsonToExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName + " (" + new Date().toLocaleDateString() + ")"}.xlsx`);
}

export const RenameKey = (obj, oldKey, newKey) => {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}

export const IsEmptyObj = (obj) => (obj !== undefined && obj !== null) && Object.keys(obj).length === 0 ? true : false;

export const FirstCharUppercase = (word) => word !== undefined && (word.charAt(0).toUpperCase() + word.slice(1));

export const RemoveNullPropFromObj = (obj) => !IsEmptyObj(obj) && Object.fromEntries(Object.keys(obj).filter(k => obj[k] !== null).map(k => [k, obj[k]]));
export const RemoveEmptyPropFromObj = (obj) => !IsEmptyObj(obj) && Object.fromEntries(Object.keys(obj).filter(k => obj[k] !== "").map(k => [k, obj[k]]));

export const GlobalVar = () => {
  const mdWidth = 1200;
  const mdHeight = 0;
  return {
    mdWidth,
    mdHeight
  }
}
