import { Collapse, ListItemButton, ListItemText } from '@mui/material';
import React, { useState } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function ListSubmenu(props) {
  const [open, setOpen] = useState(false);
  const { title, children } = props;
  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemText
          primary={title}
          sx={{
            textTransform: 'uppercase'
          }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          textTransform: 'uppercase',
          ml: 2,
          mb: 1,
        }}>
        {children}
      </Collapse>
    </>
  )
}
