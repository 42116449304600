import { Button, Grid, Grow } from '@mui/material'
import React, { useContext } from 'react'
import { Form } from '../../../layout';
import { Input, Select, InputPass } from '../../../controls';
import { DropLi, Model, UseForm } from '../../../hooks';
import { connect } from 'react-redux';
import * as actions from '../../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';
import { LoaderContext } from '../../../utils/Context';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
  const { handleShow, setActiveStep, _fetchByObj, isAdmin } = props;
  const { LoginMdl } = Model();
  const { acctType } = DropLi();
  const { setShowLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    toFormData,
  } = UseForm(LoginMdl);
  const validateForm = () => {
    let temp = {};
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    if(!isAdmin) temp.acctType = values.acctType !== "" ? "" : "Account type is required."
    temp.password = values.password !== "" ? "" : "Password is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      if (isAdmin) {
        const onComplete = (res, err) => {
          setShowLoader(false);
          if (res !== undefined && res.status === 403) {
            setErrors({
              ...errors,
              [res.error.key]: res.error.msg
            })
          }
        }
        _fetchByObj(ENDPOINTS.Admin + CNTRL_ACTN.Login, toFormData(values), onComplete, actions.ACTION_TYPES.ADMIN)
      } else {
        const onComplete = (res, err) => {
          setShowLoader(false);
          if (res !== undefined && res.status === 200) {
            handleShow();
            navigate(`/dashboard/${res.type}`);
          } else if (res !== undefined && res.status === 403) {
            setErrors({
              ...errors,
              [res.error.key]: res.error.msg
            })
          }
        }
        _fetchByObj(ENDPOINTS.Auth + CNTRL_ACTN.Login, toFormData(values), onComplete, actions.ACTION_TYPES.LOGIN)
      }
    }
  }


  return (
    <Grow in={true} timeout={800}>
      <Grid>
        <Form noValidate onSubmit={submitForm}>
          <Grid
            container
            className='p-15 pt-0'
            maxWidth={400}
            spacing={3}>
            <Grid item xs={12} className='text-center color-secondary'>
              <Grid className='pos-relative mb-30'>
                <span className='font-bold font-30 bottom-border text-uppercase'>
                  Login
                </span>
              </Grid>
            </Grid>
            {!isAdmin && <Grid item xs={12}>
              <Select
                label='Account Type'
                name='acctType'
                className='input-primary w-100pr'
                value={values.acctType}
                onChange={handleInputChange}
                error={errors.acctType}
                options={acctType}
              />
            </Grid>}
            <Grid item xs={12}>
              <Input
                label="Email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12}>
              <InputPass
                label="Password"
                name="password"
                value={values.password}
                onChange={handleInputChange}
                error={errors.password}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12} className='text-center'>
              <Button
                type='submit'
                className='btn-primary text-trans-none w-100pr font-18 p-10'>
                Login
              </Button>
              <Button
                onClick={() => setActiveStep(1)}
                className='font-18 text-trans-none mt-10 color-secondary hov-primary'>
                Forgot Your Password?
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grow>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response
})

const mapActionToProps = {
  _create: actions.create,
  _fetchByObj: actions.fetchByObj,
}

export default connect(mapStateToProps, mapActionToProps)(Login);
