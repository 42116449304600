import { Box, Container, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import * as actions from '../../actions/ieb';
import { PageTitle } from '../../hooks/Methods';
import { LoaderContext } from '../../utils/Context';

const PageByType = (props) => {
  const {
    _fetchById,
    _data,
    pageType = 0,
    cls } = props;
  const { setShowLoader } = useContext(LoaderContext);
  const [pgTitle, setPgTitle] = useState('');

  PageTitle(pgTitle);

  useEffect(() => {
    if (pageType) {
      setShowLoader(true);
      const onComplete = (res, err) => setShowLoader(false);
      _fetchById(ENDPOINTS.Admin + CNTRL_ACTN.GetPageByType,
        pageType,
        onComplete,
        actions.ACTION_TYPES.CREATE);
    }
    switch (parseInt(pageType)) {
      case 2:
        setPgTitle('News');
        break;
      case 3:
        setPgTitle('Events');
        break;
      case 4:
        setPgTitle('Notices');
        break;
      case 5:
        setPgTitle('Research');
        break;
      default:
        setPgTitle('Post');
        break;
    }
    // BackToTop
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    return () => {
      setPgTitle('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth='xl' className='p-0'>
      {_data.result &&
        <Box className={'m-15 bg-white border-radious-10 ' + cls}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div dangerouslySetInnerHTML={{ __html: _data.result.description }} 
              className='table-border' />
            </Grid>
          </Grid>
        </Box>
      }
    </Container>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
})
const mapActionToProps = {
  _fetchById: actions.fetchAllById,
}
export default connect(mapStateToProps, mapActionToProps)(PageByType);

