import { Button, Container, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { BackToTop, PageTitle } from '../../hooks/Methods';
import DownloadIcon from '@mui/icons-material/Download';
import { DropLi } from '../../hooks';
import { ShareBtn } from '../../controls';

const ViewCircular = ({ circ }) => {

  const { departments,
    educations,
    experiences,
    emplStatus,
    employerOrigins } = DropLi();

  PageTitle("View Circular");
  BackToTop();

  return (
    <Container>
      <Grid container spacing={2} className='mt-100 mb-80'>
        {/* Page Title */}
        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            className='font-18 color-secondary border-left'>Job Corner {'>'} Circular {'>'} View Circular</Typography>
        </Grid>
        {/* View data  */}
        <Grid item xs={12}>
          <Paper className='p-20'>
            <Grid container direction='row' className='color-gray' spacing={2}>
              <Grid item md={3} xs={12}
                className='text-center'>
                <Tooltip title={circ.employer.companyName} followCursor>
                  <img
                    src={circ.employer.logo !== null ? circ.employer.logo : process.env.PUBLIC_URL + "/img/other/no-logo.png"}
                    alt='ABCD'
                    className='img-fluid border-radious-5 h-180 object-cover' />
                </Tooltip>
                <Typography className='font-10 color-gray'>
                  Company Logo
                </Typography>
              </Grid>
              <Grid item md={9} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant='h6'
                      className='color-secondary font-bold hover-underline'>
                      {circ.jobTitle}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      className='color-dark font-bold'>
                      {circ.employer.companyName}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      className='color-dark font-bold'>
                      {educations.find(j => j.id === circ.education).name + " in " + departments.find(j => j.id === circ.department).name} Engineering
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={5} xs={12} sx={{ borderRight: { md: '1px solid rgb(0 0 0 / 12%)' } }}>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Concern Person: </span> {circ.employer.fullName}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Email:</span> {circ.email}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Salary:</span> ৳ {circ.salary}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Experience:</span> {circ.expDuration} Year
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Vacancy:</span> {circ.vacancy}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Field of Experience:</span> {experiences.find(j => j.id === circ.fieldOfExp).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Employment Status:</span> {emplStatus.find(j => j.id === circ.emplStatus).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Origin:</span> {employerOrigins.find(j => j.id === circ.origin).name}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='h6' className='font-16 font-bold'>
                  Special Criteria:
                </Typography>
                <Typography variant='subtitle1' className='pre-wrap'>
                  {circ.specialCriteria}
                </Typography>
                <br />
                <Typography
                  variant='h6' className='font-16 font-bold'>
                  Job Context:
                </Typography>
                <Typography variant='subtitle1' className='pre-wrap'>
                  {circ.jobContext}
                </Typography>

                <br />
                <Typography
                  variant='h6' className='font-16 font-bold'>
                  Job Responsibilites:
                </Typography>
                <Typography variant='subtitle1' className='pre-wrap'>
                  {circ.jobResp}
                </Typography>
                <br />
                <Typography
                  variant='h6' className='font-16 font-bold'>
                  Additional Requirements:
                </Typography>
                <Typography variant='subtitle1' className='pre-wrap'>
                  {circ.additionalReq}
                </Typography>
                <br />
                <Typography
                  variant='h6' className='font-16 font-bold'>
                  Compensation & Other Benefits:
                </Typography>
                <Typography variant='subtitle1' className='pre-wrap'>
                  {circ.otherBenifits}
                </Typography>
                <br />
                <Typography
                  variant='h6' className='font-16 font-bold'>
                  Job Location:
                </Typography>
                <Typography variant='subtitle1' className='font-16'>
                  {circ.jobLocation}
                </Typography>
              </Grid>

              <Grid item xs={12} className='text-right mt-10'>
                {circ.attachCircular && <Button
                  className='btn-primary border-radious-25 pr-10 pl-10'
                  size='small'
                  endIcon={<DownloadIcon />}>
                  <a href={circ.attachCircular} target="_blank" rel="noreferrer">Download Circular</a>
                </Button>}
                <ShareBtn />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ViewCircular