import React from 'react';
import {
  Paper,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import { Checkbox, Slider } from '../../controls';
import { DropLi } from '../../hooks';

export default function Sorting(props) {
  const {
    departments,
    experiences,
    interests,
    educations,
    aplOrigins } = DropLi();
  const {
    expVal,
    setExpVal,
    dept,
    setDept,
    edu,
    setEdu,
    fieldOfExp,
    setFieldOfExp,
    orgn,
    setOrgn,
    inte,
    setInte,
    origins = aplOrigins,
    orgnTitle
  } = props;

  const handleCheck = (e, value, setValue) => {
    e.target.checked ? setValue([...value, ...e.target.value]) : setValue([...value.filter(j => j !== e.target.value)])
  }

  return (
    <>
      {/* Year of Experience */}
      {expVal && <Paper className='mb-10'>
        <Grid className='pt-10 pr-10 pl-10'>
          <Typography
            variant='subtitle1'
            className='font-bold color-gray'>
            Years of Experience
          </Typography>
        </Grid>
        <Grid className='pt-10 pr-10 pl-10'>
        </Grid>
        <Divider className='mb-10' />
        <Grid className='p-20'>
          <Slider
            value={expVal}
            onChange={e => setExpVal(e.target.value)}
            marks={[{ value: 1, label: expVal[0] }, { value: 40, label: expVal[1] }]}
            min={1}
            max={40} />
        </Grid>
      </Paper>}
      {/* Education */}
      {edu && <Paper className='mb-10'>
        <Grid className='p-10'>
          <Typography
            variant='subtitle1'
            className='font-bold color-gray'>
            Education
          </Typography>
        </Grid>
        <Divider />
        <Grid className='p-15'>
          {educations.map((item, i) => (
            <Checkbox onChange={e => handleCheck(e, edu, setEdu)} key={i} label={item.name} value={item.id} />
          ))}
        </Grid>
      </Paper>}
      {/* Department */}
      {dept && <Paper className='mb-10'>
        <Grid className='p-10'>
          <Typography
            variant='subtitle1'
            className='font-bold color-gray'>
            Department
          </Typography>
        </Grid>
        <Divider />
        <Grid className='p-15'>
          {departments.map((item, i) => (
            <Checkbox onChange={e => handleCheck(e, dept, setDept)} key={i} label={item.name} value={item.id} />
          ))}
        </Grid>
      </Paper>}
      {/* Origin */}
      {orgn && <Paper className='mb-10'>
        <Grid className='p-10'>
          <Typography
            variant='subtitle1'
            className='font-bold color-gray'>
            {orgnTitle ? orgnTitle : 'Origin'}
          </Typography>
        </Grid>
        <Divider />
        <Grid className='p-15'>
          {origins.map((item, i) => (
            <Checkbox onChange={e => handleCheck(e, orgn, setOrgn)} key={i} label={item.name} value={item.id} />
          ))}
        </Grid>
      </Paper>}
      {/* Field of Experience */}
      {fieldOfExp && <Paper className='mb-10'>
        <Grid className='p-10'>
          <Typography
            variant='subtitle1'
            className='font-bold color-gray'>
            Field of Experience
          </Typography>
        </Grid>
        <Divider />
        <Grid className='p-15'>
          {experiences.map((item, i) => (
            <Checkbox onChange={e => handleCheck(e, fieldOfExp, setFieldOfExp)} key={i} label={item.name} value={item.id} />
          ))}
        </Grid>
      </Paper>}
      {/* Field of Interest */}
      {inte && <Paper>
        <Grid className='p-10'>
          <Typography
            variant='subtitle1'
            className='font-bold color-gray'>
            Field of Interest
          </Typography>
        </Grid>
        <Divider />
        <Grid className='p-15'>
          {interests.map((item, i) => (
            <Checkbox onChange={e => handleCheck(e, inte, setInte)} key={i} label={item.name} value={item.id} />
          ))}
        </Grid>
      </Paper>}
    </>
  )
}
