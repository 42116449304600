import { Button, Grid, Grow, Typography } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { Alert, Form } from '../../../../layout';
import { InputPass } from '../../../../controls';
import { Model, UseForm } from '../../../../hooks';
import { PassValidation } from '../../../../utils';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { LoaderContext } from '../../../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import { useNavigate } from 'react-router-dom';

const ChangePass = (props) => {
  const { setActiveStep, setForgotVal, forgotVal, _fetchByObj, isAdmin } = props;
  const { setShowLoader } = useContext(LoaderContext);
  const { ForgotMdl } = Model();
  const navigate = useNavigate();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputPass,
    passValidation,
    toFormData,
  } = UseForm(ForgotMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });
  const [err, setErr] = useState();

  const validateForm = () => {
    let temp = {};
    temp.password = values.password !== "" ? !passValidation.passValid ? " " : "" : "Password is required."
    temp.confirmPass = values.confirmPass !== "" ? !passValidation.match ? "Password and Confirm password not match." : "" : "Confirm Password is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      values.forgotStep = 3;
      if (isAdmin) {
        const onComplete = (res, err) => {
          setShowLoader(false);
          if (res !== undefined && res.status === 400) setErr(res.msg);
        }
        _fetchByObj(ENDPOINTS.Admin + CNTRL_ACTN.ForgotPass, toFormData(values), onComplete, actions.ACTION_TYPES.ADMIN)
      } else {
        const onComplete = (res, err) => {
          setShowLoader(false);
          if (res !== undefined && res.status === 200) navigate(`/dashboard/${res.type}`)
          else if (res !== undefined && res.status === 400) setErr(res.msg);
        }
        _fetchByObj(ENDPOINTS.Auth + CNTRL_ACTN.ForgotPass, toFormData(values), onComplete, actions.ACTION_TYPES.LOGIN)
      }
    }
  }

  useEffect(() => {
    setValues(forgotVal);
    return () => {
      setValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setForgotVal({ ...values })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <>
      <Alert
        onClick={() => setActiveStep(undefined)}
        alert={alert}
        setAlert={setAlert} />
      <Grow in={true} timeout={800}>
        <Grid>
          <Form noValidate onSubmit={submitForm}>
            <Grid
              container
              className='p-15 pt-0'
              maxWidth={400}
              spacing={3}>
              <Grid item xs={12} className='text-center color-secondary'>
                <Grid className='pos-relative mb-30'>
                  <span className='font-bold font-26 bottom-border text-uppercase'>
                    Change Password
                  </span>
                </Grid>
              </Grid>
              {err && <Grid item xs={12} className='d-flex color-danger'>
                <Typography variant='subtitle1' className='font-bold'>Error:&nbsp;</Typography>
                <Typography variant='subtitle1'>{err}</Typography>
              </Grid>}
              <Grid item xs={12}>
                <InputPass
                  label="New Password"
                  name="password"
                  value={values.password}
                  onChange={handleInputPass}
                  error={errors.password}
                  className='input-primary w-100pr'
                />
                <PassValidation {...{ password: values.password, passValidation }} />
              </Grid>
              <Grid item xs={12}>
                <InputPass
                  label="Confirm Password"
                  name="confirmPass"
                  value={values.confirmPass}
                  onChange={e => handleInputPass(e, true)}
                  error={(
                    errors.confirmPass !== "" &&
                    values.password !== "" &&
                    !passValidation.match &&
                    values.confirmPass !== "")
                    ? "Password and Confirm password not match"
                    : errors.confirmPass}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} className='text-center'>
                <Button
                  type='submit'
                  className='btn-primary text-trans-none w-100pr font-18 p-10'>
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grow>
    </>
  )
}

const mapStateToProps = state => ({
})
const mapActionToProps = {
  _fetchByObj: actions.fetchByObj,
}
export default connect(mapStateToProps, mapActionToProps)(ChangePass);
