import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Typography,
  InputAdornment,
} from '@mui/material';
import { Input, InputPass, Select, DatePicker, InputRich } from '../../controls';
import InputImg from '../../controls/InputImg';
import { DropLi, UseForm, Model } from '../../hooks';
import { Alert, Form, } from '../../layout';
import { PassValidation } from '../../utils';
import { IsEmptyObj, MaxInput, RemoveEmptyPropFromObj } from '../../hooks/Methods';
import { connect } from 'react-redux';
import * as actions from '../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import { LoaderContext } from '../../utils/Context';
import { useNavigate } from 'react-router-dom';

const MemberAcc = (props) => {
  const { setShowForm, isUpdate = false, _check, _create, _authUser, _update } = props;
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });
  const { MemberMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const {
    fellowMemberNo,
    departments,
    bloods,
    educationals,
    experiences } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputPass,
    passValidation,
    resetFormControls,
    handleInputDate,
    handleImg,
    toFormData
  } = UseForm(MemberMdl);

  const validateForm = () => {
    let temp = {};
    temp.photoFile = values.photoFile !== "" ? "" : "Photo is required."
    temp.fullName = values.fullName !== "" ? "" : "Full name is required."
    temp.mobile = values.mobile !== "" ? "" : "Mobile is required."
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    temp.educational = values.educational !== "" ? "" : "Educational is required."
    temp.department = values.department !== "" ? "" : "Department is required."
    temp.expDuration = values.expDuration !== "" ? "" : "Experience duration is required."
    temp.fieldOfExp = values.fieldOfExp !== "" ? "" : "Field of experience year is required."
    temp.bloodGroup = values.bloodGroup !== "" ? "" : "Blood group is required."
    temp.fellowMemberNo = values.fellowMemberNo !== "" ? "" : "Fellowship/Membership no. is required."
    temp.fellowMemberNoType = values.fellowMemberNoType !== "" ? "" : "No. Type is required."
    if (!isUpdate) {
      temp.password = values.password !== "" ? !passValidation.passValid ? " " : "" : "Password is required."
      temp.confirmPass = values.confirmPass !== "" ? !passValidation.match ? "Password and Confirm password not match." : "" : "Confirm Password is required."
    }

    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      // Create Action
      if (!isUpdate) {
        const onComplete = (res) => {
          setShowLoader(false);
          if (res.status === 200) {
            navigate("/dashboard/member")
          } else if (res.status === 409) {
            setErrors({
              ...errors,
              email: res.msg
            })
          }
        }
        _create(ENDPOINTS.Member + CNTRL_ACTN.Create, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.LOGIN);
      }// Update Action
      else if (isUpdate) {
        const onComplete = (res) => {
          setShowLoader(false);
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: 'success',
              title: 'Awesome!',
              subTitle: 'You hove successfully update your profile.',
            });
          } else if (res.status === 409 && res.msg) {
            setErrors({
              errors,
              'email': res.msg
            })
          } else {
            setAlert({
              isOpen: true,
              type: 'error',
              title: res.status,
              subTitle: res.msg && res.msg,
            })
          }
        }
        _update(ENDPOINTS.Member + CNTRL_ACTN.Update, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.LOGIN)
      }
    }
  }
  const closeForm = () => {
    setShowForm(false);
    resetFormControls();
  }

  const isEmailExist = (value) => {
    if (value !== '') {
      const onComplete = (res) => res.status === 409 && setErrors({ ...errors, 'email': res.msg });
      _check(ENDPOINTS.Member + CNTRL_ACTN.IsEmailExist, value, onComplete);
    }
  }

  useEffect(() => {
    if (isUpdate) {
      (_authUser.result !== undefined && !IsEmptyObj(_authUser.result)) && setValues({
        ...values,
        ..._authUser.result
      })
    }
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser])

  return (
    <>
      <Alert alert={alert} setAlert={setAlert} />
      <Form noValidate
        onSubmit={submitForm}>
        <Grid container className='pl-20 pr-20 pt-30 pb-30' spacing={3}>
          <Grid item xs={12} className='text-center mb-30 pos-relative'>
            <Typography variant='p' className='bottom-border color-dark font-30'>
              {isUpdate ? 'Edit Your Profile' : 'Member Registration Form'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <InputImg
              name='photoFile'
              title='Attach PP Size Img.'
              src={values.photo}
              rmv={() => { setValues({ ...values, 'photoFile': '', 'photo': '' }) }}
              value={values.photoFile}
              onChange={handleImg}
              error={errors.photoFile} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Full Name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleInputChange}
                  error={errors.fullName}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Mobile No."
                  name="mobile"
                  value={values.mobile}
                  onChange={handleInputChange}
                  error={errors.mobile}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  onBlur={e => !isUpdate && isEmailExist(e.target.value)}
                  error={errors.email}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label='Education'
                  name='educational'
                  className='input-primary w-100pr'
                  value={values.educational}
                  onChange={handleInputChange}
                  error={errors.educational}
                  options={educationals} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label='Department'
                  name='department'
                  className='input-primary w-100pr'
                  value={values.department}
                  onChange={handleInputChange}
                  error={errors.department}
                  options={departments} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Graduation Year"
                  name="gradYear"
                  views={['year']}
                  minDate={new Date('1950')}
                  maxDate={new Date()}
                  value={values.gradYear}
                  onChange={date => handleInputDate('gradYear', date !== null ? date.getFullYear() : '')}
                  error={errors.gradYear} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Experience Duration"
                  name="expDuration"
                  type="number"
                  value={values.expDuration}
                  onChange={handleInputChange}
                  error={errors.expDuration}
                  onInput={e => MaxInput(e, 2)}
                  className='input-primary w-100pr'
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Year</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label='Field of Experience'
                  name='fieldOfExp'
                  className='input-primary w-100pr'
                  value={values.fieldOfExp}
                  onChange={handleInputChange}
                  error={errors.fieldOfExp}
                  options={experiences} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label='Blood Group'
                  name='bloodGroup'
                  className='input-primary w-100pr'
                  value={values.bloodGroup}
                  onChange={handleInputChange}
                  error={errors.bloodGroup}
                  options={bloods} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Home District (Optional)"
              name="homeDist"
              value={values.homeDist}
              onChange={handleInputChange}
              error={errors.homeDist}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={6} container spacing={1}>
            <Grid item xs={8}>
              <Input
                label="Fellowship/Membership No."
                name="fellowMemberNo"
                type='number'
                value={values.fellowMemberNo}
                onChange={handleInputChange}
                error={errors.fellowMemberNo}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                label='No. Type'
                name='fellowMemberNoType'
                className='input-primary w-100pr'
                value={values.fellowMemberNoType}
                onChange={handleInputChange}
                error={errors.fellowMemberNoType}
                options={fellowMemberNo} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Present/Last Organization"
              name="presLastOrg"
              value={values.presLastOrg}
              onChange={handleInputChange}
              error={errors.presLastOrg}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Present/Last Designation"
              name="presLastDes"
              value={values.presLastDes}
              onChange={handleInputChange}
              error={errors.presLastDes}
              className='input-primary w-100pr'
            />
          </Grid>
          {!isUpdate ? <>
            <Grid item xs={12} md={6}>
              <InputPass
                label="Password"
                name="password"
                value={values.password}
                onChange={handleInputPass}
                error={errors.password}
                className='input-primary w-100pr'
              />
              <PassValidation {...{ password: values.password, passValidation }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputPass
                label="Confirm Password"
                name="confirmPass"
                value={values.confirmPass}
                onChange={e => handleInputPass(e, true)}
                error={(
                  errors.confirmPass !== "" &&
                  values.password !== "" &&
                  !passValidation.match &&
                  values.confirmPass !== "")
                  ? "Password and Confirm password not match"
                  : errors.confirmPass}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12}>
              <InputRich
                label='Details (Optional): '
                type="simple"
                value={values.details}
                onBlur={(val) => setValues({ ...values, details: val })} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                type='submit'
                variant='contained'
                className='btn-primary w-100pr font-20'
                size='large'>
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                onClick={closeForm}
                variant='contained'
                className='btn w-100pr font-20'
                size='large'>
                close
              </Button>
            </Grid>
          </> :
            <>
              <Grid item xs={12}>
                <InputRich
                  label='Details (Optional): '
                  type="simple"
                  value={values.details}
                  onBlur={(val) => setValues({ ...values, details: val })} />
              </Grid>
              <Grid item xs={12} className='text-right'>
                <Button
                  type='submit'
                  variant='contained'
                  className='btn-primary font-20 min-w-150'
                  size='large'>
                  Save Changes
                </Button>
              </Grid>
            </>
          }
        </Grid>
      </Form>
    </>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _create: actions.create,
  _check: actions.check,
  _update: actions.update
}

export default connect(mapStateToProps, mapActionToProps)(MemberAcc);
