import React from 'react'
import {
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
  FormHelperText
} from '@mui/material'

export default function Checkbox(props) {
  const {
    label,
    checked,
    name,
    onChange,
    size = 'medium',
    error = null,
    variant = 'checkbox-primary',
    className,
    ...other
  } = props;
  return (
    <FormGroup>
      <FormControlLabel
        onChange={onChange}
        className={variant + ' text-select-disable ' + className}
        name={name}
        control={<MuiCheckbox size={size} checked={checked} className='p-5' />}
        label={label}
        {...other} />
      {error && <FormHelperText className='color-danger'>{error}</FormHelperText>}
    </FormGroup>
  )
}
