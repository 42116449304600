import { Button, Container, Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { connect } from 'react-redux';

const Footer = ({ _home }) => {
  const [data, setData] = useState();
  useEffect(() => {
    (_home.result && _home.result.site) && setData(_home.result.site)
    return () => {
    }
  }, [_home])

  return (
    <>
      {data &&
        <Box className='bg-secondary pos-relative'>
          {/* footer background image */}
          <img
            alt="footer-bg-logo"
            className='pos-absolute left-0 right-0 top-0 bottom-0 m-auto opacity-10 h-200'
            src={data.footerLogo} />
          <Container maxWidth='xl' className='min-h-300 pt-20 pb-20'>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4} container spacing={2}>
                <Grid item xs={12}>
                  <img
                    alt="footer-logo"
                    className='w-70'
                    src={data.footerLogo} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='p' className='p'>
                    <span className='font-bold'>Address: </span> {data.address}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='p' className='font-18'>
                    <span className='font-bold pr-10'>Phone: </span>
                    <a className='color-white' href={`tel:${data.phone}`}>{data.phone}</a>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='p' className='font-18'>
                    <span className='font-bold pr-10'>Fax: </span> {data.fax}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='p' className='font-18'>
                    <span className='font-bold pr-10'>Email: </span>
                    <a className='color-white' href={`mailto:${data.email}`}>{data.email}</a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} container >
                <Grid item xs={12}>
                  <Typography variant='h5'>
                    {data.title}
                  </Typography>
                  <Divider className='bg-primary mt-10 mb-10' />
                  <p className='p pre-wrap' align='justify'>
                    {data.shortDes}
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} container spacing={2} >
                <Grid item xs={12}>
                  <Typography variant='h5'>
                    Social media
                  </Typography>
                  <Divider className='bg-primary mt-10 mb-30' />
                </Grid>
                <Grid item xs={12}>
                  <a href={data.facebook} target="_blank" rel="noreferrer">
                    <Button
                      startIcon={<FacebookOutlinedIcon />}
                      className='bg-blue pl-50 pr-50'>
                      Facebook
                    </Button>
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <a href={data.linkIn} target="_blank" rel="noreferrer">
                    <Button
                      startIcon={<LinkedInIcon />}
                      className='bg-blue pl-50 pr-50'>
                      LinkedIn
                    </Button>
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <a href={data.instagram} target="_blank" rel="noreferrer">
                    <Button
                      startIcon={<InstagramIcon />}
                      className='bg-orange pl-50 pr-50'>
                      Instagram
                    </Button>
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <a href={data.twitter} target="_blank" rel="noreferrer">
                    <Button
                      startIcon={<TwitterIcon />}
                      className='bg-blue pl-50 pr-50'>
                      Twitter
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>}
      <Box className="bg-secondary-light">
        <Container maxWidth='xl'>
          <Grid container>
            <Grid item xs={12}>
              <p className='font-18'>© 2022 IEB-Alumni. &nbsp;All Rights Reserved. Design, Development and Maintenance by &nbsp;&nbsp;
                <a href='https://jhprog.com/' target="blank" className='color-success font-bold'>JHProg</a>
                &nbsp; || <a href='https://encodershub.org/' target="blank" className='color-success font-bold'>EncodersHub</a>
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

const mapStateToProps = state => ({
  _home: state.ieb.homeData
})

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(Footer);