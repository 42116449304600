import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs as MuiTabs,
  Box,
  Tab,
  Paper
} from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='pt-10'>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Tabs({ items = [] }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    items.length > 0 ?
      <Paper
        className='bg-white border-radious-10 w-100pr'>
        <Box
          className='fon'
          sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <MuiTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons='auto'
            allowScrollButtonsMobile>
            {items.map((item, i) =>
              <Tab
                key={i}
                className='font-bold font-14'
                label={item.label}
                {...a11yProps(i)} />
            )}
          </MuiTabs>
        </Box>
        {items.map((item, i) =>
          <TabPanel key={i} value={value} index={i}>
            {item.component}
          </TabPanel>
        )}
      </Paper> : <></>
  );
}

