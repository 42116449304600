import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddIcon from '@mui/icons-material/Add';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Grid,
  Button,
  Paper,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Input } from '../controls';


export default function Table(props) {
  const {
    tblHead = [],
    tblData = [],
    showAddBtn = true,
    showDownloadBtn = true,
    addBtnTitle = 'Add Item',
    showEdit = true,
    showDelete = true,
    handleAddEdit,
    handleDlt,
    handleExcel
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [_tblData, _setTblData] = useState(tblData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSerach = (e) => {
    _setTblData([...tblData
      .filter(j => Object.keys(j).some(x => String(j[x])
        .toLowerCase()
        .includes(e.target.value.toLowerCase())))])
  }

  useEffect(() => {
    _setTblData(tblData)
    return () => {
    }
  }, [tblData])
  

  return (
    <>
      <Paper className='mb-10'>
        <Grid
          container
          className='p-10'>
          <Grid item xs={12} md={5} container spacing={1} className='d-flex justify-start'>
            {showAddBtn && <Grid item xs={12} md={6}>
              <Button
                onClick={() => handleAddEdit && handleAddEdit('add')}
                variant='outlined'
                className='btn-secondary-outlined w-100pr'
                startIcon={<AddIcon />}>
                {addBtnTitle}
              </Button>
            </Grid>}
            {showDownloadBtn && <Grid item xs={12} md={6}>
              <Button
                onClick={() => handleExcel(_tblData)}
                variant='outlined'
                className='btn-secondary-outlined w-100pr'
                startIcon={<DownloadIcon />}>
                Download To Excel
              </Button>
            </Grid>}
          </Grid>

          <Grid item xs={12} md={7} sx={{ mt: { xs: '10px', md: '0px' }, textAlign: 'right' }}>
            <Input
              label="Search"
              name="search"
              className="input-secondary"
              sx={{ width: { xs: '100%', md: '250px' } }}
              size='small'
              onChange={handleSerach}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton size="small" className='hov-secondary-text'>
                    <SearchRoundedIcon />
                  </IconButton>
                </InputAdornment>
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 184px)' }}>
          <MuiTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tblHead.map((item, i) => (
                  <TableCell key={i} className='bg-secondary-trans font-bold font-16 min-w-200'>{item.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_tblData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                return (
                  <TableRow hover key={i}>
                    {tblHead.map((headItem, index) => {
                      return (
                        <TableCell key={index} className='p-8 pl-15 font-16'>
                          {headItem.id === 'actions'
                            ? <ActionsComp
                              {...{
                                data: item,
                                showEdit,
                                showDelete,
                                handleEdit: handleAddEdit,
                                handleDlt,
                              }} />
                            : item[headItem.id]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
        {_tblData.length > 0 && <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={_tblData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      </Paper>
    </>
  );
}

const ActionsComp = (props) => {
  const {
    data,
    showEdit,
    showDelete,
    handleEdit,
    handleDlt } = props;
  return (
    <Grid className='d-flex'>
      {showEdit &&
        <IconButton
          onClick={() => handleEdit && handleEdit(data)}
          size='small'
          className='btn-secondary-trans m-2'> <EditIcon className='font-18 m-2' /></IconButton>}
      {showDelete &&
        <IconButton
          onClick={() => handleDlt && handleDlt(data)}
          size='small'
          className='btn-danger-trans m-2'><ClearIcon className='font-18 m-2' /></IconButton>}
    </Grid>
  )
}