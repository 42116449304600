import { Container, Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react'
import { BackToTop, PageTitle } from '../../hooks/Methods';
import { DropLi } from '../../hooks';
import { ShareBtn } from '../../controls';

const ViewStudentstudent = ({ student }) => {

  const { departments,
    bloods,
    experiences,
    parts,
    eduBgs } = DropLi();

  PageTitle("View Student Profile");
  BackToTop();

  return (
    <Container>
      <Grid container spacing={2} className='mt-100 mb-80'>
        {/* Page Title */}
        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            className='font-18 color-secondary border-left'>Member {'>'} Student {'>'} View Profile</Typography>
        </Grid>
        {/* View data  */}
        <Grid item xs={12}>
          <Paper className='p-20'>

            <Grid container direction='row' className='color-gray' spacing={2}>
              <Grid item md={3} xs={12} className='text-center d-flex justify-center align-center'>
                <img
                  src={student.photo}
                  className='img-fluid border-radious-5 h-220 object-cover'
                  alt='ABCD' />
              </Grid>
              <Grid item md={9} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant='h6'
                      className='color-secondary font-bold'>
                      {student.fullName}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      className='color-dark font-bold'>
                      {"Department of " + departments.find(j => j.id === student.department).name + " Engineering"}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={6} xs={12} className='color-gray' sx={{ borderRight: { md: '1px solid rgb(0 0 0 / 12%)' } }}>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Contact No:</span> <a href={'tel:' + student.mobile}>{student.mobile}</a>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Email:</span> <a href={'mailto:' + student.email}>{student.email}</a>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Part:</span> {parts.find(j => j.id === student.part).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Present Designation:</span> {student.presentDes}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Field of Expertise:</span> {experiences.find(j => j.id === student.fieldOfExp).name}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={6} xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Blood Group:</span> {bloods.find(j => j.id === student.bloodGroup).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>S/{student.stuNo}</span>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Present Organization:</span> {student.presentOrg}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Educational Background:</span> {eduBgs.find(j => j.id === student.eduBg).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Experience: </span> {student.expDuration} Year
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Home District:</span> {student.presentOrg}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: student.details }}
                  className='table-border' />
              </Grid>
              <Grid item xs={12} className='text-right'>
                <ShareBtn />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ViewStudentstudent