import { Button, Grid, Grow, Typography } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { Form, Notify } from '../../../../layout'
import { Input } from '../../../../controls';
import { Model, UseForm } from '../../../../hooks';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { LoaderContext } from '../../../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';


const SendFrgtCode = (props) => {
  const { setActiveStep, forgotVal, setForgotVal, _fetchByObj, isAdmin } = props;
  const [notify, setNotify] = useState();
  const [openNotify, setOpenNotify] = useState();
  const { setShowLoader } = useContext(LoaderContext);

  const { ForgotMdl } = Model();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    toFormData
  } = UseForm(ForgotMdl);
  const validateForm = () => {
    let temp = {};
    temp.verificationCode = values.verificationCode !== "" ? "" : "Verification code is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res, err) => {
        setShowLoader(false);
        if (res !== undefined && res.status === 200) {
          setActiveStep(3);
        } else if (res !== undefined && res.status === 204) {
          setErrors({
            ...errors,
            verificationCode: res.msg
          })
        }
      }
      values.forgotStep = 2;
      _fetchByObj((isAdmin ? ENDPOINTS.Admin : ENDPOINTS.Auth) + CNTRL_ACTN.ForgotPass, toFormData(values), onComplete, actions.ACTION_TYPES.CREATE)
    }
  }

  const resendCode = () => {
    setShowLoader(true);
    const onComplete = (res, err) => {
      setShowLoader(false);
      if (res !== undefined && res.status === 200) {
        setNotify({
          msg: 'Successfully resend verification code to your email'
        })
        setOpenNotify(!openNotify);
      }
    }
    values.forgotStep = 1;
    _fetchByObj((isAdmin ? ENDPOINTS.Admin : ENDPOINTS.Auth) + CNTRL_ACTN.ForgotPass, toFormData(values), onComplete, actions.ACTION_TYPES.CREATE)
  }

  useEffect(() => {
    setValues(forgotVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setForgotVal({ ...values })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <>
      <Notify
        notify={notify}
        open={openNotify}
        setOpen={setOpenNotify} />
      <Grow in={true} timeout={800}>
        <Grid>
          <Form noValidate onSubmit={submitForm}>
            <Grid
              container
              className='p-15 pt-0'
              maxWidth={400}
              spacing={3}>
              <Grid item xs={12} className='text-center color-secondary'>
                <Grid className='pos-relative mb-30'>
                  <span className='font-bold font-26 bottom-border text-uppercase'>
                    Verification Code
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12} className='mb-20'>
                <Typography variant='p' className='font-18 color-secondary'>
                  <strong>IEB-Alumni</strong> Sent 6 digit verification code to this email <strong>{forgotVal.email}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Input
                  type='number'
                  label="Enter Verification Code"
                  name="verificationCode"
                  value={values.verificationCode}
                  onChange={handleInputChange}
                  error={errors.verificationCode}
                  className='input-primary w-100pr'
                />
                <Button
                  onClick={() => resendCode()}
                  className='font-18 color-secondary hov-primary text-trans-none'>
                  Resend Code
                </Button>
              </Grid>
              <Grid item xs={12} className='text-center'>
                <Button
                  type='submit'
                  className='btn-primary w-100pr font-18 p-10 text-trans-none'>
                  Submit
                </Button>
                <Button
                  onClick={() => setActiveStep(1)}
                  className='btn font-18 w-100pr mt-10 p-10 text-trans-none'>
                  Back
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grow>
    </>
  )
}

const mapStateToProps = state => ({
})

const mapActionToProps = {
  _fetchByObj: actions.fetchByObj,
}

export default connect(mapStateToProps, mapActionToProps)(SendFrgtCode);
