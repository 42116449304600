import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import * as actions from '../../../../actions/ieb';
import { DropLi, Model, UseForm } from '../../../../hooks';
import { PageTitle, RemoveEmptyPropFromObj } from '../../../../hooks/Methods';
import { Alert, Form } from '../../../../layout';
import { LoaderContext } from '../../../../utils/Context';
import { InputRich, Select } from '../../../../controls';


const AddEditSinglePg = (props) => {
  const {
    _create,
    _update,
    _fetchById } = props;
  const { PageMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext);
  const [pgName, setPgName] = useState("");
  const [isUpd, setIsUpd] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  PageTitle(isUpd ? `Update ${pgName} Page` : `Create ${pgName} Page`);
  const { pages } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    toFormData,
    handleInputChange,
  } = UseForm(PageMdl);

  const validateForm = () => {
    let temp = {};
    temp.pageType = values.pageType !== "" ? "" : "Page type is required."
    temp.description = values.description !== "" ? "" : "Description is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: 'success',
            title: 'Awesome!',
            subTitle: `You have successfully ${isUpd ? 'updated' : 'created'}.`
          })
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg
          })
        }
      }
      // Update Action
      if (isUpd) {
        _update(ENDPOINTS.Admin + CNTRL_ACTN.UpdatePage,
          toFormData(RemoveEmptyPropFromObj(values)), onComplete);
        // Create Action
      } else {
        _create(ENDPOINTS.Admin + CNTRL_ACTN.CreatePage,
          toFormData(RemoveEmptyPropFromObj(values)), onComplete);
      }
    }
  }

  useEffect(() => {
    if (values.pageType) {
      setShowLoader(true);
      const onComplete = (res, err) => {
        setShowLoader(false);
        if (res.status === 200 && res.result) {
          setIsUpd(true);
          setValues({ ...res.result });
        } else if (res.status === 204) {
          setIsUpd(false);
          setValues({
            ...values,
            id: '',
            title: '',
            coverPhoto: '',
            description: '',
            createDate: '',
            photoFile: ''
          })
        }
      };
      _fetchById(ENDPOINTS.Admin + CNTRL_ACTN.GetPageByType,
        values.pageType,
        onComplete,
        actions.ACTION_TYPES.CREATE);
    }

    switch (values.pageType) {
      case 1:
        setPgName('About Us');
        break;
      case 6:
        setPgName('Committe > Alumni');
        break;
      case 7:
        setPgName('Committe > Students');
        break;
      case 8:
        setPgName('Archive > Alumni');
        break;
      case 9:
        setPgName('Amie Course > Exam Doc');
        break;
      case 10:
        setPgName('Amie Course > Questions');
        break;
      case 11:
        setPgName('Amie Course > Books');
        break;
      case 12:
        setPgName('Amie Course > Hand Notes');
        break;
      default:
        break;
    }

    return () => {
      setIsUpd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.pageType])


  return (
    <>
      <Alert alert={alert} setAlert={setAlert} />
      <Container maxWidth='xl'>
        <Paper className='p-15 mt-10'>
          <Form noValidate
            onSubmit={submitForm}>
            <Grid container spacing={3}>
              <Grid item xs={12} container justifyContent='space-between' className='mb-30'>
                <Grid className='text-center pos-relative'>
                  <Typography
                    variant='p'
                    className='bottom-border color-dark'
                    sx={{ fontSize: { xs: '25px', md: '30px' } }}>
                    {isUpd ? `Update ${pgName} Page` : `Create ${pgName} Page`}
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    type='submit'
                    className='btn-primary'>
                    {isUpd ? 'Save Changes' : 'Publish'}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Select
                  label='Page Type'
                  className='input-primary w-100pr'
                  name='pageType'
                  value={values.pageType}
                  onChange={handleInputChange}
                  error={errors.pageType}
                  options={pages} />
              </Grid>
              <Grid item xs={12}>
                <InputRich
                  name='description'
                  value={values.description}
                  error={errors.description}
                  onBlur={val => {
                    setValues({ ...values, description: val });
                    values.description !== "" && setErrors({ ...errors, description: false });
                  }} />
                {errors.description !== "" &&
                  <Typography className='color-danger'>
                    {errors.description}
                  </Typography>}
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Container>
    </>
  )
}
const mapStateToProps = state => ({
  _data: state.ieb.data,
})
const mapActionToProps = {
  _fetchById: actions.fetchAllById,
  _create: actions.create,
  _update: actions.update
}
export default connect(mapStateToProps, mapActionToProps)(AddEditSinglePg);
