import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux';
import { Input, InputPass, Select } from '../../controls';
import { DropLi, UseForm, Model } from '../../hooks';
import { Form } from '../../layout';
import { PassValidation } from '../../utils';
import * as actions from '../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import { useNavigate } from 'react-router-dom';
import { LoaderContext } from '../../utils/Context';
import { RemoveEmptyPropFromObj } from '../../hooks/Methods';

const EmployeeAcc = (props) => {
  const { setShowForm, isUpdate, _create, _check, _response } = props;
  const { EmployeeMdl } = Model();
  const { aplOrigins } = DropLi();
  const navigate = useNavigate();
  const { setShowLoader } = useContext(LoaderContext)

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    handleInputPass,
    passValidation,
    resetFormControls,
    toFormData,
  } = UseForm(EmployeeMdl);

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : "Full name is required."
    temp.mobile = values.mobile !== "" ? "" : "Mobile is required."
    temp.aplOrigin = values.aplOrigin !== "" ? "" : "Applicant origin is required."
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? _response.status === 409 ? `${_response.msg}` : "" : "Email is not valid.") : "Email is required."
    temp.password = values.password !== "" ? !passValidation.passValid ? " " : "" : "Password is required."
    temp.confirmPass = values.confirmPass !== "" ? !passValidation.match ? "Password and Confirm password not match." : "" : "Confirm Password is required."

    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          navigate("/dashboard/employee")
        } else if (res.status === 409) {
          setErrors({
            ...errors,
            email: res.msg
          })
        }
      }
      _create(ENDPOINTS.Employee + CNTRL_ACTN.Create, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.LOGIN);
    }
  }

  const closeForm = () => {
    setShowForm(false);
    resetFormControls();
  }

  const isEmailExist = (value) => {
    value !== '' && _check(ENDPOINTS.Employee + CNTRL_ACTN.IsEmailExist, value);
  }

  useEffect(() => {
    (_response.status === 409 && _response.type === 'employee') && setErrors({
      ...errors,
      email: _response.msg
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_response])

  return (
    <Grid>
      <Form noValidate
        onSubmit={submitForm}>
        <Grid container spacing={3} className='pl-20 pr-20 pt-30 pb-30' >
          <Grid item xs={12} className='text-center mb-30 pos-relative'>
            <Typography variant='p' className='bottom-border color-dark font-30'>
              {isUpdate ? 'Update Job Seekers Profile' : 'Job Seekers Form'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Full Name"
              name="fullName"
              value={values.fullName}
              onChange={handleInputChange}
              error={errors.fullName}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Mobile No."
              name="mobile"
              value={values.mobile}
              onChange={handleInputChange}
              error={errors.mobile}
              className='input-primary w-100pr'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label='Applicant Origin'
              name='aplOrigin'
              className='input-primary w-100pr'
              value={values.aplOrigin}
              onChange={handleInputChange}
              error={errors.aplOrigin}
              options={aplOrigins} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Email Address"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              onBlur={e => isEmailExist(e.target.value)}
              error={errors.email}
              className='input-primary w-100pr'
            />
          </Grid>
          {!isUpdate && <>
            <Grid item xs={12} md={6}>
              <InputPass
                label="Password"
                name="password"
                value={values.password}
                onChange={handleInputPass}
                error={errors.password}
                className='input-primary w-100pr'
              />
              <PassValidation {...{ password: values.password, passValidation }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputPass
                label="Confirm Password"
                name="confirmPass"
                value={values.confirmPass}
                onChange={e => handleInputPass(e, true)}
                error={(
                  errors.confirmPass !== "" &&
                  values.password !== "" &&
                  !passValidation.match &&
                  values.confirmPass !== "")
                  ? "Password and Confirm password not match"
                  : errors.confirmPass}
                className='input-primary w-100pr'
              />
            </Grid>
          </>
          }
          <Grid item xs={12} md={!isUpdate ? 6 : 12} className='text-right'>
            <Button
              type='submit'
              variant='contained'
              className={(!isUpdate && 'w-100pr') + ' btn-primary font-20'}
              size='large'>
              {isUpdate ? 'Save Changes' : 'Submit'}
            </Button>
          </Grid>
          {!isUpdate && <Grid item xs={12} md={6}>
            <Button
              onClick={closeForm}
              variant='contained'
              className='btn w-100pr font-20'
              size='large'>
              close
            </Button>
          </Grid>}
        </Grid>
      </Form>
    </Grid>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response
})

const mapActionToProps = {
  _create: actions.create,
  _check: actions.check
}

export default connect(mapStateToProps, mapActionToProps)(EmployeeAcc);
