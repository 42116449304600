import axios from "axios";

const BASE_URL = "https://server.ieb-alumni.org/api/";

export const ENDPOINTS = {
  Employee: 'Employee',
  Employer: 'Employer',
  Member: 'Member',
  Student: 'Student',
  Business: 'Business',
  Auth: 'Auth',
  Admin: 'Admin',
  Home: 'Home',
}

export const CNTRL_ACTN = {
  Create: '/Create',
  Update: '/Update',
  Delete: '/Delete',
  GetAll: '/GetAll',
  IsEmailExist: '/IsEmailExist',
  GetAuthUser: '/GetAuthUser',
  Login: '/Login',
  Logout: '/Logout',
  ChangePass: '/ChangePass',
  AddCircular: '/AddCircular',
  PutCircular: '/PutCircular',
  DeleteCircular: '/DeleteCircular',
  GetAuthUserCirculars: '/GetAuthUserCirculars',
  GetCircularById: '/GetCircularById',
  GetCirculars: '/GetCirculars',
  ForgotPass: '/ForgotPass',
  CheckAdmin: '/CheckAdmin',
  GetPagesByType: '/GetPagesByType',
  GetPageByType: '/GetPageByType',
  CreatePage: '/CreatePage',
  UpdatePage: '/UpdatePage',
  DeletePage: '/DeletePage',
  DeleteSlider: '/DeleteSlider',
  GetSliders: '/GetSliders',
  CreateSlider: '/CreateSlider',
  UpdateSlider: '/UpdateSlider',
  DeleteQuote: '/DeleteQuote',
  GetQuotes: '/GetQuotes',
  CreateQuote: '/CreateQuote',
  UpdateQuote: '/UpdateQuote',
  GetHomeData: '/GetHomeData',
  GetGallery: '/GetGallery',
  ContactUs: '/ContactUs',
  GetSettings: '/GetSettings',
  CreateSettins: '/CreateSettins',
  UpdateSettins: '/UpdateSettins',
}

export const api = (endpoint) => {
  const url = BASE_URL + endpoint + '/';
  return {
    fetchAll: () => axios.get(url, { withCredentials: true }),
    fetchAllById: (id) => axios.get(url + id, { withCredentials: true }),
    fetchByObj: (obj) => axios.post(url, obj, { withCredentials: true }),
    create: (data) => axios.post(url, data, { withCredentials: true }),
    update: (data) => axios.put(url, data, { withCredentials: true }),
    delete: (id) => axios.delete(url + id, { withCredentials: true }),
    check: (value) => axios.get(url + value, { withCredentials: true })
  }
}
