import { Button } from '@mui/material'
import React from 'react'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

const ShareBtn = () => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Share this page',
        url: ''
      }).then(() => {
        return "Success";
      }).catch(console.error);
    }
  }
  return (
    <Button
      onClick={() => handleShare()}
      size='small'
      className='btn-primary border-radious-30 pr-10 pl-10'
      endIcon={<ShareOutlinedIcon />}>
      Share
    </Button>
  )
}

export default ShareBtn