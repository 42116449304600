import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { IsEmptyObj, PageTitle } from '../../hooks/Methods';
import AppBar from '../appBar/AppBar'
import BusinessHub from '../businessHub/BusinessHub';
import Circular from '../jobCorner/Circular';
import Seekers from '../jobCorner/Seekers';
import Alumni from '../members/Alumni';
import AlumniArch from '../archive/AlumniArch';
import Students from '../members/Students';
import ErrorPage from '../pages/ErrorPage';
import Body from './Body'
import Footer from './Footer'
import * as actions from '../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import { LoaderContext } from '../../utils/Context';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import PagesByType from '../pages/PagesByType';
import AmieCourse from '../archive/AmieCourse/AmieCourse';
import Gallery from '../archive/Gallery';
import StudentsCmt from '../committe/StudentsCmt'
import AlumniCmt from '../committe/AlumniCmt'
import AboutUs from '../aboutUs/AboutUs';
import ContactUs from '../contactUs/ContactUs';

const Home = (props) => {
  const { title,
    _fetchAll,
    _authUser } = props;
  const { setShowLoader } = useContext(LoaderContext)
  PageTitle(title);

  useEffect(() => {
    // < Check User authorized >
    if (IsEmptyObj(_authUser)) {
      setShowLoader(true);
      const onComplete = (res, err) => setShowLoader(false);
      _fetchAll(ENDPOINTS.Auth + CNTRL_ACTN.GetAuthUser,
        onComplete,
        actions.ACTION_TYPES.LOGIN);
    }
    // </ Check User authorized >
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AppBar />
      <Routes>
        <Route path='/*' element={<Body />} />
        <Route path='pages/:pageType' element={<PagesByType />} />
        <Route path='job'>
          <Route path='seekers/*' element={<Seekers />} />
          <Route path='circular/*' element={<Circular />} />
        </Route>
        <Route path='members/'>
          <Route path='alumni/*' element={<Alumni />} />
          <Route path='students/*' element={<Students />} />
        </Route>
        <Route path='archive'>
          <Route path='amieCourse' element={<AmieCourse />} />
          <Route path='gallery' element={<Gallery />} />
          <Route path='alumni' element={<AlumniArch />} />
        </Route>
        <Route path="aboutUs" element={<AboutUs />} />
        <Route path="contactUs" element={<ContactUs />} />
        <Route path='committe'>
          <Route path='students' element={<StudentsCmt />} />
          <Route path='alumni' element={<AlumniCmt />} />
        </Route>
        <Route path='businesshub/*' element={<BusinessHub />} />
        <Route path='unauthorized' element={
          <ErrorPage
            title='401 - Unauthorized'
            icon={<GppMaybeOutlinedIcon className='font-90' />}
            msgTitle='401 - Unauthorized'
            msg='Access is allowed only for registered and logged in users' />
        } />
        <Route path='*' element={
          <ErrorPage
            icon={<SentimentDissatisfiedRoundedIcon className='font-90' />}
            msgTitle="404 - Not Found"
            msg="The requested URL/Page Not found on this Server."
          />} />
      </Routes>
      <Footer />
    </>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser,
  _data: state.ieb.data
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(Home);
