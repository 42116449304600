import {
  Container,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Typography,
  Divider,
  Button,
  Pagination,
  Tooltip
} from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import { Input } from '../../controls';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import JobSort from '../sorting/Sorting';
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog } from '../../layout';
import { DropLi } from '../../hooks';
import { FilterList, PageTitle } from '../../hooks/Methods';
import { connect } from 'react-redux';
import * as actions from '../../actions/ieb';
import { LoaderContext } from '../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import ViewCircular from './ViewCircular';
import Ads from '../Ads/Ads';
import { Route, Routes, useNavigate } from 'react-router-dom';

let pageSize = 10;
const Circular = (props) => {
  const { _fetchAll, _list } = props;
  const { setShowLoader } = useContext(LoaderContext);
  const { departments, educations, experiences, employerOrigins, emplStatus } = DropLi();

  const [showMblSort, setShowMblSort] = useState(false);
  const [list, setList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [orgn, setOrgn] = useState([]);
  const [edu, setEdu] = useState([]);
  const [fieldOfExp, setFieldOfExp] = useState([]);
  const [dept, setDept] = useState([]);
  const [expVal, setExpVal] = useState([1, 40]);;
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  PageTitle('Job Circular');

  useEffect(() => {
    setShowLoader(true);
    const onComplete = (res) => {
      setShowLoader(false);
      setList(res.result);
    }
    _fetchAll(ENDPOINTS.Employer + CNTRL_ACTN.GetCirculars, onComplete);
    // BackToTop
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return () => {
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (_list.length > 0 && _list[0].emplStatus !== undefined)
      && FilterList(
        _list,
        setList,
        searchVal,
        expVal,
        dept,
        fieldOfExp,
        edu,
        orgn);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dept, fieldOfExp, expVal, searchVal, edu, orgn])

  const handleView = (val) => navigate(`viewProfile/${val.id}`);

  const DefaultView = (
    <Container maxWidth='xl' className='mt-120 mb-50'>
      <Grid container spacing={2}>
        {/* Sorting */}
        <Grid item md={3} xs={12} sx={{ display: { md: 'block', xs: 'none' } }}>
          <JobSort
            {...{
              expVal,
              setExpVal,
              edu,
              setEdu,
              dept,
              setDept,
              orgn,
              setOrgn,
              fieldOfExp,
              setFieldOfExp,
              origins: employerOrigins
            }}
          />
        </Grid>
        <Dialog
          show={showMblSort}
          setShow={setShowMblSort}
          fullScreen={true}>
          <JobSort
            {...{
              expVal,
              setExpVal,
              edu,
              setEdu,
              dept,
              setDept,
              orgn,
              setOrgn,
              fieldOfExp,
              setFieldOfExp,
              origins: employerOrigins
            }}
          />
        </Dialog>
        <Grid item md={9} xs={12}>
          <Grid container direction='row' spacing={2}>
            {/* Page Title */}
            <Grid item xs={12}>
              <Typography
                variant='subtitle1'
                className='font-18 color-secondary border-left'>Job Corner {'>'} Job Circular</Typography>
            </Grid>
            {/* Searchbar */}
            <Grid item xs={12}>
              <Paper className='p-15 d-flex align-center'>
                <IconButton
                  onClick={() => setShowMblSort(true)}
                  className='mr-10'
                  sx={{ display: { md: 'none', xs: 'block' } }}>
                  <TuneIcon className='font-28 color-secondary' />
                </IconButton>
                <Input
                  label="Find a Suitable Employee"
                  name="website"
                  placeholder='Key words'
                  className='input-primary w-100pr'
                  onChange={e => setSearchVal(e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton size='small' className='hov-primary-text'>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Paper>
            </Grid>
            {/* Body */}
            {list.length > 0 && list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize).map((circ, i) =>
              <Grid key={i} item xs={12}>
                <Paper className='p-10 hover color-gray' onClick={() => handleView(circ)}>
                  <Grid container direction='row' spacing={2}>
                    <Grid item md={3} xs={12}
                      className='text-center'>
                      <Tooltip title={circ.employer.companyName} followCursor>
                        <img
                          src={circ.employer.logo !== null ? circ.employer.logo : process.env.PUBLIC_URL + "/img/other/no-logo.png"}
                          alt='ABCD'
                          className='img-fluid border-radious-5 h-180 object-cover' />
                      </Tooltip>
                      <Typography className='font-10 color-gray'>
                        Company Logo
                      </Typography>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            className='color-secondary font-bold hover-underline'>
                            {circ.jobTitle}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            className='color-dark font-bold'>
                            {circ.employer.companyName}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            className='color-dark font-bold'>
                            {educations.find(j => j.id === circ.education).name + " in " + departments.find(j => j.id === circ.department).name} Engineering
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item md={5} xs={12} sx={{ borderRight: { md: '1px solid rgb(0 0 0 / 12%)' } }}>
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Concern Person: </span> {circ.employer.fullName}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Email:</span> {circ.email}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Salary:</span> ৳ {circ.salary}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Experience:</span> {circ.expDuration} Year
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item md={7} xs={12}>
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Vacancy:</span> {circ.vacancy}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Field of Experience:</span> {experiences.find(j => j.id === circ.fieldOfExp).name}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Employment Status:</span> {emplStatus.find(j => j.id === circ.emplStatus).name}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { md: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Origin:</span> {employerOrigins.find(j => j.id === circ.origin).name}
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} className='text-right mt-10'>
                          {circ.attachCircular && <Button
                            className='btn-primary border-radious-25 pr-10 pl-10'
                            size='small'
                            endIcon={<DownloadIcon />}>
                            <a href={circ.attachCircular} target="_blank" rel="noreferrer">Download Circular</a>
                          </Button>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} className='text-center'>
              <Ads />
            </Grid>
            {/* Pagination */}
            <Grid item xs={12} className='mt-30 d-flex justify-center'>
              <Paper className='p-15 border-radious-30'>
                <Pagination
                  color='primary'
                  count={Math.ceil(list.length / pageSize)}
                  defaultPage={1}
                  onChange={(event, newPage) => setPage(newPage)}
                  showFirstButton={true}
                  showLastButton={true} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <Routes>
      {_list.map((circ, i) =>
        <Route
          key={i}
          path={`viewProfile/${circ.id}`}
          element={<ViewCircular {...{ circ }} />} />)}
      <Route index element={DefaultView} />
    </Routes>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser,
  _list: state.ieb.list
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(Circular);