import { Container, Paper } from '@mui/material';
import React from 'react';
import EmployerAcc from '../../account/EmployerAcc';
import { PageTitle } from '../../../hooks/Methods'
export default function EmployerPrflEdit(props) {
  const { title } = props;
  PageTitle(title);
  return (
    <Container>
      <Paper className='mt-15'>
        <EmployerAcc isUpdate={true} />
      </Paper>
    </Container>

  )
}
