import { Button, Container, Grid, Paper } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Alert, Form } from '../../layout';
import { InputPass } from '../../controls';
import { Model, UseForm } from '../../hooks';
import { PassValidation } from '../../utils';
import { connect } from 'react-redux';
import * as actions from '../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import { LoaderContext } from '../../utils/Context';
import { FirstCharUppercase, PageTitle } from '../../hooks/Methods';

const ChangePassComp = (props) => {
  const { _fetchByObj, _authUser, isAdmin = false } = props;
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });
  const { setShowLoader } = useContext(LoaderContext);
  const { ChangePassMdl } = Model();
  const {
    values,
    errors,
    setErrors,
    handleInputPass,
    handleInputChange,
    passValidation,
    resetFormControls,
  } = UseForm(ChangePassMdl);
  const validateForm = () => {
    let temp = {};
    temp.currentPass = values.currentPass !== "" ? "" : "Current password is required."
    temp.password = values.password !== "" ? !passValidation.passValid ? " " : "" : "New password is required."
    temp.confirmPass = values.confirmPass !== "" ? !passValidation.match ? "Password and Confirm password not match." : "" : "Confirm Password is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  PageTitle("Change Password")

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        console.log('res: ', res);
        setShowLoader(false);
        if (res.status === 200) {
          resetFormControls();
          setAlert({
            isOpen: true,
            type: 'success',
            title: 'Congratulations',
            subTitle: 'You have successfully changed your password.',
          });
        }else if(res.status === 400){
          setErrors({
            ...errors,
            [res.error.key]: res.error.msg
          })
        }
      }
      _fetchByObj((!isAdmin ? FirstCharUppercase(_authUser.type) : ENDPOINTS.Admin) + CNTRL_ACTN.ChangePass, values, onComplete, actions.ACTION_TYPES.RESPONSE);
    }
  }

  return (
    <Container maxWidth="xs">
      <Paper>
        <Alert
          alert={alert}
          setAlert={setAlert}
          title="Congratulations!"
          msg="You have successfully changed your password" />
        <Form noValidate onSubmit={submitForm}>
          <Grid
            container
            className='p-15 pt-0 mt-inherit'
            spacing={3}>
            <Grid item xs={12} className='text-center color-secondary'>
              <Grid className='pos-relative mb-30'>
                <span className='font-bold font-26 bottom-border text-uppercase'>
                  Change Password
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputPass
                label="Current Password"
                name="currentPass"
                value={values.currentPass}
                onChange={handleInputChange}
                error={errors.currentPass}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12}>
              <InputPass
                label="New Password"
                name="password"
                value={values.password}
                onChange={handleInputPass}
                error={errors.password}
                className='input-primary w-100pr'
              />
              <PassValidation {...{ password: values.password, passValidation }} />
            </Grid>
            <Grid item xs={12}>
              <InputPass
                label="Confirm Password"
                name="confirmPass"
                value={values.confirmPass}
                onChange={e => handleInputPass(e, true)}
                error={(
                  errors.confirmPass !== "" &&
                  values.password !== "" &&
                  !passValidation.match &&
                  values.confirmPass !== "")
                  ? "Password and Confirm password not match"
                  : errors.confirmPass}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12} className='text-center'>
              <Button
                type='submit'
                className='btn-primary text-trans-none w-100pr font-18 p-10'>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Container>
  )
}

const mapStateToProps = state => ({
  _response: state.ieb.response,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _fetchByObj: actions.fetchByObj
}

export default connect(mapStateToProps, mapActionToProps)(ChangePassComp);