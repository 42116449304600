import React, { useEffect, useState } from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {
  Box,
  Toolbar,
  List as MuiList,
  Typography,
  Divider,
  IconButton,
  Avatar,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { WinDims } from '../hooks/Methods';
import customizeMui from '../utils/customizeMui';


const List = styled(MuiList)({
  '&': {
    margin: '5px',
  },
  '& .MuiListItemButton-root': {
    borderRadius: '10px',
    marginBottom: '5px',
    '&, & .MuiListItemIcon-root': {
      color: '#D1D5DB',
    },
  },
  // selected and (selected + hover) states
  '&& .Mui-selected': {
    backgroundColor: '#e3e3e321',
    borderRight: '4px solid #ffb606',
    '&, & .MuiListItemIcon-root': {
      color: '#ffb606 !important',
    },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: '#e3e3e321',
    borderRadius: '10px',
    '&, & .MuiListItemIcon-root': {
      color: '#D1D5DB',
    },
  },
});

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const { theme } = customizeMui();

export default function Dashboard(props) {
  const {
    menu,
    headerMenu,
    children,
    title,
    subtitle = 'Dashboard',
    ...other } = props;
  const windowDims = WinDims().width
  const [open, setOpen] = useState(windowDims > 1200 ? true : false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(windowDims > 1200 ? true : false)
  }, [windowDims])

  return (
    <ThemeProvider theme={theme} {...other}>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="absolute"
          open={open}
          elevation={1}
          className='bg-nav color-secondary'>
          <Toolbar className='pr-20'>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                className='font-18'
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {title}
              </Typography>
              <Typography variant='subtitle2' className='color-gray'>
                {subtitle}
              </Typography>
            </Grid>

            {/* header props */}
            {headerMenu}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          classes={{ paper: 'bg-secondary color-light' }}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              px: [1],
            }}>
            <Avatar alt='logo' src={process.env.PUBLIC_URL + "/img/site/logo.png"} />
            <Typography
              variant='p'
              className='font-16'>
              {subtitle}
            </Typography>
            <IconButton onClick={toggleDrawer} className='color-light'>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider className='bg-gray' />
          <List sx={{
            height: 'calc(100vh - 92px)',
            overflow: 'auto',
            paddingRight: '10px'
          }}>
            {menu}
          </List>
        </Drawer>
        <Box
          component="main"
          className='bg-default'
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}>
          <Toolbar />
          <Grid className='pt-20 pb-20'>
            {children}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}