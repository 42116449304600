import { Card, CardContent, CardMedia, Container, Grid, Pagination, Paper, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { PageTitle } from '../../hooks/Methods';
import * as actions from '../../actions/ieb';
import { LoaderContext } from '../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';

let pageSize = 16;
const Gallery = (props) => {
  const { _list, _fetchAll } = props;
  const { setShowLoader } = useContext(LoaderContext);
  const [page, setPage] = useState(1);

  PageTitle('Image Gallery');

  useEffect(() => {
    // Get Home Page Data.
    setShowLoader(true);
    const onComplete = (res, err) => setShowLoader(false);
    _fetchAll(ENDPOINTS.Home + CNTRL_ACTN.GetGallery, onComplete)
    // BackToTop
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth='xl'>
      <Grid container className='pl-20 pr-20 mt-120 mb-50' spacing={2}>
        <Grid item xs={12} className='pos-relative mb-40'>
          <Typography
            variant='h4'
            className='text-center font-bold font-32 color-secondary bottom-border'>
            Gallery
          </Typography>
        </Grid>
        {(_list.length > 0 && _list[0].imgType) && _list
          .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
          .map((item, i) =>
            <Grid key={i} item xs={12} md={3}>
              <Card>
                <a href={item.photo} target='_blank' rel="noreferrer">
                  <CardMedia
                    component='img'
                    className='h-250 cursor-pointer object-cover'
                    image={item.photo}
                  />
                </a>
                <CardContent className='pb-10 pt-0'>
                  <Typography
                    variant='h6'
                    className='font-16 font-bold color-secondary mt-10'>
                    {item.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}

        <Grid item xs={12} className='mt-30 d-flex justify-center'>
          <Paper className='p-15 border-radious-30'>
            <Pagination
              color='primary'
              count={Math.ceil(_list.length / pageSize)}
              defaultPage={1}
              onChange={(e, newPage) => setPage(newPage)}
              showFirstButton={true}
              showLastButton={true} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
const mapStateToProps = state => ({
  _list: state.ieb.list,
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(Gallery);