import { Container, Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react'
import { BackToTop, PageTitle } from '../../hooks/Methods';
import { DropLi } from '../../hooks';
import { ShareBtn } from '../../controls';

const ViewAlumniMember = ({ member }) => {

  const { departments,
    bloods,
    experiences,
    fellowMemberNo,
    educationals } = DropLi();

  PageTitle("View Member Profile");
  BackToTop();


  return (
    <Container>
      <Grid container spacing={2} className='mt-100 mb-80'>
        {/* Page Title */}
        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            className='font-18 color-secondary border-left'>Member {'>'} Alumni {'>'} View Profile</Typography>
        </Grid>
        {/* View data  */}
        <Grid item xs={12}>
          <Paper className='p-20'>
            <Grid container direction='row' className='color-gray' spacing={2}>
              <Grid item md={3} xs={12} className='text-center d-flex justify-center align-center'>
                <img
                  src={member.photo}
                  alt={member.fullName}
                  className='img-fluid border-radious-5 h-220 object-cover' />
              </Grid>
              <Grid item md={9} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant='h6'
                      className='color-secondary font-bold hover-underline'>
                      {member.fullName}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      className='color-dark font-bold'>
                      {educationals.find(j => j.id === member.educational).name + " in " + departments.find(j => j.id === member.department).name} Engineering
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={6} xs={12} className='color-gray' sx={{ borderRight: { md: '1px solid rgb(0 0 0 / 12%)' } }}>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Contact No:</span> <a href={'tel:' + member.mobile}>{member.mobile}</a>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Email:</span> <a href={'mailto:' + member.email}>{member.email}</a>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Graduation Year:</span> {member.gradYear}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Experience:</span> {member.expDuration} Years
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={6} xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>{fellowMemberNo.find(j => j.id === member.fellowMemberNoType).name + "/" + member.fellowMemberNo}</span>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Blood Group:</span> {bloods.find(j => j.id === member.bloodGroup).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Field of Experience:</span> {experiences.find(j => j.id === member.fieldOfExp).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Home District:</span> {member.homeDist}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Present Designation:</span> {member.presLastDes}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Present Organization:</span> {member.presLastOrg}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: member.details }}
                  className='table-border' />
              </Grid>
              <Grid item xs={12} className='text-right'>
                <ShareBtn />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ViewAlumniMember