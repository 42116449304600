import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import { Model, UseForm } from '../../../../hooks';
import { PageTitle, RemoveEmptyPropFromObj } from '../../../../hooks/Methods';
import { LoaderContext } from '../../../../utils/Context';
import * as actions from '../../../../actions/ieb';
import { Alert, Form } from '../../../../layout';
import { Input, InputImg } from '../../../../controls';

const SiteSettings = (props) => {
  const {
    _create,
    _update,
    _fetch } = props;
  const { SiteMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext)
  const [isUpdate, setIsUpdate] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    toFormData,
    handleImg
  } = UseForm(SiteMdl);

  PageTitle(isUpdate ? 'Edit Settings' : 'Create Settings')

  const validateForm = () => {
    let temp = {};
    temp.headerLogoFile = values.headerLogoFile !== "" ? "" : "Logo is required."
    temp.footerLogoFile = values.footerLogoFile !== "" ? "" : "Logo is required."
    temp.siteName = values.siteName !== "" ? "" : "Site name is required."
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    temp.phone = values.phone !== "" ? "" : "Contact number is required."
    temp.title = values.title !== "" ? "" : "Title is required."
    temp.address = values.address !== "" ? "" : "Address is required."
    temp.shortDes = values.shortDes !== "" ? "" : "Short Description is required."

    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          isUpdate
            ? setAlert({
              isOpen: true,
              type: 'success',
              title: 'Awesome!',
              subTitle: `You have successfully updated settings.`,
            })
            : setAlert({
              isOpen: true,
              type: 'success',
              title: 'Congratulations!',
              subTitle: `You have successfully created settings.`,
            })
        }
      }
      isUpdate
        ? _update(ENDPOINTS.Admin + CNTRL_ACTN.UpdateSettins, toFormData(RemoveEmptyPropFromObj(values)), onComplete)
        : _create(ENDPOINTS.Admin + CNTRL_ACTN.CreateSettins, toFormData(values), onComplete)
    }
  }

  useEffect(() => {
    setShowLoader(true);
    const onComplete = (res, err) => {
      setShowLoader(false);
      if (res.status === 200) {
        setIsUpdate(true);
        setValues({ ...values, ...res.result });
      } else if (res.status === 204) setIsUpdate(false);
    }
    _fetch(ENDPOINTS.Home + CNTRL_ACTN.GetSettings, onComplete, actions.ACTION_TYPES.CREATE)
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Alert alert={alert} setAlert={setAlert} />
      <Container maxWidth='lg' className='mt-30'>
        <Paper>
          <Form noValidate
            onSubmit={submitForm}>
            <Grid container spacing={2} className='pl-20 pr-20 pt-30 pb-30' >
              <Grid item xs={12} className='text-center mb-30 pos-relative'>
                <Typography variant='p' className='bottom-border color-dark font-30'>
                  {isUpdate ? 'Update Settings' : 'Create Settings'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} container spacing={2}>
                <Grid item xs={12} md={6} className='d-flex justify-center'>
                  <InputImg
                    title="Header Logo"
                    name='headerLogoFile'
                    src={values.headerLogo}
                    defImg={process.env.PUBLIC_URL + "/img/other/no-logo.png"}
                    rmv={() => { setValues({ ...values, 'headerLogoFile': '', 'headerLogo': '' }) }}
                    value={values.headerLogoFile}
                    onChange={handleImg}
                    error={errors.headerLogoFile} />
                </Grid>
                <Grid item xs={12} md={6} className='d-flex justify-center'>
                  <InputImg
                    title="Footer Logo"
                    name='footerLogoFile'
                    src={values.footerLogo}
                    defImg={process.env.PUBLIC_URL + "/img/other/no-logo.png"}
                    rmv={() => { setValues({ ...values, 'footerLogoFile': '', 'footerLogo': '' }) }}
                    value={values.footerLogoFile}
                    onChange={handleImg}
                    error={errors.footerLogoFile} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container spacing={1}>
                <Grid item xs={12}>
                  <Input
                    label="Site Name"
                    name="siteName"
                    value={values.siteName}
                    onChange={handleInputChange}
                    error={errors.siteName}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Support Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Contact Number"
                    name="phone"
                    value={values.phone}
                    onChange={handleInputChange}
                    error={errors.phone}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Fax"
                    name="fax"
                    value={values.fax}
                    onChange={handleInputChange}
                    error={errors.fax}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label="Footer Text Title"
                    name="title"
                    value={values.title}
                    onChange={handleInputChange}
                    error={errors.title}
                    className='input-primary w-100pr'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Short Description"
                  name="shortDes"
                  value={values.shortDes}
                  onChange={handleInputChange}
                  multiline
                  rows={3}
                  error={errors.shortDes}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Company Address"
                  name="address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Facebook Link"
                  name="facebook"
                  value={values.facebook}
                  onChange={handleInputChange}
                  error={errors.facebook}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="LinkIn Link"
                  name="linkIn"
                  value={values.linkIn}
                  onChange={handleInputChange}
                  error={errors.linkIn}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Instagram Link"
                  name="instagram"
                  value={values.instagram}
                  onChange={handleInputChange}
                  error={errors.instagram}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Twitter Link"
                  name="twitter"
                  value={values.twitter}
                  onChange={handleInputChange}
                  error={errors.twitter}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} className='text-right'>
                <Button
                  type='submit'
                  variant='contained'
                  size="large"
                  className='btn-primary min-w-150'>
                  {isUpdate ? 'Save Changes' : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Container>
    </>
  )
}
const mapStateToProps = state => ({
})
const mapActionToProps = {
  _create: actions.create,
  _update: actions.update,
  _fetch: actions.fetchAll,
}
export default connect(mapStateToProps, mapActionToProps)(SiteSettings);
