import { Button, Grid } from '@mui/material'
import React from 'react'
import Dialog from './Dialog'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function Alert(props) {
  const {
    alert,
    setAlert } = props;
    

  return (
    <>
      {alert &&
        <Dialog
          show={alert.isOpen}
          maxWidth='xs'>
          {alert.type === 'confirmation' ? <ConfirmationCom {...{ alert, setAlert }} />
            : <Grid container direction='column'
              sx={{
                position: 'relative',
                textAlign: 'center',
              }}>
              <Grid className='mt-50'>
                {alert.type === 'success' ? <CheckCircleRoundedIcon className='color-success font-70' />
                  : alert.type === 'warning' ? <WarningAmberRoundedIcon className='color-primary font-70' />
                    : alert.type === 'error' ? <ErrorOutlineRoundedIcon className='color-danger font-70' />
                      : alert.type === 'info' && <InfoOutlinedIcon className='color-info font-70' />}
              </Grid>
              <Grid>
                <p className='font-30 mt-0 mb-0 font-bold'>
                  {alert.title}
                </p>
                <p className='p mt-10 pl-30 pr-30'>
                  {alert.subTitle}
                </p>
              </Grid>

              <Grid className='mt-20 p-10'>
                <Button
                  className={(alert.type === 'success' ? 'btn-success' :
                    alert.type === 'warning' ? 'btn-primary' :
                      alert.type === 'error' ? 'btn-danger' :
                        alert.type === 'info' ? 'btn-secondary-trans' : 'btn') + ' font-18'}
                  fullWidth
                  onClick={() => {
                    setAlert({ ...alert, isOpen: false });
                    alert.onClick && alert.onClick();
                  }}>Ok</Button>
              </Grid>
            </Grid>}
        </Dialog>}
    </>
  )
}

const ConfirmationCom = (props) => {
  const { alert, setAlert } = props;
  return (
    <>
      <Grid container direction='column'
        sx={{
          position: 'relative',
          textAlign: 'center',
        }}>
        <Grid className='mt-50'>
          <InfoOutlinedIcon className='color-primary font-70' />
        </Grid>
        <Grid>
          <p className='font-30 mt-0 mb-0 font-bold'>
            {alert.title}
          </p>
          <p className='p mt-10 pl-30 pr-30'>
            {alert.subTitle}
          </p>
        </Grid>

        <Grid className='p-10 mb-20'>
          <Button
            className='font-18 btn-secondary m-5 min-w-100'
            onClick={alert.onConfirm && alert.onConfirm}>{alert.confirmBtnTitle ? alert.confirmBtnTitle : 'Yes'}</Button>
          <Button
            className='font-18 btn-danger m-5 min-w-100'
            onClick={() => alert.onCancel !== undefined ? alert.onCancel : setAlert({ ...alert, isOpen: false })}>{alert.cancelBtnTitle ? alert.cancelBtnTitle : 'Cancel'}</Button>
        </Grid>
      </Grid>
    </>
  )
}
