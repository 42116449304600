import {
  Container,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Typography,
  Divider,
  Button,
  Pagination
} from '@mui/material'
import React, { useContext, useState, useEffect } from 'react';
import { Input } from '../../controls';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { DropLi } from '../../hooks';
import { BackToTop, FilterList, PageTitle } from '../../hooks/Methods';
import { connect } from 'react-redux';
import * as actions from '../../actions/ieb';
import { LoaderContext } from '../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import Ads from '../Ads/Ads';
import JobSort from '../../component/sorting/Sorting';
import { Dialog } from '../../layout';
import TuneIcon from '@mui/icons-material/Tune';
import ViewBusinessProfile from './ViewBusinessProfile';
import { Route, Routes, useNavigate } from 'react-router-dom';

let pageSize = 10;
const BusinessHub = (props) => {
  const { _fetchAll, _list } = props;
  const { setShowLoader } = useContext(LoaderContext);
  const { fellowMemberNo, educationals, departments, employerOrigins } = DropLi();
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [showMblSort, setShowMblSort] = useState(false);
  const [orgn, setOrgn] = useState([]);
  const navigate = useNavigate();

  PageTitle('Business Hub');
  BackToTop();

  const handleView = (val) => navigate(`viewProfile/${val.id}`);


  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    _list && setList(_list.filter(j => Object.keys(j).some(x => String(j[x])
      .toLowerCase()
      .includes(e.target.value.toLowerCase()))))
  }

  useEffect(() => {
    setShowLoader(true);
    const onComplete = (res) => {
      setShowLoader(false);
      setList(res.result);
    }
    _fetchAll(ENDPOINTS.Business + CNTRL_ACTN.GetAll, onComplete);
    return () => {
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (_list.length > 0 && _list[0].compMainJob !== undefined)
      && FilterList(
        _list,
        setList,
        searchVal,
        [],
        [],
        [],
        [],
        orgn);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal, orgn])

  // Default View Page
  const DefaultView = (
    <Container maxWidth='xl' className='mt-120 mb-50' >
      <Grid container spacing={2}>
        {/* Sorting */}
        <Grid item md={3} xs={12} sx={{ display: { md: 'block', xs: 'none' } }}>
          <JobSort
            {...{
              orgn,
              setOrgn,
              orgnTitle: 'Business Concern',
              origins: employerOrigins
            }}
          />
        </Grid>
        <Dialog
          show={showMblSort}
          setShow={setShowMblSort}
          fullScreen={true}>
          <JobSort
            {...{
              orgn,
              setOrgn,
              orgnTitle: 'Business Concern',
              origins: employerOrigins
            }}
          />
        </Dialog>
        <Grid item xs={12} md={9}>
          <Grid container direction='row' spacing={2}>
            {/* Page Title */}
            <Grid item xs={12}>
              <Typography
                variant='subtitle1'
                className='font-18 color-secondary border-left'>Business Hub</Typography>
            </Grid>
            {/* Searchbar */}
            <Grid item xs={12}>
              <Paper className='p-15 d-flex align-center'>
                <IconButton
                  onClick={() => setShowMblSort(true)}
                  className='mr-10'
                  sx={{ display: { md: 'none', xs: 'block' } }}>
                  <TuneIcon className='font-28 color-secondary' />
                </IconButton>
                <Input
                  label="Search"
                  name="search"
                  placeholder='Key words'
                  className='input-primary w-100pr'
                  value={searchVal}
                  onChange={handleSearch}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton size='small' className='hov-primary-text'>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Paper>
            </Grid>
            {/* Body */}
            {list.length > 0 && list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize).map((item, i) =>
              <Grid key={i} item xs={12}>
                <Paper className='p-10 hover'
                  onClick={() => handleView(item)}>
                  <Grid container direction='row' spacing={2}>
                    <Grid item md={9} xs={12}>
                      <Grid container className='color-gray p-5'>
                        <Grid item xs={3} md={2} className='text-center'>
                          <img
                            src={item.logo !== null ? item.logo : process.env.PUBLIC_URL + "/img/other/no-logo.png"}
                            alt={item.companyName}
                            className='mx-w-60 object-cover' />
                          <Typography variant='subtitle2' className='font-10 color-gray'>Company Logo</Typography>
                        </Grid>
                        <Grid item xs={9} md={10}>
                          <Typography
                            variant='h6'
                            className='color-secondary font-bold font-18 hover-underline'>
                            {item.fullName + ", " + fellowMemberNo.find(j => j.id === item.fellowMemberNoType).name + "/" + item.fellowMemberNo}
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            className='color-dark font-bold'>
                            {educationals.find(j => j.id === item.eduQualif).name + " in " + departments.find(j => j.id === item.department).name} Engineering
                          </Typography>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            className='color-dark font-bold'>
                            {item.designation}, {item.companyName}
                          </Typography>
                        </Grid>
                        <Grid item lg={6}
                          xs={12}
                          className='color-gray'
                          sx={{ borderRight: { lg: '1px solid rgb(0 0 0 / 12%)' } }}>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Contact No:</span> <a href={'tel:' + item.mobile}>{item.mobile}</a>
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} className='color-gray'>
                          <Divider />
                          <Typography
                            variant='subtitle1'
                            sx={{ pl: { lg: '10px', xs: '0px' } }}>
                            <span className='font-bold'>Business Concern:</span> {item.origin ?
                              employerOrigins.find(j => j.id === item.origin).name : ''}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} xs={12} className='color-gray' sx={{ borderRight: { lg: '1px solid rgb(0 0 0 / 12%)' } }}>
                          <Divider />
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Email:</span> <a href={'mailto:' + item.email}>{item.email}</a>
                          </Typography>
                          <Divider sx={{ display: { lg: 'block', sm: 'none' } }} />
                        </Grid>
                        <Grid item lg={6} xs={12} className='color-gray'>
                          <Divider />
                          <Typography
                            variant='subtitle1' sx={{ pl: { lg: '10px', xs: '0px' } }}>
                            <span className='font-bold' >Website:</span> <a href={item.website.includes('http') ? item.website : `http://${item.website}`} target='_blank' rel="noopener noreferrer"> {item.website}</a>
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} className='color-gray'>
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Company Main Job/Key Products/ Services:</span> {item.compMainJob}
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} className='color-gray'>
                          <Typography
                            variant='subtitle1'>
                            <span className='font-bold'>Company Address:</span> {item.compAddress}
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} className='mt-10'>
                          {item.attachCompPrfl && <Button
                            className='btn-primary border-radious-25 pr-10 pl-10'
                            size='small'
                            endIcon={<DownloadIcon />}>
                            <a href={item.attachCompPrfl} target="_blank" rel="noreferrer">Company Profile</a>
                          </Button>}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3} xs={12} className='text-center'>
                      <img
                        src={item.photo}
                        alt='ABCD'
                        className='img-fluid border-radious-5 h-240 object-cover' />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} className='text-center'>
              <Ads />
            </Grid>
            <Grid item xs={12} className='mt-30 d-flex justify-center'>
              <Paper className='p-15 border-radious-30'>
                <Pagination
                  color='primary'
                  count={Math.ceil(list.length / pageSize)}
                  defaultPage={1}
                  onChange={(event, newPage) => setPage(newPage)}
                  showFirstButton={true}
                  showLastButton={true} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container >
  )

  return (
    <>
      <Routes>
        {/* View Business Profile */}
        {_list.map((business, i) =>
          <Route
            key={i}
            path={`viewProfile/${business.id}`}
            element={<ViewBusinessProfile {...{ business }} />} />)}
        {/* Default View  */}
        <Route index element={DefaultView} />
      </Routes>
    </>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser,
  _list: state.ieb.list
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(BusinessHub);