import React from 'react'
import {
  Slider as MuiSlider, Typography,
} from '@mui/material'

export default function Slider(props) {
  const { label, className, variant = 'color-primary', ...other } = props;
  const [value, setValue] = React.useState([1, 10]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        className='color-secondary'>
        {label}
      </Typography>
      <MuiSlider
        className={className + ' ' + variant}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        {...other}
      />
    </>
  )
}
