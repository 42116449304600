import { Button, Grid, Grow } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { Form } from '../../../../layout'
import { Input, Select } from '../../../../controls';
import { DropLi, Model, UseForm } from '../../../../hooks';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { LoaderContext } from '../../../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';

const SendFrgtCode = (props) => {
  const { setActiveStep, forgotVal, setForgotVal, _fetchByObj, isAdmin } = props;
  const { acctType } = DropLi();
  const { ForgotMdl } = Model();
  const { setShowLoader } = useContext(LoaderContext);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    toFormData,
  } = UseForm(ForgotMdl);
  const validateForm = () => {
    let temp = {};
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    if (!isAdmin) temp.acctType = values.acctType !== "" ? "" : "Account type is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res, err) => {
        setShowLoader(false);
        if (res !== undefined && res.status === 200) {
          setActiveStep(2);
        } else if (res !== undefined && res.status === 204) {
          setErrors({
            ...errors,
            email: res.msg
          })
        }
      }
      values.forgotStep = 1;
      _fetchByObj((isAdmin ? ENDPOINTS.Admin : ENDPOINTS.Auth) + CNTRL_ACTN.ForgotPass, toFormData(values), onComplete, actions.ACTION_TYPES.CREATE)
    }
  }

  useEffect(() => {
    setValues(forgotVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setForgotVal({ ...values })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <Grow in={true} timeout={800}>
      <Grid>
        <Form noValidate onSubmit={submitForm}>
          <Grid
            container
            className='p-15 pt-0'
            maxWidth={400}
            spacing={3}>
            <Grid item xs={12} className='text-center color-secondary'>
              <Grid className='pos-relative mb-30'>
                <span className='font-bold font-26 bottom-border text-uppercase'>
                  Forgot Password
                </span>
              </Grid>
            </Grid>
            {!isAdmin && <Grid item xs={12}>
              <Select
                label='Account Type'
                name='acctType'
                className='input-primary w-100pr'
                value={values.acctType}
                onChange={handleInputChange}
                error={errors.acctType}
                options={acctType}
              />
            </Grid>}
            <Grid item xs={12}>
              <Input
                label="Email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                className='input-primary w-100pr'
              />
            </Grid>
            <Grid item xs={12} className='text-center'>
              <Button
                type='submit'
                className='btn-primary text-trans-none w-100pr font-18 p-10'>
                Send Verification Code
              </Button>
              <Button
                onClick={() => setActiveStep(undefined)}
                className='btn text-trans-none font-18 w-100pr mt-10 p-10'>
                Back
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grow>
  )
}

const mapStateToProps = state => ({
})

const mapActionToProps = {
  _fetchByObj: actions.fetchByObj,
}

export default connect(mapStateToProps, mapActionToProps)(SendFrgtCode);
