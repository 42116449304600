import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Input, InputPass, InputRich, Select } from '../../controls';
import InputImg from '../../controls/InputImg';
import { DropLi, UseForm, Model } from '../../hooks';
import { Alert, Form } from '../../layout';
import { PassValidation } from '../../utils';
import * as actions from '../../actions/ieb';
import { useNavigate } from 'react-router-dom';
import { LoaderContext } from '../../utils/Context';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import { IsEmptyObj, RemoveEmptyPropFromObj } from '../../hooks/Methods';

const EmployerAcc = (props) => {
  const { setShowForm, isUpdate = false, _check, _create, _authUser, _update } = props;
  const { EmployerMdl } = Model();
  const { employerOrigins, fellowMemberNo } = DropLi();
  const navigate = useNavigate();
  const {setShowLoader} = useContext(LoaderContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputPass,
    passValidation,
    resetFormControls,
    handleImg,
    toFormData,
  } = UseForm(EmployerMdl);

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : "Full name is required."
    temp.fellowMemberNo = values.fellowMemberNo !== "" ? "" : "Fellowship/Membershop no. is required."
    temp.fellowMemberNoType = values.fellowMemberNoType !== "" ? "" : "No. Type is required."
    temp.companyName = values.companyName !== "" ? "" : "Company name is required."
    temp.employerOrigin = values.employerOrigin !== "" ? "" : "Employer origin is required."
    temp.mobile = values.mobile !== "" ? "" : "Mobile is required."
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    if (!isUpdate) {
      temp.password = values.password !== "" ? !passValidation.passValid ? " " : "" : "Password is required."
      temp.confirmPass = values.confirmPass !== "" ? !passValidation.match ? "Password and Confirm password not match." : "" : "Confirm Password is required."
    }
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      // Create Action
      if (!isUpdate) {
        const onComplete = (res) => {
          setShowLoader(false);
          if (res.status === 200) {
            navigate("/dashboard/employer")
          } else if (res.status === 409) {
            setErrors({
              ...errors,
              email: res.msg
            })
          }
        }
        _create(ENDPOINTS.Employer + CNTRL_ACTN.Create, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.LOGIN);
      }// Update Action
      else if (isUpdate) {
        const onComplete = (res) => {
          setShowLoader(false);
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: 'success',
              title: 'Awesome!',
              subTitle: 'You hove successfully update your profile.',
            });
          } else if (res.status === 409 && res.msg) {
            setErrors({
              errors,
              'email': res.msg
            })
          } else {
            setAlert({
              isOpen: true,
              type: 'error',
              title: res.status,
              subTitle: res.msg && res.msg,
            })
          }
        }
        _update(ENDPOINTS.Employer + CNTRL_ACTN.Update, toFormData(RemoveEmptyPropFromObj(values)), onComplete, actions.ACTION_TYPES.LOGIN)
      }
    }
  }
  const closeForm = () => {
    setShowForm(false);
    resetFormControls();
  }

  const isEmailExist = (value) => {
    if (value !== '') {
      const onComplete = (res) => res.status === 409 && setErrors({ ...errors, 'email': res.msg });
      _check(ENDPOINTS.Employer + CNTRL_ACTN.IsEmailExist, value, onComplete);
    }
  }

  useEffect(() => {
    if (isUpdate) {
      (_authUser.result !== undefined && !IsEmptyObj(_authUser.result)) && setValues({
        ...values,
        ..._authUser.result
      })
    }
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser])

  return (
    <>
      <Alert
        alert={alert}
        setAlert={setAlert} />
      <Form noValidate
        onSubmit={submitForm}>
        <Grid container className='pl-20 pr-20 pt-30 pb-30' spacing={2}>
          <Grid item xs={12} className='text-center mb-30 pos-relative'>
            <Typography variant='p' className='bottom-border color-dark font-30'>
              {isUpdate ? 'Edit Employer Profile' : 'Employer Account Form'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className='d-flex justify-center'>
            <InputImg
              name='logoFile'
              title='Attach Logo'
              src={values.logo}
              rmv={() => { setValues({ ...values, 'logoFile': '', 'logo': '' }) }}
              value={values.logoFile}
              onChange={handleImg}
              error={errors.logoFile} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Company Name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleInputChange}
                  error={errors.companyName}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Full Name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleInputChange}
                  error={errors.fullName}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  onBlur={e => !isUpdate && isEmailExist(e.target.value)}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={8}>
                  <Input
                    label="Fellowship/Membership No."
                    name="fellowMemberNo"
                    type='number'
                    value={values.fellowMemberNo}
                    onChange={handleInputChange}
                    error={errors.fellowMemberNo}
                    className='input-primary w-100pr'
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    label='No. Type'
                    name='fellowMemberNoType'
                    className='input-primary w-100pr'
                    value={values.fellowMemberNoType}
                    onChange={handleInputChange}
                    error={errors.fellowMemberNoType}
                    options={fellowMemberNo} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Mobile No."
                  name="mobile"
                  value={values.mobile}
                  onChange={handleInputChange}
                  error={errors.mobile}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label='Employer Origin'
                  name='employerOrigin'
                  className='input-primary w-100pr'
                  value={values.employerOrigin}
                  onChange={handleInputChange}
                  error={errors.employerOrigin}
                  options={employerOrigins} />
              </Grid>
              {!isUpdate && <>
                <Grid item xs={12} md={6}>
                  <InputPass
                    label="Password"
                    name="password"
                    value={values.password}
                    onChange={handleInputPass}
                    error={errors.password}
                    className='input-primary w-100pr'
                  />
                  <PassValidation {...{ password: values.password, passValidation }} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputPass
                    label="Confirm Password"
                    name="confirmPass"
                    value={values.confirmPass}
                    onChange={e => handleInputPass(e, true)}
                    error={(
                      errors.confirmPass !== "" &&
                      values.password !== "" &&
                      !passValidation.match &&
                      values.confirmPass !== "")
                      ? "Password and Confirm password not match"
                      : errors.confirmPass}
                    className='input-primary w-100pr'
                  />
                </Grid>
              </>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputRich
              label='Details (Optional): '
              type="simple"
              value={values.details}
              onBlur={(val) => setValues({...values, details: val})} />
          </Grid>
          {!isUpdate ? <>
            <Grid item xs={12} md={6}>
              <Button
                type='submit'
                variant='contained'
                className='btn-primary w-100pr font-20'
                size='large'>
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                onClick={closeForm}
                variant='contained'
                className='btn w-100pr font-20'
                size='large'>
                close
              </Button>
            </Grid>
          </>
            :
            <Grid item xs={12} className='text-right'>
              <Button
                type='submit'
                variant='contained'
                className='btn-primary'
                size='large'>
                Save Changes
              </Button>
            </Grid>}

        </Grid>
      </Form>
    </>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _create: actions.create,
  _check: actions.check,
  _update: actions.update
}

export default connect(mapStateToProps, mapActionToProps)(EmployerAcc);
