import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Paper,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { PageTitle } from '../../hooks/Methods';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import * as actions from '../../actions/ieb';
import { LoaderContext } from '../../utils/Context';

let pageSize = 16;
const PagesByType = (props) => {
  const { _home, _fetchAll } = props;
  const navigate = useNavigate();
  const { pageType } = useParams();
  const [page, setPage] = useState(1);
  const [pgData, setPgData] = useState([]);
  const [pgTitle, setPgTitle] = useState('');
  const { setShowLoader } = useContext(LoaderContext);

  PageTitle(pgTitle);

  const handleView = (item) => navigate(`/${item.id}/${item.pageType}`);

  useEffect(() => {
    // Get Home Page Data.
    if (_home.result === undefined || _home.result.pages === undefined) {
      const onComplete = (res, err) => setShowLoader(false);
      _fetchAll(ENDPOINTS.Home + CNTRL_ACTN.GetHomeData, onComplete, actions.ACTION_TYPES.HOME)
    }
    // Set Page Title
    switch (parseInt(pageType)) {
      case 2:
        setPgTitle('All News');
        break;
      case 3:
        setPgTitle('All Events');
        break;
      case 4:
        setPgTitle('All Notices');
        break;
      case 5:
        setPgTitle('All Research');
        break;
      default:
        setPgTitle('All Post');
        break;
    }
    // BackToTop
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (_home.result !== undefined && _home.result.pages !== undefined) &&
      setPgData(_home.result.pages.filter(j => j.pageType === parseInt(pageType)))
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_home.result])

  return (
    <Grid container className='pl-20 pr-20 mt-120 mb-50' spacing={2}>
      <Grid item xs={12} className='pos-relative mb-40'>
        <Typography
          variant='h4'
          className='text-center font-bold font-32 color-secondary bottom-border'>
          {pgTitle}
        </Typography>
      </Grid>
      {pgData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
        .map((item, i) =>
          <Grid key={i} item xs={12} md={3}>
            <Card>
              <CardMedia
                onClick={() => handleView(item)}
                component='img'
                alt={item.title}
                className='h-250 cursor-pointer'
                image={item.coverPhoto ? item.coverPhoto : process.env.PUBLIC_URL + "/img/other/no-image.png"}
              />
              <CardContent className='pb-15'>
                <Grid className='d-flex'>
                  <CalendarMonthIcon className='color-gray mr-10 font-20' />
                  <Typography
                    variant='h6'
                    className='font-14 font-bold color-gray'>
                    {item.createDate}
                  </Typography>
                </Grid>
                <Typography
                  onClick={() => handleView(item)}
                  variant='h6'
                  className='font-16 font-bold color-secondary mt-10 cursor-pointer hov-primary'>
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      <Grid item xs={12} className='mt-30 d-flex justify-center'>
        <Paper className='p-15 border-radious-30'>
          <Pagination
            color='primary'
            count={Math.ceil(pgData.length / pageSize)}
            defaultPage={1}
            onChange={(e, newPage) => setPage(newPage)}
            showFirstButton={true}
            showLastButton={true} />
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  _home: state.ieb.homeData,
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(PagesByType);