import { Button, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react'
import { BackToTop, PageTitle } from '../../hooks/Methods';
import DownloadIcon from '@mui/icons-material/Download';
import { DropLi } from '../../hooks';
import { ShareBtn } from '../../controls';

const ViewBusinessProfile = ({ business }) => {

  const { departments,
    fellowMemberNo,
    educationals,
    employerOrigins } = DropLi();

  PageTitle("View Page");
  BackToTop();

  return (
    <Container>
      <Grid container spacing={2} className='mt-100 mb-80'>
        {/* Page Title */}
        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            className='font-18 color-secondary border-left'> Business Hub {'>'} View Profile</Typography>
        </Grid>
        {/* View data  */}
        <Grid item xs={12}>
          <Paper className='p-20'>
            <Grid container direction='row' spacing={2}>
              <Grid item md={9} xs={12}>
                <Grid container className='color-gray p-5'>
                  <Grid item xs={3} md={2} className='text-center'>
                    <img
                      src={business.logo !== null ? business.logo : process.env.PUBLIC_URL + "/img/other/no-logo.png"}
                      alt={business.companyName}
                      className='mx-w-60 object-cover' />
                    <Typography variant='subtitle2' className='font-10 color-gray'>Company Logo</Typography>
                  </Grid>
                  <Grid item xs={9} md={10}>
                    <Typography
                      variant='h6'
                      className='color-secondary font-bold font-18'>
                      {business.fullName + ", " + fellowMemberNo.find(j => j.id === business.fellowMemberNoType).name + "/" + business.fellowMemberNo}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      className='color-dark font-bold'>
                      {educationals.find(j => j.id === business.eduQualif).name + " in " + departments.find(j => j.id === business.department).name} Engineering
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      className='color-dark font-bold'>
                      {business.designation}, {business.companyName}
                    </Typography>
                  </Grid>
                  <Grid item lg={4}
                    xs={12}
                    className='color-gray'
                    sx={{ borderRight: { lg: '1px solid rgb(0 0 0 / 12%)' } }}>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Contact No:</span> <a href={'tel:' + business.mobile}>{business.mobile}</a>
                    </Typography>
                    <Divider sx={{ borderBottom: { xs: '0px', lg: '1px solid rgb(0 0 0 / 12%)' } }} />
                  </Grid>
                  <Grid item lg={4} xs={12} className='color-gray'
                    sx={{ borderRight: { lg: '1px solid rgb(0 0 0 / 12%)' } }}>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { lg: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Business Concern:</span> {business.origin ?
                        employerOrigins.find(j => j.id === business.origin).name : ''}
                    </Typography>
                    <Divider sx={{ borderBottom: { xs: '0px', lg: '1px solid rgb(0 0 0 / 12%)' } }} />
                  </Grid>
                  <Grid item lg={4} xs={12} className='color-gray'>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { lg: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Email:</span> <a href={'mailto:' + business.email}>{business.email}</a>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Website:</span> <a href={business.website.includes('http') ? business.website : `http://${business.website}`} target='_blank' rel="noopener noreferrer"> {business.website}</a>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Company Main Job/Key Products/ Services:</span> {business.compMainJob}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Company Address:</span> {business.compAddress}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12} className='text-center'>
                <img
                  src={business.photo}
                  alt='ABCD'
                  className='img-fluid border-radious-5 h-240 object-cover' />
              </Grid>
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: business.details }}
                  className='table-border' />

                <div className='text-right mt-10'>
                  {business.attachCompPrfl && <Button
                    className='btn-primary border-radious-25 pr-10 pl-10'
                    size='small'
                    endIcon={<DownloadIcon />}>
                    <a href={business.attachCompPrfl} target="_blank" rel="noreferrer">Company Profile</a>
                  </Button>}
                  <ShareBtn />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ViewBusinessProfile