import { ACTION_TYPES } from "../actions/ieb";

export const initialState = {
  list: [],
  data: {},
  authUser: {},
  response: {},
  admin: {},
  homeData: {},
}

export const ieb = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...state,
        list: [...action.payload.result]
      }
    case ACTION_TYPES.ADD_LIST:
      return {
        ...state,
        list: [action.payload.result, ...state.list]
      }
    case ACTION_TYPES.UPDATE_LIST:
      return {
        ...state,
        list: state.list.map(j => j.id === action.payload.result.id ? action.payload.result : j)
      }
    case ACTION_TYPES.REMOVE_LIST:
      return {
        ...state,
        list: state.list.filter(j => j.id !== action.payload.result.id)
      }
    case ACTION_TYPES.CREATE:
      return {
        ...state,
        data: action.payload
      }
    case ACTION_TYPES.UPDATE:
      return {
        ...state,
        data: action.payload
      }
    case ACTION_TYPES.DELETE:
      return {
        ...state,
        list: [...action.payload],
      }
    case ACTION_TYPES.LOGIN:
      return {
        ...state,
        authUser: action.payload,
      }
    case ACTION_TYPES.ADMIN:
      return {
        ...state,
        admin: action.payload,
      }
    case ACTION_TYPES.RESPONSE:
      return {
        ...state,
        response: action.payload,
      }
    case ACTION_TYPES.HOME:
      return {
        ...state,
        homeData: action.payload,
      }
    default:
      return state;
  }
}