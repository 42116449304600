import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Slide from '../../layout/Slide'
import MultCarousel from '../../layout/MultCarousel'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';

export default function LatestNewsHome({ data }) {
  const navigate = useNavigate();
  const [news, setNews] = useState();

  useEffect(() => {
    data.pages && setNews(data.pages.filter(j => j.pageType === 2).slice(0, 12));
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleView = (item) =>
    navigate(`${item.id}/${item.pageType}`);

  return (
    news && news.length > 0
      ?
      <Container maxWidth='xl'>
        <Grid container spacing={3} className='mt-50 mb-30'>
          <Grid item xs={12} className='pos-relative mb-20'>
            <Typography
              variant='h4'
              className='text-center font-bold font-32 color-secondary bottom-border'>
              Latest News
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MultCarousel {...{
              showDots: true,
              large: 4,
              desktop: 4,
              tablet: 2,
              mobile: 1,
            }}>
              {news.map((item, i) =>
                <Grid key={i} className='p-10 mb-60'>
                  <Slide {...{ autoShow: true, direction: 'left' }}>
                    <Card>
                      <CardMedia
                        component='img'
                        alt="Paella dish"
                        className='h-280 object-cover'
                        image={item.coverPhoto}
                      />
                      <CardContent>
                        <Grid className='d-flex'>
                          <CalendarMonthIcon className='color-gray mr-10 font-20' />
                          <Typography
                            variant='h6'
                            className='font-14 font-bold color-gray'>
                            {item.createDate}
                          </Typography>
                        </Grid>
                        <Typography
                          onClick={() => handleView(item)}
                          variant='h6'
                          className='font-16 font-bold color-secondary mt-20 cursor-pointer hov-primary'>
                          {item.title}
                        </Typography>
                        <Button
                          onClick={() => handleView(item)}
                          variant='container'
                          className='font-16 w-100pr mt-20 btn-primary-outlined'>
                          Read More
                        </Button>
                      </CardContent>
                    </Card>
                  </Slide>
                </Grid>
              )}
            </MultCarousel>
            <Grid item className='text-center'>
              <Slide {...{ autoShow: true, direction: 'right' }}>
                <Button
                  onClick={() => navigate(`pages/2`)}
                  variant='contained'
                  className='btn-primary font-16 pl-40 pr-40'>
                  View All News
                </Button>
              </Slide>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      : <></>
  )
}
