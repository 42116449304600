import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { PageTitle } from '../../../../hooks/Methods';
import { ENDPOINTS, CNTRL_ACTN } from '../../../../actions/api';
import { Alert, Dialog, Table } from '../../../../layout';
import AddEditQuote from './AddEditQuote';

const Quotes = (props) => {
  const { _fetchAll, _list, _delete } = props;
  const [showForm, setShowForm] = useState(false);
  const [updData, setUpdData] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  PageTitle("Quotes");

  const handleEdit = (val) => {
    val !== 'add' && setUpdData(val);
    setShowForm(true);
  }
  const handleDlt = (props) => {
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to delete this Quote?',
      onConfirm: () => {
        const onComplete = (res) => {
          setAlert({
            isOpen: true,
            type: res.status === 200 ? 'success' : 'error',
            title: res.status === 200 ? 'Deleted Successfully' : 'OPPS',
            subTitle: res.msg
          })
        }
        _delete(ENDPOINTS.Admin + CNTRL_ACTN.DeleteQuote, props.id, onComplete, actions.ACTION_TYPES.REMOVE_LIST);
      },
    })
  }

  useEffect(() => {
    _fetchAll(ENDPOINTS.Admin + CNTRL_ACTN.GetQuotes);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Circular List || Table */}
      <Container maxWidth='xl'>
        <Table
          showDownloadBtn={false}
          handleAddEdit={handleEdit}
          handleDlt={handleDlt}
          addBtnTitle='Add Quote'
          tblHead={[
            { id: 'actions', label: 'Actions' },
            { id: 'title', label: 'Title' },
            { id: 'fullName', label: 'Full Name' },
            { id: 'photo', label: 'Photo' },
          ]}
          tblData={_list.length > 0 ? _list.map(obj => {
            return {
              ...obj,
              'photo': <a href={obj.photo} target="_blank" rel="noreferrer" className='hov-primary'>View Photo</a>,
            }
          }) : []} />
      </Container>

      {/* Add and Edit Circular Form  */}
      {showForm && <Dialog
        show={showForm}
        maxWidth='md'>
        <AddEditQuote {...{
          updData,
          setUpdData,
          setShowForm
        }} />
      </Dialog>}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  )
}

const mapStateToProps = state => ({
  _list: state.ieb.list,
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
  _delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(Quotes);