import { Container } from '@mui/material'
import React from 'react'
import Tabs from '../../../layout/Tabs'
import ExamDoc from './ExamDoc';
import Questions from './Questions'
import Books from './Books'
import HandNotes from './HandNotes'

export default function AmieCourse() {

  return (
    <Container maxWidth='xl' className='mt-120 mb-30'>
      <Tabs {...{
        items: [
          { label: 'Books', component: <Books /> },
          { label: 'Questions', component: <Questions /> },
          { label: 'Hand Notes', component: <HandNotes /> },
          { label: 'Exam Documents', component: <ExamDoc /> }
        ]
      }} />
    </Container>
  )
}
