import React, { forwardRef } from 'react'
import {
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  Grow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} timeout={400} />;
});

export default function Dialog(props) {
  const { show, setShow, maxWidth = 'sm', children, onClose = true, fullScreen = false, ...other } = props;
  return (
    <MuiDialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={() => (setShow !== undefined && onClose) && setShow(false)}
      aria-describedby="dialog"
      maxWidth={maxWidth}
      {...other}>
      {setShow !== undefined &&
        <DialogTitle className='text-right p-10'>
          <IconButton
            onClick={() => setShow(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>}
      {children}
    </MuiDialog>
  );
}
