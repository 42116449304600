import React, { useEffect, useState } from 'react';
import ScrollToTop from './controls/ScrollToTop';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'
import ErrorPage from './component/pages/ErrorPage';
import { Grid, ThemeProvider } from '@mui/material';
import EmployeeDash from './component/Dashboard/employee/EmployeeDash';
import Home from './component/home/Home';
import customizeMui from './utils/customizeMui';
import EmployerDash from './component/Dashboard/employer/EmployerDash';
import MemberDash from './component/Dashboard/member/MemberDash';
import StudentDash from './component/Dashboard/student/StudentDash';
import BusinessDash from './component/Dashboard/business/BusinessDash';
import MyAccount from './component/appBar/MyAccount';
import Loader from './controls/Loader';
import { LoaderContext } from './utils/Context';
import Admin from './component/Dashboard/Admin/Admin';
import * as actions from './actions/ieb';
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from './actions/api';

const App = (props) => {
  const { _fetchAll } = props;
  const { theme } = customizeMui();
  const [showLoader, setShowLoader] = useState(false);
  
  useEffect(() => {
    const onComplete = (res, err) => setShowLoader(false);
    _fetchAll(ENDPOINTS.Home + CNTRL_ACTN.GetHomeData, onComplete, actions.ACTION_TYPES.HOME)
    return () => {
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <LoaderContext.Provider value={{ showLoader, setShowLoader }}>
        <Loader show={showLoader} />
        <Grid className='pos-relative'>
          <ScrollToTop />
          <Router>
            <Routes>
              <Route path='/*' element={<Home title='IEB-Alumni' />} />
              <Route path='admin-panel/*' element={<Admin title='Admin Panel' />} />
              <Route path='dashboard/*'>
                <Route path='employee/*' element={<EmployeeDash title='Employee - Dashboard' />} />
                <Route path='employer/*' element={<EmployerDash title='Employer - Dashboard' />} />
                <Route path='member/*' element={<MemberDash title='Member - Dashboard' />} />
                <Route path='student/*' element={<StudentDash title='Member - Dashboard' />} />
                <Route path='business/*' element={<BusinessDash title='Business - Dashboard' />} />
                <Route path='*' element={<ErrorPage />} />
              </Route>
              <Route path='test' element={<MyAccount />} />
            </Routes>
          </Router>
        </Grid>
      </LoaderContext.Provider>

    </ThemeProvider>
  );
}
const mapStateToProps = state => ({
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(App);
