export default function Model() {

  const EmployeeMdl = () => ({
    id: 0,
    fullName: '',
    mobile: '',
    aplOrigin: '',
    email: '',
    password: '',
    confirmPass: '',
    
    education: '',
    expDuration: '',
    prevComp: '',
    computerExp: '',
    department: '',
    lastDegPassYr: '',
    fieldOfInt: '',
    fieldOfExp: '',
    specialQuality: '',
    homeDist: '',
    photo: '',
    attachCV: '',
    photoFile: '',
    attachCVFile: '',
    details: '',
  })
  const EmployerMdl = () => ({
    id: 0,
    fullName: '',
    fellowMemberNo: '',
    fellowMemberNoType: '',
    companyName: '',
    logo: '',
    logoFile: '',
    employerOrigin: '',
    mobile: '',
    email: '',
    details: '',
    password: '',
    confirmPass: '',
  })
  const CircularMdl = () => ({
    id: 0,
    jobTitle: '',
    email: '',
    vacancy: '',
    jobContext: '',
    jobResp: '',
    emplStatus: '',
    education: '',
    origin: '',
    department: '',
    expDuration: '',
    fieldOfExp: '',
    additionalReq: '',
    specialCriteria: '',
    jobLocation: '',
    salary: '',
    otherBenifits: '',
    attachCircular: '',
    attachCircularFile: '',
    employerId: 0,
  })
  const MemberMdl = () => ({
    id: 0,
    fullName: '',
    fellowMemberNo: '',
    fellowMemberNoType: '',
    department: '',
    bloodGroup: '',
    mobile: '',
    email: '',
    educational: '',
    gradYear: '',
    expDuration: '',
    fieldOfExp: '',
    presLastOrg: '',
    presLastDes: '',
    homeDist: '',
    photo: '',
    photoFile: '',
    details: '',
    password: '',
    confirmPass: ''
  })
  const StudentMdl = () => ({
    id: 0,
    fullName: '',
    stuNo: '',
    department: '',
    bloodGroup: '',
    mobile: '',
    email: '',
    eduBg: '',
    part: '',
    expDuration: '',
    fieldOfExp: '',
    presentOrg: '',
    presentDes: '',
    homeDist: '',
    photo: '',
    photoFile: '',
    details: '',
    password: '',
    confirmPass: ''
  })
  const BusinessMdl = () => ({
    id: 0,
    fullName: '',
    fellowMemberNo: '',
    fellowMemberNoType: '',
    eduQualif: '',
    department: '',
    companyName: '',
    designation: '',
    mobile: '',
    email: '',
    compMainJob: '',
    website: '',
    origin: '',
    compAddress: '',
    photo: '',
    photoFile: '',
    logo: '',
    logoFile: '',
    attachCompPrfl: '',
    attachCompPrflFile: '',
    details: '',
    password: '',
    confirmPass: ''
  })
  const PageMdl = () => ({
    id: 0,
    title: '',
    coverPhoto: '',
    description: '',
    createDate: '',
    pageType: '',
    photoFile: '',
  })
  const QuoteMdl = () => ({
    id: 0,
    title: '',
    fullName: '',
    photo: '',
    photoFile: '',
    quotes: '',
  })
  const SliderMdl = () => ({
    id: 0,
    imgType: '',
    title: '',
    link: '',
    photo: '',
    photoFile: '',
    shortDes: '',
  })
  const AdminMdl = () => ({
    id: 0,
    fullName: '',
    email: '',
    password: '',
    confirmPass: '',
  })
  const LoginMdl = () => ({
    email: '',
    password: '',
    acctType: '',
  })
  const ForgotMdl = () => ({
    email: '',
    acctType: '',
    forgotStep: '',
    verificationCode: '',
    password: '',
    confirmPass: ''
  })
  const ContactUs = () => ({
    fullName: '',
    email: '',
    msg: '',
  })
  const ChangePassMdl = () => ({
    currentPass: '',
    password: '',
    confirmPass: ''
  })
  const SiteMdl = () => ({
    id: 0,
    siteName: '',
    headerLogo: '',
    footerLogo: '',
    email: '',
    phone: '',
    fax: '',
    address: '',
    title: '',
    shortDes: '',
    facebook: '',
    linkIn: '',
    instagram: '',
    twitter: '',
    headerLogoFile: '',
    footerLogoFile: '',
  })
  return {
    EmployeeMdl,
    EmployerMdl,
    CircularMdl,
    MemberMdl,
    StudentMdl,
    BusinessMdl,
    LoginMdl,
    ForgotMdl,
    ChangePassMdl,
    AdminMdl,
    PageMdl,
    SliderMdl,
    QuoteMdl,
    ContactUs,
    SiteMdl
  }
}
