import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';
import * as actions from '../../../actions/ieb';
import { LoaderContext } from '../../../utils/Context';
import AdminDash from './AdminDash';
import AdminLogin from './AdminLogin';
import AddOrEdit from './AddOrEdit';

const Admin = (props) => {
  const { _fetch, _admin } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { setShowLoader } = useContext(LoaderContext);

  useEffect(() => {
    setShowLoader(true);
    const onComplete = (res, err) => {
      if (res.status === 200) setActiveStep(1);
      else if (res.status === 204) setActiveStep(3);
      else setActiveStep(2);
      setShowLoader(false);
    }
    _fetch(ENDPOINTS.Admin + CNTRL_ACTN.CheckAdmin, onComplete, actions.ACTION_TYPES.ADMIN);
    return () => {
      setActiveStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (_admin.result !== null && _admin.result !== undefined && _admin.result.id !== undefined) setActiveStep(1);
    return () => {
    }
  }, [_admin.result])

  const Content = (step) => {
    switch (step) {
      case 1:
        return (
          <AdminDash {...{
            setActiveStep
          }} />
        )
      case 2:
        return (
          <AdminLogin />
        )
      case 3:
        return (
          <AddOrEdit {...{
            setActiveStep
          }} />
        )
      default:
        break;
    }
  }

  return (
    <>
      {Content(activeStep)}
    </>
  )
}

const mapStateToProps = state => ({
  _admin: state.ieb.admin,
  _data: state.ieb.data,
  _response: state.ieb.response
})

const mapActionToProps = {
  _create: actions.create,
  _fetchByObj: actions.fetchByObj,
  _fetch: actions.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(Admin);