import React from 'react';
import { Grid, Typography } from '@mui/material';
// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function PassValidation(props) {
   const { passValidation, password } = props;
   return (
      (password !== "" && !passValidation.passValid) && <>
         <Grid
            container
            alignItems="center"
            className="mt-10">
            <Grid>
               {passValidation.charNumberValid
                  ? <CheckCircleIcon className="color-success" />
                  : <CancelIcon className="color-danger" />}
            </Grid>
            <Grid>
               <Typography variant="subtitle1"> &nbsp; 6-20 characters</Typography>
            </Grid>
         </Grid>
         <Grid
            container
            alignItems="center"
            className="mt-10">
            <Grid>
               {passValidation.specialCharValid
                  ? <CheckCircleIcon className="color-success" />
                  : <CancelIcon className="color-danger" />}
            </Grid>
            <Grid>
               <Typography variant="subtitle1"> &nbsp; 1 special character</Typography>
            </Grid>
         </Grid>
         <Grid
            container
            alignItems="center"
            className="mt-10">
            <Grid>
               {passValidation.uppercaseValid
                  ? <CheckCircleIcon className="color-success" />
                  : <CancelIcon className="color-danger" />}
            </Grid>
            <Grid>
               <Typography variant="subtitle1"> &nbsp; 1 uppercase letter</Typography>
            </Grid>
         </Grid>
         <Grid
            container
            alignItems="center"
            className="mt-10">
            <Grid>
               {passValidation.numberValid
                  ? <CheckCircleIcon className="color-success" />
                  : <CancelIcon className="color-danger" />}
            </Grid>
            <Grid>
               <Typography variant="subtitle1"> &nbsp; 1 number</Typography>
            </Grid>
         </Grid>
      </>
   )
}
