import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import { Alert, Dialog, Table } from '../../../layout'
import AddEditCircular from './AddEditCircular';
import { PageTitle } from '../../../hooks/Methods';
import { connect } from 'react-redux';
import * as actions from '../../../actions/ieb';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';
import { DropLi } from '../../../hooks';

const EmployerCircular = (props) => {
  const { title, _fetchAll, _list, _delete } = props;
  const { departments, educations, emplStatus, experiences, employerOrigins } = DropLi();
  const [showForm, setShowForm] = useState(false);
  const [circular, setCircular] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  })

  PageTitle(title);

  const handleEdit = (props) => {
    setCircular(props.id && props);
    setShowForm(true);
  }
  const handleDlt = (props) => {
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to delete this record?',
      onConfirm: () => {
        if (props.id !== undefined && props.id !== 0) {
          const onComplete = (res) => {
            setAlert({
              isOpen: true,
              type: res.status === 200 ? 'success' : 'error',
              title: res.status === 200 ? 'Deleted Successfully' : 'OPPS',
              subTitle: res.msg
            })
          }
          _delete(ENDPOINTS.Employer + CNTRL_ACTN.DeleteCircular, props.id, onComplete, actions.ACTION_TYPES.REMOVE_LIST);
        }
      },
    })
  }

  useEffect(() => {
    _fetchAll(ENDPOINTS.Employer + CNTRL_ACTN.GetAuthUserCirculars);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Circular List || Table */}
      <Container maxWidth='xl'>
        <Table
          showDownloadBtn={false}
          handleAddEdit={handleEdit}
          handleDlt={handleDlt}
          addBtnTitle='Add Circular'
          tblHead={[
            { id: 'actions', label: 'Actions' },
            { id: 'jobTitle', label: 'Job Title' },
            { id: 'vacancy', label: 'Vacancy' },
            { id: 'jobContext', label: 'Job Context' },
            { id: 'jobResp', label: 'Job Responsibility' },
            { id: 'origin', label: 'Origin' },
            { id: 'emplStatus', label: 'Employment Status' },
            { id: 'department', label: 'Department' },
            { id: 'expDuration', label: 'Experience' },
            { id: 'fieldOfExp', label: 'Field of Experience' },
            { id: 'additionalReq', label: 'Additional Requirements' },
            { id: 'specialCriteria', label: 'Special Criteria' },
            { id: 'jobLocation', label: 'Job Location' },
            { id: 'salary', label: 'Salary' },
            { id: 'otherBenifits', label: 'Compensation & Other Benefits' },
            { id: 'attachCircular', label: 'Circular Attachment' },
          ]}
          tblData={(_list.length > 0 && _list[0].origin !== undefined) ? _list.map(obj => {
            return {
              ...obj,
              'department': obj.department !== 0 && departments.find(j => j.id === obj.department).name,
              'education': obj.education !== 0 && educations.find(j => j.id === obj.education).name,
              'emplStatus': obj.emplStatus !== 0 && emplStatus.find(j => j.id === obj.emplStatus).name,
              'fieldOfExp': obj.fieldOfExp !== 0 && experiences.find(j => j.id === obj.fieldOfExp).name,
              'origin': obj.origin !== 0 && employerOrigins.find(j => j.id === obj.origin).name,
              'expDuration': obj.expDuration + ' year',
              'attachCircular': <a href={obj.attachCircular} target="_blank" rel="noreferrer" className='hov-primary'>View Attachment</a>
            }
          }) : []} />
      </Container>

      {/* Add and Edit Circular Form  */}
      {showForm && <Dialog
        show={showForm}
        maxWidth='md'>
        <AddEditCircular {...{
          setShowForm,
          circular,
          setCircular,
        }} />
      </Dialog>}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  )
}

const mapStateToProps = state => ({
  _list: state.ieb.list,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
  _delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(EmployerCircular);