import React from 'react'
import { PageTitle } from '../../../hooks/Methods';
import ChangePassComp from '../ChangePassComp'

export default function BusinessChangePass(props) {
  const {title} = props;
  PageTitle(title);
  return (
    <ChangePassComp />
  )
}
