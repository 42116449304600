import { Typography } from '@mui/material';
import JoditEditor from 'jodit-react'
import React from 'react'



const btnOpts = "fullsize,bold,italic,underline,strikethrough,eraser,ul,ol,fontsize,paragraph,lineHeight,superscript,subscript,selectall,hr,table,indent,outdent,align";
const simpleConfig = {
  useSearch: false,
  spellcheck: true,
  enter: "P",
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPaste: "insert_only_text",
  minHeight: 250,
  buttons: btnOpts,
  buttonsMD: btnOpts,
  buttonsSM: btnOpts,
  buttonsXS: btnOpts,
}

const config = {
  uploader: {
    insertImageAsBase64URI: true
  },
  minHeight: 450,
}

const InputRich = (props) => {
  const { type = 'all', name, label, value, onChange, error = null, ...other } = props;
  return (
    <>
      <Typography variant="subtitle1" className='color-gray'>{label}</Typography>
      <JoditEditor
        config={type === 'all' ? config : simpleConfig}
        name={name}
        value={value}
        onChange={onChange}
        {...other}
      />
      {error && <Typography className='color-danger'>{error}</Typography>}
    </>
  )
}



export default InputRich