import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useContext, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CNTRL_ACTN, ENDPOINTS } from '../../actions/api';
import * as actions from '../../actions/ieb';
import { Input } from '../../controls';
import { Model, UseForm } from '../../hooks';
import { PageTitle } from '../../hooks/Methods';
import { Alert, Form } from '../../layout';
import { LoaderContext } from '../../utils/Context';

const ContactUs = (props) => {
  const { _postReq } = props;
  const { ContactUs } = Model();
  const { setShowLoader } = useContext(LoaderContext)
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    toFormData,
  } = UseForm(ContactUs);

  PageTitle('Contact Us')

  const validateForm = () => {
    let temp = {};
    temp.fullName = values.fullName !== "" ? "" : "Full name is required."
    temp.email = values.email !== "" ? ((/^$|.+@.+..+/).test(values.email) ? "" : "Email is not valid.") : "Email is required."
    temp.msg = values.msg !== "" ? "" : "Message is required."
    setErrors({ ...temp })
    return Object.values(temp).every(x => x === "");
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        if (res.status === 200) {
          setAlert({
            isOpen: true,
            type: 'success',
            title: 'Awesome!',
            subTitle: res.msg,
            onClick: () => navigate('/')
          })
        }
      }
      _postReq(ENDPOINTS.Home + CNTRL_ACTN.ContactUs, toFormData(values), onComplete)
    }
  }

  return (
    <>
      <Alert alert={alert} setAlert={setAlert} />
      <Grid className='d-flex justify-center align-center h-100vh p-10'>
        <Paper className='mx-w-700'>
          <Form noValidate
            onSubmit={submitForm}>
            <Grid container spacing={3} className='pl-20 pr-20 pt-30 pb-30' >
              <Grid item xs={12} className='text-center mb-30 pos-relative'>
                <Typography variant='p' className='bottom-border color-dark font-30'>
                  Contact Us
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Full Name"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleInputChange}
                  error={errors.fullName}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Message"
                  name="msg"
                  value={values.msg}
                  onChange={handleInputChange}
                  error={errors.msg}
                  multiline
                  rows={4}
                  className='input-primary w-100pr'
                />
              </Grid>
              <Grid item xs={12} className='text-right'>
                <Button
                  type='submit'
                  variant='contained'
                  size='large'
                  className='btn-primary w-100'>
                  Send
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
})

const mapActionToProps = {
  _postReq: actions.create,
}

export default connect(mapStateToProps, mapActionToProps)(ContactUs);
