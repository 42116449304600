import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { DropLi } from '../../../../hooks';
import { JsonToExcel, PageTitle } from '../../../../hooks/Methods';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import { Alert, Table } from '../../../../layout';

const Circular = (props) => {
  const { _fetchAll, _list, _delete } = props;
  const {
    departments,
    educations,
    emplStatus,
    experiences,
    employerOrigins } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  })

  PageTitle('Circular');

  const handleExcel = (data) => {
    const result = data.map(({ attachCircularFile, employer, ...rest }) => ({
      ...rest,
      attachCircular: rest.attachCircular.props.href,
    }));
    JsonToExcel(result.map(j => {
      delete Object.assign(j, { 'Id': j.id })['id'];
      delete Object.assign(j, { 'Employer Id': j.employerId })['employerId'];
      delete Object.assign(j, { 'Job Title': j.jobTitle })['jobTitle'];
      delete Object.assign(j, { 'Company Name': j.companyName })['companyName'];
      delete Object.assign(j, { 'Vacancy': j.vacancy })['vacancy'];
      delete Object.assign(j, { 'Job Context': j.jobContext })['jobContext'];
      delete Object.assign(j, { 'Job Responsibility': j.jobResp })['jobResp'];
      delete Object.assign(j, { 'Employment Status': j.emplStatus })['emplStatus'];
      delete Object.assign(j, { 'Education': j.education })['education'];
      delete Object.assign(j, { 'Origin': j.origin })['origin'];
      delete Object.assign(j, { 'Department': j.department })['department'];
      delete Object.assign(j, { 'Experience': j.expDuration })['expDuration'];
      delete Object.assign(j, { 'Field of Experience': j.fieldOfExp })['fieldOfExp'];
      delete Object.assign(j, { 'Additional Requirements': j.additionalReq })['additionalReq'];
      delete Object.assign(j, { 'Special Criteria': j.specialCriteria })['specialCriteria'];
      delete Object.assign(j, { 'Job Location': j.jobLocation })['jobLocation'];
      delete Object.assign(j, { 'Salary': j.salary })['salary'];
      delete Object.assign(j, { 'Compensation & Other Benefits': j.otherBenifits })['otherBenifits'];
      delete Object.assign(j, { 'Circular': j.attachCircular })['attachCircular'];
      return j;
    }), 'Circular');
  }

  const handleDlt = (props) => {
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to delete this record?',
      onConfirm: () => {
        if (props.id !== undefined && props.id !== 0) {
          const onComplete = (res) => {
            setAlert({
              isOpen: true,
              type: res.status === 200 ? 'success' : 'error',
              title: res.status === 200 ? 'Deleted Successfully' : 'OPPS',
              subTitle: res.msg
            })
          }
          _delete(ENDPOINTS.Employer + CNTRL_ACTN.DeleteCircular, props.id, onComplete, actions.ACTION_TYPES.REMOVE_LIST);
        }
      },
    })
  }

  useEffect(() => {
    _fetchAll(ENDPOINTS.Employer + CNTRL_ACTN.GetCirculars);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Circular List || Table */}
      <Container maxWidth='xl'>
        <Table
          showDownloadBtn={true}
          showEdit={false}
          showAddBtn={false}
          handleExcel={handleExcel}
          handleDlt={handleDlt}
          tblHead={[
            { id: 'actions', label: 'Actions' },
            { id: 'id', label: 'Id' },
            { id: 'jobTitle', label: 'Job Title' },
            { id: 'companyName', label: 'Company Name' },
            { id: 'vacancy', label: 'Vacancy' },
            { id: 'jobContext', label: 'Job Context' },
            { id: 'jobResp', label: 'Job Responsibility' },
            { id: 'origin', label: 'Origin' },
            { id: 'emplStatus', label: 'Employment Status' },
            { id: 'department', label: 'Department' },
            { id: 'expDuration', label: 'Experience' },
            { id: 'fieldOfExp', label: 'Field of Experience' },
            { id: 'additionalReq', label: 'Additional Requirements' },
            { id: 'specialCriteria', label: 'Cpecial Criteria' },
            { id: 'jobLocation', label: 'Job Location' },
            { id: 'salary', label: 'Salary' },
            { id: 'otherBenifits', label: 'Compensation & Other Benefits' },
            { id: 'attachCircular', label: 'Circular Attachment' },
          ]}
          tblData={(_list.length > 0 && _list[0].emplStatus !== undefined) ? _list.map(obj => {
            return {
              ...obj,
              'department': obj.department === 0 ? "" : departments.find(j => j.id === obj.department).name,
              'education': obj.education === 0 ? "" : educations.find(j => j.id === obj.education).name,
              'emplStatus': obj.emplStatus === 0 ? "" : emplStatus.find(j => j.id === obj.emplStatus).name,
              'fieldOfExp': obj.fieldOfExp === 0 ? "" : experiences.find(j => j.id === obj.fieldOfExp).name,
              'origin': obj.origin === 0 ? "" : employerOrigins.find(j => j.id === obj.origin).name,
              'companyName': obj.employer.companyName,
              'expDuration': obj.expDuration + ' year',
              'attachCircular': <a href={obj.attachCircular} target="_blank" rel="noreferrer" className='hov-primary'>View Attachment</a>
            }
          }) : []} />
      </Container>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  )
}

const mapStateToProps = state => ({
  _list: state.ieb.list,
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
  _delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(Circular);