import React, { useState } from 'react';
import { Slide as MuiSlide } from '@mui/material';
import { InView } from 'react-intersection-observer';

export default function Slide(props) {
  const {
    timeout = 900,
    direction = 'up',
    show = true,
    children,
    autoShow = false,
    ...other } = props;

  const [inViewport, setInViewport] = useState(false);

  const MainComp = () => {
    return <MuiSlide
      direction={direction}
      in={autoShow ? inViewport : show}
      timeout={timeout}
      {...other}
      mountOnEnter
      unmountOnExit>
      {children}
    </MuiSlide>
  }

  return (
    autoShow ? <InView as="div"
      triggerOnce={true}
      onChange={(inView) => setInViewport(inView)}>
      <MainComp />
    </InView> : <MainComp />
  )
}
