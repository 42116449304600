import React from 'react'

export default function Loader(props) {
  const { show = false } = props;

  return (
    <div className={show ? 'd-block' : 'd-none'}>
      <div className='pos-fixed z-index-full top-0 left-0 right-0 bottom-0 align-center justify-center d-flex bg-dark-trans'>
        <div className="loadingio-spinner-dual-ball-xjci8aerbe">
          <div className="ldio-9r47h8a95fh">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
