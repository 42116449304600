import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
} from '@mui/material';
import LogoComp from './LogoComp';
import MenusComp from './MenusComp';
import TopNav from './TopNav';
import { InView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';

const defaultNavCls = () => ({
  nav: {
    backgroundColor: 'rgba(0, 33, 71, 0.5)',
    mt: '45px',
    padding: '10px 0px 10px 0px',
  },
  txt: {
    color: '#fff',
    fontSize: '16px',
    mr: 2
  },
  menuIcon: {
    color: '#fff'
  }
})

const Nav = () => {
  const location = useLocation();

  const [bgIsTrans, setBgIsTrans] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState();
  // const [searchAncr, setSearchAncr] = useState();
  const [navCls, setNavCls] = useState(defaultNavCls);
  const [showFixedNav, setShowFixedNav] = useState(false);

  useEffect(() => {
    location.pathname === '/' ? setBgIsTrans(true) : setBgIsTrans(false);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleShowMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElNav(null);
  }

  useEffect(() => {
    if (!showFixedNav || !bgIsTrans) {
      setNavCls({
        nav: {
          background: 'rgba(255, 255, 255, 0.6)',
          backdropFilter: 'blur(20px)',
          padding: '10px 0px 10px 0px',
          mt: `${showFixedNav ? '45' : '0'}px`
        },
        txt: {
          color: '#000',
          fontSize: '16px',
          mr: 2
        },
        menuIcon: {
          color: '#ffb606'
        }
      })
    } else setNavCls(defaultNavCls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFixedNav, bgIsTrans])

  return (
    <>
      {/* Top Navbar  */}
      <InView as='div' onChange={(inView) => setShowFixedNav(inView)}>
        <TopNav />
      </InView>

      {/* Navbar */}
      <AppBar
        elevation={2}
        sx={navCls !== undefined ? navCls.nav : {}}
        className='z-index'>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* show on desktop */}
            <LogoComp {...{ mr: 2, display: { xs: 'none', md: 'none', lg: 'flex' } }} />
            <MenusComp {...{
              handleShowMenu,
              handleCloseMenu,
              anchorElNav,
              setAnchorElNav,
              sxProps: navCls !== undefined ? navCls : {},
            }} />

            {/* shwo on mobile  */}
            <LogoComp {...{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'none' } }} />
            <MenusComp {...{
              handleShowMenu,
              handleCloseMenu,
              anchorElNav,
              showOn: 'mobile',
              sxProps: navCls !== undefined ? navCls : {}
            }} />

            {/* <Box sx={{ flexGrow: 0 }}>
              <IconButton
                className='btn-hov-sec'
                sx={navCls !== useEffect ? navCls.txt : {}}
                onClick={handleSearchPopup}>
                <SearchIcon color="inherit" />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={searchAncr}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(searchAncr)}
                onClose={() => setSearchAncr(null)}>
                <SearchBox />
              </Menu>
            </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default Nav;
