import { createTheme } from '@mui/material/styles';

export default function customizeMui() {
  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#ffb606',
        darker: '#053e85',
        contrastText: '#fff',
      },
      secondary: {
        main: '#002147',
        darker: '#053e85',
        contrastText: '#fff',
      },
    },
  });
  return {
    theme
  }
}