import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemIcon
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Slide from '../../layout/Slide'
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function Events({ data }) {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    if (data.pages) {
      setEvents(data.pages.filter(j => j.pageType === 3).slice(0, 3));
      setNotices(data.pages.filter(j => j.pageType === 4).slice(0, 5));
    }
    return () => {
      setEvents([]);
      setNotices([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleView = (item) => navigate(`${item.id}/${item.pageType}`);

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3} className='mt-50 mb-30'>
        {/* ====Events==== */}
        {(events && events.length > 0) &&
          <Grid item xs={12} md={8} container spacing={2}>
            <Grid item xs={12} className='pos-relative mb-40'>
              <Typography
                variant='h4'
                className='text-center font-bold font-32 color-secondary bottom-border'>
                Events
              </Typography>
            </Grid>
            {events.map((item, i) =>
              <Grid key={i} item xs={12} md={4}>
                <Slide {...{ autoShow: true, direction: 'right' }}>
                  <Card>
                    <CardMedia
                      component='img'
                      alt="Paella dish"
                      className='h-280 object-cover'
                      image={item.coverPhoto}
                    />
                    <CardContent>
                      <Grid className='d-flex'>
                        <CalendarMonthIcon className='color-gray mr-10 font-20' />
                        <Typography
                          variant='h6'
                          className='font-14 font-bold color-gray'>
                          {item.createDate}
                        </Typography>
                      </Grid>
                      <Typography
                        onClick={() => handleView(item)}
                        variant='h6'
                        className='font-16 font-bold color-secondary mt-20 hov-primary cursor-pointer'>
                        {item.title}
                      </Typography>
                      <Button
                        onClick={() => handleView(item)}
                        variant='container'
                        className='font-16 w-100pr mt-20 btn-primary-outlined border-radious-30'>
                        Read More
                      </Button>
                    </CardContent>
                  </Card>
                </Slide>
              </Grid>
            )}
            <Grid item xs={12} className='text-center mt-20'>
              <Slide {...{ autoShow: true, direction: 'right' }}>
                <Button
                  onClick={() => navigate('pages/3')}
                  variant='contained'
                  className='btn-primary font-16 pl-40 pr-40 border-radious-30'>
                  View All Events
                </Button>
              </Slide>
            </Grid>
          </Grid>
        }
        <Grid item xs={12} md={4}>
          <Grid item xs={12} className='pos-relative mb-40'>
            <Typography
              variant='h4'
              className='text-center font-bold font-32 color-secondary bottom-border'>
              Notices
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Slide {...{ autoShow: true, direction: 'left' }}>
              <Paper sx={{
                height: '480px',
                overflow: 'auto'
              }}>
                <List disablePadding>
                  {notices.map((item, i) =>
                    <div key={i}>
                      <ListItemButton onClick={() => handleView(item)}>
                        <ListItemIcon>
                          <EventNoteIcon className='font-34' />
                        </ListItemIcon>
                        <ListItemText
                          className='color-secondary'
                          primary={item.title}
                          secondary={`Published: ${item.createDate}`} />
                      </ListItemButton>
                      <Divider />
                    </div>
                  )}
                </List>
              </Paper>
            </Slide>
          </Grid>
          <Grid item xs={12} className='text-center mt-30'>
            <Slide {...{ autoShow: true, direction: 'left' }}>
              <Button
                onClick={() => navigate('pages/4')}
                variant='contained'
                className='btn-primary font-16 pl-40 pr-40 border-radious-30'>
                View All Notices
              </Button>
            </Slide>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
