import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CstmMenu from '../../layout/CstmMenu';
import ListSubmenu from '../../layout/ListSubmenu';
import { useNavigate } from 'react-router-dom'
import MyAccount from './MyAccount';
import Login from '../account/login/Login';
import { connect } from 'react-redux';

const MenusComp = (props) => {
  const {
    handleShowMenu,
    handleCloseMenu,
    anchorElNav,
    showOn = 'desktop',
    sxProps,
    _authUser } = props;

  const [showMyAcc, setShowMyAcc] = useState(false);
  const navigate = useNavigate();
  const scrollFun = () => {
    var scrollVal = document.documentElement.scrollTop;
    if (scrollVal < 20) setShowMyAcc(false);
    else if (scrollVal > 20) setShowMyAcc(true);
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollFun);
    return () => {
      window.removeEventListener("scroll", scrollFun);
    };
  }, [])

  const handleMenu = (step) => {
    switch (step) {
      case 'home':
        navigate('/');
        break;
      case 'membersAlumni':
        navigate('/members/alumni');
        break;
      case 'membersStudents':
        navigate('/members/students');
        break;
      case 'jobSeekers':
        navigate('/job/seekers');
        break;
      case 'jobCircular':
        navigate('/job/circular');
        break;
      case 'businesshub':
        navigate('/businesshub');
        break;
      case 'amieCourse':
        navigate('/archive/amieCourse');
        break;
      case 'gallery':
        navigate('/archive/gallery');
        break;
      case 'alumniArch':
        navigate('/archive/alumni');
        break;
      case 'alumniCmt':
        navigate('/committe/alumni');
        break;
      case 'studentsCmt':
        navigate('/committe/students');
        break;
      case 'aboutUs':
        navigate('/aboutUs');
        break;
      case 'contactUs':
        navigate('/contactUs');
        break;
      case 'events':
        navigate('/pages/3');
        break;
      default:
        navigate('/');
        break;
    }
    handleCloseMenu();
  }

  return (
    showOn === 'mobile'
      ? //Mobile Menu
      <>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'flex', md: 'flex', lg: 'none' },
            justifyContent: 'right'
          }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleShowMenu}>
            {!Boolean(anchorElNav) ? <MenuRoundedIcon sx={sxProps.menuIcon} /> : <CloseRoundedIcon sx={sxProps.menuIcon} />}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}>
            <List
              disablePadding
              component='nav'
              sx={{
                width: '100%',
                minWidth: '320px',
                maxHeight: 'calc(100vh - 150px)',
                overflowY: 'auto',
                textTransform: 'uppercase',
              }}>
              <ListItemButton onClick={() => handleMenu('aboutUs')}>
                <ListItemText
                  primary='About Us' />
              </ListItemButton>
              <Divider className='m-0' />
              <ListSubmenu {...{ title: 'Members' }}>
                <ListItemButton onClick={() => handleMenu('membersAlumni')} >
                  <ListItemText primary='Alumni' />
                </ListItemButton>
                <Divider className='m-0' />
                <ListItemButton onClick={() => handleMenu('membersStudents')}>
                  <ListItemText primary='Students' />
                </ListItemButton>
              </ListSubmenu>
              <Divider className='m-0' />
              <ListSubmenu {...{ title: 'Job Corner' }}>
                <ListItemButton onClick={() => handleMenu('jobSeekers')}>
                  <ListItemText primary='Job Seekers' />
                </ListItemButton>
                <Divider className='m-0' />
                <ListItemButton onClick={() => handleMenu('jobCircular')}>
                  <ListItemText primary='Job Circular' />
                </ListItemButton>
              </ListSubmenu>
              <Divider className='m-0' />
              <ListSubmenu {...{ title: 'Committee' }}>
                <ListItemButton onClick={() => handleMenu('alumniCmt')}>
                  <ListItemText primary='Alumni' />
                </ListItemButton>
                <Divider className='m-0' />
                <ListItemButton onClick={() => handleMenu('studentsCmt')}>
                  <ListItemText primary='Students' />
                </ListItemButton>
              </ListSubmenu>
              <Divider className='m-0' />
              <ListItemButton onClick={() => handleMenu('businesshub')}>
                <ListItemText
                  primary='Business Hub' />
              </ListItemButton>
              <Divider className='m-0' />
              <ListSubmenu {...{ title: 'archive' }}>
                <ListItemButton onClick={() => handleMenu('alumniArch')}>
                  <ListItemText primary='Alumni' />
                </ListItemButton>
                <Divider className='m-0' />
                <ListItemButton onClick={() => handleMenu('amieCourse')}>
                  <ListItemText primary='AMIE Course' />
                </ListItemButton>
                <Divider className='m-0' />
                <ListItemButton onClick={() => handleMenu('gallery')}>
                  <ListItemText primary='Gallery' />
                </ListItemButton>
              </ListSubmenu>
              <Divider className='m-0' />
              <ListItemButton onClick={() => handleMenu('contactUs')}>
                <ListItemText
                  primary='Contact Us' />
              </ListItemButton>
              <Divider className='m-0' />
              {(showMyAcc && (_authUser.result === undefined || _authUser.result.id === undefined)) &&
                <>
                  <Login
                    showIcon={false}
                    onShowFun={handleCloseMenu}
                    className='font-18 color-black ml-10 min-w-300 justify-start' />
                  <Divider className='m-0' />
                  <ListSubmenu {...{ title: 'Sign Up' }}>
                    <MyAccount onClick={handleCloseMenu} />
                  </ListSubmenu>
                </>}
            </List>
          </Menu>
        </Box>
      </>
      : // Desktop Menu
      <>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' } }}>
          <Button
            className='btn-hov-sec'
            onClick={() => handleMenu('aboutUs')}
            sx={sxProps.txt}>
            About Us
          </Button>
          <CstmMenu {...{
            btnTitle: 'Members',
            sxProps: sxProps.txt
          }}>
            <MenuItem onClick={() => handleMenu('membersAlumni')} className='btn-hov-sec' >
              <Typography textAlign="center" className='font-18'>Alumni</Typography>
            </MenuItem>
            <Divider className='m-0' />
            <MenuItem onClick={() => handleMenu('membersStudents')} className='btn-hov-sec'>
              <Typography textAlign="center" className='font-18'>Students</Typography>
            </MenuItem>
          </CstmMenu>
          <CstmMenu {...{
            btnTitle: 'Job Corner',
            sxProps: sxProps.txt
          }}>
            <MenuItem onClick={() => handleMenu('jobSeekers')} className='btn-hov-sec'>
              <Typography textAlign="center" className='font-18'>Job Seekers</Typography>
            </MenuItem>
            <Divider className='m-0' />
            <MenuItem onClick={() => handleMenu('jobCircular')} className='btn-hov-sec'>
              <Typography textAlign="center" className='font-18'>Job Circular</Typography>
            </MenuItem>
          </CstmMenu>
          <CstmMenu {...{
            btnTitle: 'Committee',
            sxProps: sxProps.txt
          }}>
            <MenuItem onClick={() => handleMenu('alumniCmt')} className='btn-hov-sec'>
              <Typography textAlign="center" className='font-18'>Alumni</Typography>
            </MenuItem>
            <Divider className='m-0' />
            <MenuItem onClick={() => handleMenu('studentsCmt')} className='btn-hov-sec'>
              <Typography textAlign="center" className='font-18'>Students</Typography>
            </MenuItem>
          </CstmMenu>
          <Button
            className='btn-hov-sec'
            onClick={() => handleMenu('businesshub')}
            sx={sxProps.txt}>
            Business Hub
          </Button>
          <CstmMenu {...{
            btnTitle: 'archive',
            sxProps: sxProps.txt
          }}>
            <MenuItem onClick={() => handleMenu('alumniArch')} className='btn-hov-sec' >
              <Typography textAlign="center" className='font-18'>Alumni</Typography>
            </MenuItem>
            <Divider className='m-0' />
            <MenuItem onClick={() => handleMenu('amieCourse')} className='btn-hov-sec'>
              <Typography textAlign="center" className='font-18'>AMIE Course</Typography>
            </MenuItem>
            <Divider className='m-0' />
            <MenuItem onClick={() => handleMenu('gallery')} className='btn-hov-sec'>
              <Typography textAlign="center" className='font-18'>Gallery</Typography>
            </MenuItem>
          </CstmMenu>
          <Button
            className='btn-hov-sec'
            onClick={() => handleMenu('contactUs')}
            sx={sxProps.txt}>
            Contact Us
          </Button>
          {(showMyAcc && (_authUser.result === undefined || _authUser.result.id === undefined)) &&
            <>
              <Login
                showIcon={false}
                className='btn-hov-sec font-16 color-black' />
              <CstmMenu {...{
                btnTitle: 'Sign Up',
                sxProps: sxProps.txt
              }}>
                <MyAccount />
              </CstmMenu>
            </>}
        </Box>
      </>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(MenusComp);