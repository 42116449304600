import { Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import React from 'react'
import { connect } from 'react-redux';

const LogoComp = (props) => {
  const {
    display,
    flexGrow = 0,
    mr = 0,
    _home } = props;
  return (
    <Typography
 
      noWrap
      component="div"
      className="color-black"
      sx={{ flexGrow: flexGrow, mr: mr, display: display }}>
      <Link to="/">
        <img
          alt="Logo"
          className='h-50 obj-contain mr-20'
          src={(_home.result && _home.result.site) ? _home.result.site.headerLogo : process.env.PUBLIC_URL + "img/site/logo.png"} />
      </Link>
    </Typography>
  )
}

const mapStateToProps = state => ({
  _home: state.ieb.homeData
})

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(LogoComp);