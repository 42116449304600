import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'

import React from 'react'
import Slide from '../../layout/Slide'

export default function ViceChancellor({ data }) {
  return (
    <Container maxWidth='xl'>
      {data.qoutes !== undefined && data.qoutes.length === 2 && <Grid container spacing={5} className='mt-20'>
        {/* image part */}
        <Grid item xs={12} md={3}>
          <Slide {...{ autoShow: true, direction: 'right' }}>
            <Card>
              <CardMedia
                component='img'
                image={data.qoutes[0].photo} />
              <CardContent className='text-center'>
                <Typography
                  gutterBottom
                  variant='h6'
                  className='font-bold font-18 mb-0'>
                  {data.qoutes[0].fullName}
                </Typography>
              </CardContent>
            </Card>
          </Slide>
        </Grid>
        {/* text part */}
        < Grid item xs={12} md={9} >
          <Slide {...{ autoShow: true, direction: 'left' }}>
            <Paper elevation={2} className='p-20'>
              <Typography variant='h5' className='font-bold color-gray'>{data.qoutes[0].title}</Typography>
              <Divider className='mb-20 mt-20' />
              <Typography variant='p' className='p pre-wrap'>
                <div dangerouslySetInnerHTML={{ __html: data.qoutes[0].quotes }} />
              </Typography>
            </Paper>
          </Slide>
        </Grid>
        {/* text part */}
        <Grid item xs={12} md={9}>
          <Slide {...{ autoShow: true, direction: 'right' }}>
            <Paper elevation={2} className='p-20'>
              <Typography variant='h5' className='font-bold color-gray'>{data.qoutes[1].title}</Typography>
              <Divider className='mb-20 mt-20' />
              <Typography variant='p' className='p pre-wrap'>
                <div dangerouslySetInnerHTML={{ __html: data.qoutes[1].quotes }} />
              </Typography>
            </Paper>
          </Slide>
        </Grid>
        {/* image part */}
        <Grid item xs={12} md={3}>
          <Slide {...{ autoShow: true, direction: 'left' }}>
            <Card>
              <CardMedia
                component='img'
                image={data.qoutes[1].photo} />
              <CardContent className='text-center'>
                <Typography
                  gutterBottom
                  variant='h6'
                  className='font-bold font-18 mb-0'>
                  {data.qoutes[1].fullName}
                </Typography>
              </CardContent>
            </Card>
          </Slide>
        </Grid>
      </Grid>}
    </Container >
  )
}
