import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import { connect } from 'react-redux';
import * as actions from '../../../../actions/ieb';
import { DropLi } from '../../../../hooks';
import { JsonToExcel, PageTitle } from '../../../../hooks/Methods';
import { CNTRL_ACTN, ENDPOINTS } from '../../../../actions/api';
import { Alert, Table } from '../../../../layout';

const Employer = (props) => {
  const {
    _fetchAll,
    _list,
    _delete } = props;
  const {
    employerOrigins,
    fellowMemberNo, } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  })

  PageTitle("Employer");

  const handleDlt = (props) => {
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to delete this record?',
      onConfirm: () => {
        if (props.id !== undefined && props.id !== 0) {
          const onComplete = (res) => {
            setAlert({
              isOpen: true,
              type: res.status === 200 ? 'success' : 'error',
              title: res.status === 200 ? 'Deleted Successfully' : 'OPPS',
              subTitle: res.msg
            })
          }
          _delete(ENDPOINTS.Employer + CNTRL_ACTN.Delete,
            props.id,
            onComplete,
            actions.ACTION_TYPES.REMOVE_LIST);
        }
      },
    })
  }


  const handleExcel = (data) => {
    const result = data.map(({ logoFile, fellowMemberNoType, ...rest }) => ({
      ...rest,
      logo: rest.logo.props.href,
    }));
    JsonToExcel(result.map(j => {
      delete Object.assign(j, { 'Id': j.id })['id'];
      delete Object.assign(j, { 'Full Name': j.fullName })['fullName'];
      delete Object.assign(j, { 'Email': j.email })['email'];
      delete Object.assign(j, { 'Mobile': j.mobile })['mobile'];
      delete Object.assign(j, { 'Fellowship No/Membership No.': j.fellowMemberNo })['fellowMemberNo'];
      delete Object.assign(j, { 'Employer Origin': j.employerOrigin })['employerOrigin'];
      delete Object.assign(j, { 'Company Name': j.companyName })['companyName'];
      delete Object.assign(j, { 'Logo': j.logo })['logo'];
      return j;
    }), 'Employer');
  }

  useEffect(() => {
    _fetchAll(ENDPOINTS.Employer + CNTRL_ACTN.GetAll);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Circular List || Table */}
      <Container maxWidth='xl'>
        <Table
          showDownloadBtn={true}
          showEdit={false}
          showAddBtn={false}
          handleExcel={handleExcel}
          handleDlt={handleDlt}
          tblHead={[
            { id: 'actions', label: 'Action' },
            { id: 'id', label: 'Id' },
            { id: 'fullName', label: 'Full Name' },
            { id: 'email', label: 'Email' },
            { id: 'mobile', label: 'Mobile' },
            { id: 'fellowMemberNo', label: 'Fellowship No/Membership No.' },
            { id: 'employerOrigin', label: 'Employer Origin' },
            { id: 'companyName', label: 'Company Name' },
            { id: 'logo', label: 'Logo' },
          ]}
          tblData={(_list.length > 0 && _list[0].employerOrigin !== undefined) ? _list.map(obj => {
            return {
              ...obj,
              'employerOrigin': employerOrigins.find(j => j.id === obj.employerOrigin).name,
              'fellowMemberNo': obj.fellowMemberNo + " " + fellowMemberNo.find(j => j.id === obj.fellowMemberNoType).name,
              'logo': <a href={obj.logo} target="_blank" rel="noreferrer" className='hov-primary'>View Logo</a>,
            }
          }) : []} />
      </Container>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  )
}

const mapStateToProps = state => ({
  _list: state.ieb.list,
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
  _delete: actions.Delete,
}

export default connect(mapStateToProps, mapActionToProps)(Employer);
