import React, { useState, useEffect } from 'react'
import { Card, CardActions, Button, Input, Grow, Typography, Grid } from '@mui/material'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CloseIcon from '@mui/icons-material/Close';

export default function InputImg(props) {
  const {
    title = "Attach a PP size Picture",
    value,
    name,
    error = null,
    onChange,
    rmv,
    src,
    imgFull = false,
    defImg = (process.env.PUBLIC_URL + "/img/other/no-img.jpg"),
    ...other } = props;

  const [sizeErr, setSizeErr] = useState();
  const [photoSrc, setPhotoSrc] = useState(null);
  const [showClsBtn, setShowClsBtn] = useState(false);

  const showPreview = e => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      if ((imageFile.size / 1048576) < 6) {
        const reader = new FileReader();
        reader.onload = x => {
          setPhotoSrc(x.target.result);
          onChange({ name, value: imageFile });
        }
        reader.readAsDataURL(imageFile);
        setSizeErr();
      }else setSizeErr('Maximum Allowed 6MB')
    }
  }
  const rmvImg = () => {
    setPhotoSrc(null);
    rmv !== undefined && rmv();
  }

  useEffect(() => {
    src && setPhotoSrc(src);
    return () => {
      setPhotoSrc(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  useEffect(() => {
    photoSrc !== null ? setShowClsBtn(true) : setShowClsBtn(false);
    return () => {
      setShowClsBtn(false);
    }
  }, [photoSrc])

  return (
    <Grid>
      <Card sx={{ maxWidth: imgFull ? '100%' : '300px' }} {...other}>
        <img
          className={(!imgFull && 'h-300') + ' w-100pr object-cover'}
          src={photoSrc !== null ? photoSrc : defImg}
          alt='Attach a PP Pic' />
        <CardActions className='justify-between'>
          <label htmlFor={name} className='w-100pr'>
            <Input
              accept="image/png, image/gif, image/jpeg, image/jpg"
              className='d-none'
              name={name}
              id={name}
              onChange={showPreview}
              type="file" />
            <Button
              className='btn-primary pl-15 pr-15 w-100pr'
              startIcon={<DriveFolderUploadIcon />}
              component="span">
              {title}
            </Button>
          </label>
          {showClsBtn && <Grow in timeout={800}>
            <Button className='btn ml-5' onClick={rmvImg}>
              <CloseIcon />
            </Button>
          </Grow>}
        </CardActions>
      </Card>
      <Typography color='error' className='mt-10 font-4'>
        {error}{sizeErr}
      </Typography>
    </Grid>
  )
}
