import React from 'react'
import { connect } from 'react-redux'
import Events from './Events'
import HomeSlide from './HomeSlide'
import LatestNewsHome from './LatestNewsHome'
import Research from './Research'
import ViceChancellor from './ViceChancellor'
import * as actions from '../../actions/ieb';
import { Route, Routes } from 'react-router-dom'
import ViewPage from '../Dashboard/Admin/Page/ViewPage'

const Body = ({ _home }) => {
  // 1 is the slider Id
  const sliders = (_home.result && _home.result.imgs) && _home.result.imgs.filter(j => j.imgType === 1);
  return (
    _home.result ? <>
      <Routes>
        <Route index element={<>
          {sliders && <HomeSlide data={sliders} />}
          {_home.result.qoutes && <ViceChancellor data={_home.result} />}
          {_home.result.pages &&
            <>
              <LatestNewsHome data={_home.result} />
              <Events data={_home.result} />
              <Research data={_home.result} />
            </>
          }
        </>}>
        </Route>
        {_home.result.pages.map((page, i) =>
          <Route
            key={i}
            path={`${page.id}/${page.pageType}`}
            element={<ViewPage viewData={page} cls='mt-100 p-20' />} />
        )}
      </Routes>
    </> : <> </>
  )
}

const mapStateToProps = state => ({
  _home: state.ieb.homeData
})

const mapActionToProps = {
  _fetchAll: actions.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(Body);