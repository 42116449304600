import { api } from "./api"


export const ACTION_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  FETCH_ALL: 'FETCH_ALL',
  FETCH_BY_ID: 'FETCH_BY_ID',
  LOGIN: 'LOGIN',
  RESPONSE: 'RESPONSE',
  UPDATE_LIST: 'UPDATE_LIST',
  REMOVE_LIST: 'REMOVE_LIST',
  ADD_LIST: 'ADD_LIST',
  ADMIN: 'ADMIN',
  HOME: 'HOME',
}

export const check = (endpoint, value, onComplete, type = ACTION_TYPES.RESPONSE) => dispatch => {
  api(endpoint).check(value)
    .then(res => {
      dispatch({
        type: type,
        payload: res.data
      })
      onComplete && onComplete(res.data);
    })
    .catch(err => {
      console.error('actions > ieb: ', err);
      onComplete && onComplete(undefined, err);
    })
}
export const fetchAll = (endpoint, onComplete, type = ACTION_TYPES.FETCH_ALL) => dispatch => {
  api(endpoint).fetchAll()
    .then(res => {
      dispatch({
        type: type,
        payload: res.data
      })
      onComplete && onComplete(res.data);
    })
    .catch(err => {
      console.error('actions > ieb: ', err);
      onComplete && onComplete(undefined, err);
    })
}
export const fetchAllById = (endpoint, id, onComplete, type = ACTION_TYPES.FETCH_ALL) => dispatch => {
  api(endpoint).fetchAllById(id)
    .then(res => {
      dispatch({
        type: type,
        payload: res.data
      })
      onComplete !== undefined && onComplete(res.data);
    })
    .catch(err => console.error(err));
}
export const fetchByObj = (endpoint, obj, onComplete, type = ACTION_TYPES.FETCH_ALL) => dispatch => {
  api(endpoint).fetchByObj(obj)
    .then(res => {
      dispatch({
        type: type,
        payload: res.data
      })
      onComplete !== undefined && onComplete(res.data);
    })
    .catch(err => {
      console.error(err);
      onComplete !== undefined && onComplete(undefined, err);
    });
}
export const create = (endpoint, data, onComplete, type = ACTION_TYPES.CREATE) => dispatch => {
  api(endpoint).create(data)
    .then(res => {
      dispatch({
        type: type,
        payload: res.data
      })
      onComplete !== undefined && onComplete(res.data);
    })
    .catch(err => console.error(err));
}

export const update = (endpoint, data, onComplete, type = ACTION_TYPES.UPDATE) => dispatch => {
  api(endpoint).update(data)
    .then(res => {
      dispatch({
        type: type,
        payload: res.data
      })
      onComplete !== undefined && onComplete(res.data)
    })
    .catch(err => console.error(err));
}

export const Delete = (endpoint, id, onComplete, type = ACTION_TYPES.DELETE) => dispatch => {
  api(endpoint).delete(id)
    .then(res => {
      dispatch({
        type: type,
        payload: res.data
      })
      onComplete !== undefined && onComplete(res.data);
    })
    .catch(err => console.error(err))
}
