import { Button, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react'
import { BackToTop, PageTitle } from '../../hooks/Methods';
import DownloadIcon from '@mui/icons-material/Download';
import { DropLi } from '../../hooks';
import { ShareBtn } from '../../controls';

const ViewSeekers = ({ seekers }) => {

  const { departments,
    educations,
    experiences,
    interests,
    aplOrigins } = DropLi();

  PageTitle("View Profile");
  BackToTop();

  return (
    <Container>
      <Grid container spacing={2} className='mt-100 mb-80'>
        {/* Page Title */}
        <Grid item xs={12}>
          <Typography
            variant='subtitle1'
            className='font-18 color-secondary border-left'>Job Corner {'>'} Seekers {'>'} View Profile</Typography>
        </Grid>
        {/* View data  */}
        <Grid item xs={12}>
          <Paper className='p-20'>
            <Grid container direction='row' spacing={2}>
              <Grid item md={3} xs={12} className='text-center'>
                <img
                  src={seekers.photo !== null ? seekers.photo : process.env.PUBLIC_URL + "/img/other/no-img.jpg"}
                  alt='ABCD'
                  className='img-fluid border-radious-5 h-250 object-cover' />
              </Grid>
              <Grid item md={9} xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant='h6'
                      className='color-secondary font-bold'>
                      {seekers.fullName}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      className='color-dark font-bold'>
                      {educations.find(j => j.id === seekers.education).name + " in " + departments.find(j => j.id === seekers.department).name} Engineering
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={5} xs={12} className='color-gray' sx={{ borderRight: { md: '1px solid rgb(0 0 0 / 12%)' } }}>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Contact No:</span> <a href={'tel:' + seekers.mobile}>{seekers.mobile}</a>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Email:</span> <a href={'mailto:' + seekers.email}>{seekers.email}</a>
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Last Degree Passing Year:</span> {seekers.lastDegPassYr}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Field of Interest:</span> {interests.find(j => j.id === seekers.fieldOfInt).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Experience:</span> {seekers.expDuration} Years
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item md={7} xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Previous Company:</span> {seekers.prevComp}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Computer Expertise:</span> {seekers.computerExp}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Field of Experience:</span> {experiences.find(j => j.id === seekers.fieldOfExp).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Application Origin:</span> {aplOrigins.find(j => j.id === seekers.aplOrigin).name}
                    </Typography>
                    <Divider />
                    <Typography
                      variant='subtitle1'
                      sx={{ pl: { md: '10px', xs: '0px' } }}>
                      <span className='font-bold'>Special Quality:</span> {seekers.specialQuality}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className='color-gray'>
                    <Typography
                      variant='subtitle1'>
                      <span className='font-bold'>Home District:</span> {seekers.homeDist}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{ __html: seekers.details }}
                  className='table-border' />
                <div className='text-right mt-10'>
                  {seekers.attachCV !== null && <Button
                    className='btn-primary border-radious-25 pr-10 pl-10 mr-5'
                    size='small'
                    endIcon={<DownloadIcon />}>
                    <a href={seekers.attachCV} target="_blank" rel="noreferrer">View CV</a>
                  </Button>}
                  <ShareBtn />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ViewSeekers