import { Container, Divider, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { connect } from 'react-redux';
import { DropLi } from '../../../hooks';


const ProfileComp = (props) => {
  const { educations, departments } = DropLi();
  const { user } = props;
  return (
    <Paper className='p-10'>
      {user && <Grid item xs={12} className='bg-secondary p-20 border-radious-5'>
        <Grid className='text-center mt-10'>
          <img
            alt='user-img'
            className='h-150 w-150 object-cover border-circle border-primary-2 p-5'
            src={user.photo !== null ? user.photo : process.env.PUBLIC_URL + "/img/other/no-img.jpg"} />
        </Grid>
        <Grid className='text-center'>
          <Typography variant='h5' className='mt-15'>
            {user.fullName}
          </Typography>
          <Divider className='bg-gray' />
          <Typography variant='subtitle' className='mt-20 color-light'>
             {user.education && educations.find(j => j.id === user.education).name} in {user.department && departments.find(j => j.id === user.department).name} Engineering
          </Typography>
        </Grid>
        <Grid>
          <Typography variant='h6' className='mt-30 color-light text-uppercase'>
            Contact
          </Typography>
          <Divider className='bg-gray' />
          <Typography variant='subtitle' className='mt-10 color-light d-block d-flex'>
            <CallIcon className='font-22 mr-10' /><a href={'tel:' + user.mobile}>{user.mobile}</a>
          </Typography>
          <Typography variant='subtitle' className='mt-10 color-light d-block d-flex'>
            <EmailIcon className='font-22 mr-10' /> <a href={'mailto:' + user.email}>{user.email}</a>
          </Typography>
          <Typography variant='subtitle' className='mt-10 color-light d-block d-flex'>
            <LocationOnIcon className='font-22 mr-10' /> {user.homeDist}
          </Typography>
        </Grid>
      </Grid>}
    </Paper>
  )
}

const EmployeePrfl = (props) => {
  const { _authUser } = props;
  const {
    educations,
    aplOrigins,
    departments,
    experiences,
    interests } = DropLi();

  return (
    <Container>
      {(_authUser.result !== undefined && _authUser.result.fullName !== undefined) && <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ display: { md: 'none', xs: 'block' } }}>
          <ProfileComp user={_authUser.result} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className='p-20'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Education:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.education && educations.find(j => j.id === _authUser.result.education).name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Application Origin:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.aplOrigin &&aplOrigins.find(j => j.id === _authUser.result.aplOrigin).name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Department:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.department && departments.find(j => j.id === _authUser.result.department).name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Last Degree Passing Year:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.lastDegPassYr}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Experience:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.expDuration} Year
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Field of Interest:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.fieldOfInt && interests.find(j => j.id === _authUser.result.fieldOfInt).name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Field of Experience:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.fieldOfExp && experiences.find(j => j.id === _authUser.result.fieldOfExp).name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Previous Company:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.prevComp}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Computer Experties:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.computerExp}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                <Typography variant='subtitle2' className='color-gray'>
                  Special Quality:
                </Typography>
                <Typography variant='subtitle1'>
                  {_authUser.result.specialQuality}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2' className='color-gray'>
                  CV:
                </Typography>
                <Typography variant='subtitle1'>
                  <a href={_authUser.result.attachCV} target="_blank" rel="noreferrer" className='hov-primary'>View CV</a>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { md: 'block', xs: 'none' } }}>
          <ProfileComp user={_authUser.result} />
        </Grid>
      </Grid>}
    </Container>
  )
}

const mapStateToProps = state => ({
  _authUser: state.ieb.authUser
})

const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(EmployeePrfl);