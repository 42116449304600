
export default function DropLi() {

  const gender = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' },
  ]

  const aplOrigins = [
    { id: 1, name: 'IEB Graduate' },
    { id: 2, name: 'AMIE Student' },
    { id: 3, name: 'Others' },
  ]

  const employerOrigins = [
    { id: 1, name: 'IEB Graduate' },
    { id: 2, name: 'Others' },
  ]
  const fellowMemberNo = [
    { id: 1, name: 'F' },
    { id: 2, name: 'M' },
    { id: 3, name: 'AM' },
  ]

  const departments = [
    { id: 8, name: 'Architecture' },
    { id: 1, name: 'Civil' },
    { id: 2, name: 'Mechanical' },
    { id: 3, name: 'Electrical' },
    { id: 4, name: 'Chemical' },
    { id: 5, name: 'Computer' },
    { id: 6, name: 'Textile' },
    { id: 7, name: 'Others' },
  ]

  const bloods = [
    { id: 1, name: 'A+' },
    { id: 2, name: 'A-' },
    { id: 3, name: 'B+' },
    { id: 4, name: 'B-' },
    { id: 5, name: 'O+' },
    { id: 6, name: 'O-' },
    { id: 7, name: 'AB+' },
    { id: 8, name: 'AB-' },
  ]

  const educationals = [
    { id: 1, name: 'PhD' },
    { id: 2, name: 'M.Sc.' },
    { id: 3, name: 'Graduation' },
  ]

  const educations = [
    { id: 1, name: 'M.Sc.' },
    { id: 2, name: 'B.Sc.' },
    { id: 3, name: 'Diploma' },
    { id: 4, name: 'HSC' },
  ]

  const experiences = [
    { id: 1, name: 'Consultancy' },
    { id: 2, name: 'Maintenance' },
    { id: 3, name: 'Marketing' },
    { id: 4, name: 'Project' },
    { id: 5, name: 'Fresher' },
  ]
  const interests = [
    { id: 1, name: 'Consultancy' },
    { id: 2, name: 'Maintenance' },
    { id: 3, name: 'Marketing' },
    { id: 4, name: 'Project' },
  ]

  const eduBgs = [
    { id: 1, name: 'HSC' },
    { id: 2, name: 'Diploma' }
  ]

  const parts = [
    { id: 1, name: 'A' },
    { id: 2, name: 'B' }
  ]

  const acctType = [
    { id: 1, name: 'Job Seekers' },
    { id: 2, name: 'Employer' },
    { id: 3, name: 'Member' },
    { id: 4, name: 'Student' },
    { id: 5, name: 'Business' },
  ]

  const emplStatus = [
    {id: 1, name: 'Part Time'},
    {id: 2, name: 'Full Time'},
  ]

  const imgsType = [
    {id: 1, name: 'Slider'},
    {id: 2, name: 'Gallery'},
    {id: 3, name: 'Ads'},
  ]
  
  const pages = [
    {id: 1, name: 'About Us'},
    {id: 6, name: 'Committe > Alumni'},
    {id: 7, name: 'Committe > Students'},
    {id: 8, name: 'Archive > Alumni'},
    {id: 9, name: 'Archive > Amie Course > Exam Doc'},
    {id: 10, name: 'Archive > Amie Course > Questions'},
    {id: 11, name: 'Archive > Amie Course > Books'},
    {id: 12, name: 'Archive > Amie Course > Hand Notes'},
  ]

  return {
    gender,
    aplOrigins,
    employerOrigins,
    fellowMemberNo,
    departments,
    bloods,
    educationals,
    experiences,
    eduBgs,
    parts,
    acctType,
    interests,
    educations,
    emplStatus,
    imgsType,
    pages,
  }
}
