import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CNTRL_ACTN, ENDPOINTS } from '../../../actions/api';
import * as actions from '../../../actions/ieb';
import { LoaderContext } from '../../../utils/Context';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import LogoutIcon from '@mui/icons-material/Logout';
import { PageTitle } from '../../../hooks/Methods';
import Dashboard from '../../../layout/Dashboard';
import { Alert } from '../../../layout';
import ChangePassComp from '../ChangePassComp';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import EventIcon from '@mui/icons-material/Event';
import ArticleIcon from '@mui/icons-material/Article';
import ScienceIcon from '@mui/icons-material/Science';
import Pages from './Page/Pages';
import Sliders from './Slider/Sliders';
import BurstModeRoundedIcon from '@mui/icons-material/BurstModeRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import AddOrEdit from './AddOrEdit';
import Employee from './Employee/Employee';
import BadgeIcon from '@mui/icons-material/Badge';
import Employer from './Employer/Employer';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import Circular from './Employer/Circular';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Member from './Member/Member';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Student from './Student/Student';
import Business from './Business/Business';
import BusinessIcon from '@mui/icons-material/Business';
import Quotes from './Quote/Quotes';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import AddEditSinglePg from './Page/AddEditSinglePg';
import WebIcon from '@mui/icons-material/Web';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SiteSettings from './SiteSettings/SiteSettings';

const Menu = (props) => {
  const { _fetch, setAlert } = props;
  const [selIndex, setSelectIndex] = useState(1);
  const navigate = useNavigate();
  const { setShowLoader } = useContext(LoaderContext);

  const Logout = () => {
    // Logout
    const onLogout = () => {
      setShowLoader(true);
      const onComplete = (res) => {
        setShowLoader(false);
        (res !== undefined && res.status === 204) && navigate('/');
      }
      _fetch(ENDPOINTS.Admin + CNTRL_ACTN.Logout, onComplete, actions.ACTION_TYPES.ADMIN);
    }
    setAlert({
      isOpen: true,
      type: 'confirmation',
      title: 'Are you sure!',
      subTitle: 'You want to Logout from your account?',
      onConfirm: onLogout,
    });
  }

  return (
    <>
      <ListItemButton
        selected={selIndex === 1}
        onClick={() => {
          setSelectIndex(1);
          navigate("/admin-panel")
        }}>
        <ListItemIcon>
          <WebIcon />
        </ListItemIcon>
        <ListItemText primary="Add/Edit Page" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 2}
        onClick={() => {
          setSelectIndex(2);
          navigate(`/admin-panel/pages/${2}`);
        }}>
        <ListItemIcon >
          <NewspaperIcon />
        </ListItemIcon>
        <ListItemText primary="News" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 3}
        onClick={() => {
          setSelectIndex(3);
          navigate(`/admin-panel/pages/${3}`);
        }}>
        <ListItemIcon >
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Events" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 4}
        onClick={() => {
          setSelectIndex(4);
          navigate(`/admin-panel/pages/${4}`);
        }}>
        <ListItemIcon >
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="Notices" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 5}
        onClick={() => {
          setSelectIndex(5);
          navigate(`/admin-panel/pages/${5}`);
        }}>
        <ListItemIcon >
          <ScienceIcon />
        </ListItemIcon>
        <ListItemText primary="Research" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 6}
        onClick={() => {
          setSelectIndex(6);
          navigate('/admin-panel/siteSettings');
        }}>
        <ListItemIcon >
          <SettingsRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Site Settings" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 7}
        onClick={() => {
          setSelectIndex(7);
          navigate('/admin-panel/sliders');
        }}>
        <ListItemIcon >
          <BurstModeRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Slider/Img/Ads" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 8}
        onClick={() => {
          setSelectIndex(8);
          navigate('/admin-panel/editAdmin');
        }}>
        <ListItemIcon >
          <AdminPanelSettingsRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Edit Admin" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 9}
        onClick={() => {
          setSelectIndex(9);
          navigate('/admin-panel/employee');
        }}>
        <ListItemIcon >
          <BadgeIcon />
        </ListItemIcon>
        <ListItemText primary="Employee" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 10}
        onClick={() => {
          setSelectIndex(10);
          navigate('/admin-panel/employer');
        }}>
        <ListItemIcon >
          <SupervisedUserCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Employer" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 11}
        onClick={() => {
          setSelectIndex(11);
          navigate('/admin-panel/circular');
        }}>
        <ListItemIcon >
          <WorkHistoryRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Circular" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 12}
        onClick={() => {
          setSelectIndex(12);
          navigate('/admin-panel/member');
        }}>
        <ListItemIcon >
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="Member" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 13}
        onClick={() => {
          setSelectIndex(13);
          navigate('/admin-panel/student');
        }}>
        <ListItemIcon >
          <PermContactCalendarIcon />
        </ListItemIcon>
        <ListItemText primary="Student" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 14}
        onClick={() => {
          setSelectIndex(14);
          navigate('/admin-panel/business');
        }}>
        <ListItemIcon >
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Business" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 15}
        onClick={() => {
          setSelectIndex(15);
          navigate('/admin-panel/quotes');
        }}>
        <ListItemIcon >
          <SubjectRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Quotes" />
      </ListItemButton>

      <ListItemButton
        selected={selIndex === 19}
        onClick={() => {
          setSelectIndex(19);
          navigate('/admin-panel/changePass');
        }}>
        <ListItemIcon >
          <SyncLockIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      <ListItemButton
        selected={selIndex === 20}
        onClick={() => {
          setSelectIndex(20);
          Logout();
        }}>
        <ListItemIcon >
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </>
  )
}

const AdminDash = (props) => {
  const { _fetch, _admin } = props;
  const [alert, setAlert] = useState({
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
  });
  PageTitle("Admin Panel");

  useEffect(() => {

    return () => {
    }
  }, [])

  return (
    <Dashboard
      menu={<Menu {...{ _fetch, setAlert }} />}
      title={'Hi! ' + (_admin.result !== undefined && _admin.result.fullName)}
      subtitle='Admin Panel'>
      <Routes>
        <Route path='changePass' element={<ChangePassComp {...{ isAdmin: true }} />} />
        <Route path='pages/:pageType' element={<Pages />} />
        <Route path='sliders' element={<Sliders />} />
        <Route path='editAdmin' element={<AddOrEdit {...{ isUpdate: true }} />} />
        <Route path='employee' element={<Employee />} />
        <Route path='employer' element={<Employer />} />
        <Route path='circular' element={<Circular />} />
        <Route path='member' element={<Member />} />
        <Route path='student' element={<Student />} />
        <Route path='business' element={<Business />} />
        <Route path='quotes' element={<Quotes />} />
        <Route index path='/' element={<AddEditSinglePg />} />
        <Route path='siteSettings' element={<SiteSettings />} />
      </Routes>
      <Alert alert={alert} setAlert={setAlert} />
    </Dashboard>
  )
}

const mapStateToProps = state => ({
  _data: state.ieb.data,
  _response: state.ieb.response,
  _admin: state.ieb.admin
})

const mapActionToProps = {
  _fetch: actions.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(AdminDash);
